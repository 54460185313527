import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  // Link,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import {
  addUser,
  getAccountUsersByRole,
  getBranchesByAccount,
  getUserByEmail,
  url,
} from "../../Api";
import { Link } from "react-router-dom";
import Capitalise from "../Capitalise/Capitalise";
import "./Modal.css";

let branches = [],
  users;

export default function AddUserModal(props) {
  useEffect(() => {
    Promise.all([getBranchesByAccount(), getAccountUsersByRole("Admin")]).then(
      (res) => {
        branches = res[0].data;
        users = res[1].data;
      }
    );
  }, []);

  let validateForm = (values) => {
    const errors = {};
    if (!values.Name) errors.Name = "Enter name of the user";
    if (!values.Email) errors.Email = "Enter email id of the user";
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email))
      errors.Email = "Invalid email address";
    if (!values.Role) errors.Role = "Enter a role of the user";
    if (!values.Branch) errors.Branch = "Select branch for the user";

    return errors;
  };

  let Invite = (values, isSubmitting) => {
    addUser({
      name: values.Name,
      email: values.Email,
      role: values.Role,
      branch: values.Branch,
      status: "pending",
      account_id: localStorage.accountId,
    })
      .then((res) => {
        toast.success("invitation sent successfully");
        props.close();
      })
      .catch((err) => {
        if (err.response.status === 409) {
          toast.error("Email already registered. Please use another email");
          isSubmitting.setSubmitting(false);
        } else {
          isSubmitting.setSubmitting(false);
          toast.error("Counld not send invitation. please try again");
        }
      });
  };

  let isAssigned = (branch) => {
    if (users.filter((user) => user.branch === branch._id).length === 0)
      return false;
    else return true;
  };

  let addBranch = (res) => {
    branches.push(res.data);
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.close()}
      aria-labelledby="create-survey-modal-title"
      aria-describedby="create-survey-modal-description"
    >
      <Box sx={{ width: { xs: "81%", sm: "350px" } }} className="basic-modal">
        <h2 id="create-survey-modal-title">Add User</h2>
        <Formik
          validate={validateForm}
          initialValues={{ Name: "", Email: "", Role: "Admin", Branch: "" }}
          onSubmit={(values, isSubmitting) => Invite(values, isSubmitting)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} id="add-user-modal-description">
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                name="Name"
                variant="outlined"
                onBlur={handleBlur}
                label="Name of the user"
                value={values.Name}
                onChange={handleChange}
              />
              <div
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginBottom: "16px",
                }}
              >
                {errors.Name && touched.Name && errors.Name}
              </div>

              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                name="Email"
                variant="outlined"
                onBlur={handleBlur}
                label="Email of the user"
                value={values.Email}
                onChange={handleChange}
              />
              <div
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginBottom: "16px",
                }}
              >
                {errors.Email && touched.Email && errors.Email}
              </div>

              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                name="Role"
                variant="outlined"
                onBlur={handleBlur}
                label="Role of the user"
                value="Admin"
                onChange={handleChange}
                disabled
              />
              <div
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginBottom: "16px",
                }}
              >
                {errors.Role && touched.Role && errors.Role}
              </div>
              <FormControl fullWidth>
                <InputLabel id="select-label">Branch</InputLabel>
                <Select
                  InputLabelProps={{
                    shrink: true,
                  }}
                  labelId="-select-label"
                  id="simple-select"
                  value={values.Branch}
                  defaultValue=""
                  name="Branch"
                  label="Branch"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {branches.map((branch, i) => (
                    <MenuItem
                      value={branch._id}
                      key={i}
                      disabled={isAssigned(branch)}
                    >
                      {Capitalise(branch.name)}
                    </MenuItem>
                  ))}
                  <MenuItem
                    sx={{
                      justifyContent: "end",
                      display: "flex",
                    }}
                  >
                    <Link to="/settings/branches">add more branch</Link>
                  </MenuItem>
                </Select>
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginBottom: "16px",
                  }}
                >
                  {errors.Branch && touched.Branch && errors.Branch}
                </div>
              </FormControl>
              <Grid display="flex" justifyContent={"end"}>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ marginTop: "24px", textTransform: "none" }}
                  disabled={isSubmitting}
                >
                  Invite
                </Button>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}
