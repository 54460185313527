import axios from "axios";

export const CLIENT_URL = window.location.origin;
export const url = process.env.REACT_APP_SERVER_URL + "/api";
// export const url = "http://192.168.18.22:1337";
const userUrl = `${url}/user`;
const branchUrl = `${url}/branch`;
const surveyUrl = `${url}/survey`;
const contactUrl = `${url}/contact`;
const feedbackUrl = `${url}/feedback`;
const testimonialsUrl = `${url}/testimonials`;
const messageUrl = `${url}/message`;
const themeUrl = `${url}/theme`;
const embedGraphUrl = `${url}/public/embed/graph`;
const embedSurveyUrl = `${url}/public/embed`;

// Use this function instead of axios to send requests with auth headers
// eg => authenticatedRequest.get('/users')
// TODO: figure out which all endpoints should be public and maker the rest of them
// private in the backend and change here accordingly
let authenticatedRequest = axios.create({
  headers: { Authorization: "Bearer " + localStorage.getItem("token") },
});
let adminAuthenticatedRequest = axios.create({
  headers: { Authorization: "Bearer " + localStorage.getItem("passCode") },
});

//user api
export const loginUser = async (user) =>
  await axios.post(userUrl + "/login", user).then((res) => {
    authenticatedRequest = axios.create({
      headers: { Authorization: "Bearer " + res.data.jwtToken },
    });
    return res;
  });
export const getAccountUsersByRole = async (role) =>
  await authenticatedRequest.get(
    userUrl + "/account/" + localStorage.accountId + "/" + role
  );
export const getUserByEmail = async (email) =>
  await authenticatedRequest.get(userUrl + "/email/" + email);

export const verifyUser = async (accountId, userId, data) =>
  await authenticatedRequest.put(userUrl + "/verify", {
    account_id: accountId,
    id: userId,
    data: data,
  });
export const removeUser = async (userId) =>
  await authenticatedRequest.delete(`${userUrl}/delete/${userId}`);
export const getallAccounts = async () =>
  await authenticatedRequest.get(`${userUrl}/getallaccounts`);
export const changeAccountStatus = async (id, status) =>
  await authenticatedRequest.put(`${userUrl}/${id}/${status}`);

export const addUser = async (user) =>
  await authenticatedRequest.post(userUrl + "/add", user);
export const updatePermissions = async (userId, permissions) =>
  await authenticatedRequest.post(userUrl + "/updatePermissions", {
    userId,
    permissions,
  });
// export const createAccount = async (user) => await axios.post(userUrl, user);
export const createAccount = async (user) => await axios.post(userUrl, user);
export const getPassword = async () =>
  await authenticatedRequest.get(
    userUrl + "/" + localStorage.userId + "/password"
  );
export const updatePassword = async (userId, password) =>
  await authenticatedRequest.put(userUrl + "/password", { userId, password });
export const resetPasswordReq = async (email) =>
  await axios.post(userUrl + "/resetpassword/start", { email });

export const resetPassword = async (userId, token, newPassword) =>
  await axios.post(userUrl + "/resetpassword", { token, userId, newPassword });

//contact api
export const getContactsByAccount = async () =>
  await axios.get(`${contactUrl}/account/${localStorage.accountId}`);
export const getContactsByUser = async () =>
  await axios.get(
    `${contactUrl}/user/${localStorage.accountId}/${localStorage.userId}`
  );
export const getMessageTemplateByUser = async () =>
  await axios.get(`${contactUrl}/template/${localStorage.userId}`);

export const addContact = async (contact) =>
  await axios.post(contactUrl, contact);
export const deleteContactById = async (contactId) =>
  await axios.delete(`${contactUrl}/${contactId}`);
export const addMessageTemplate = async (data) =>
  await axios.post(`${contactUrl}/template`, data);

//branch api
export const getBranchesByAccount = async () =>
  await authenticatedRequest.get(
    `${branchUrl}/account/${localStorage.accountId}`
  );
export const addBranch = async (branch) =>
  await authenticatedRequest.post(branchUrl, branch);
export const deleteBranch = async (branchId) =>
  await authenticatedRequest.delete(`${branchUrl}/${branchId}`);

//survey api
export const fetchSurveys = async () =>
  await axios.get(surveyUrl + "/account-survey/" + localStorage.accountId);
export const fetchOnlySurveys = async () =>
  await axios.get(surveyUrl + "/onlySurveys/" + localStorage.accountId);
export const fetchOnlySurveyNames = async () =>
  await axios.get(surveyUrl + "/onlySurveyNames/" + localStorage.accountId);
export const fetchSurveyById = async (id) =>
  await axios.get(`${surveyUrl}/${id}`);
export const fetchSurveyQuestions = async (id) =>
  await axios.get(`${surveyUrl}/${id}/questions`);
export const fetchSurveyResponses = async (id) =>
  await authenticatedRequest.get(`${surveyUrl}/${id}/responses`);

export const addSurvey = async (
  surveyTitle,
  surveyLanguage,
  selectedTemplate,
  surveyType
) =>
  await authenticatedRequest.post(surveyUrl, {
    accountId: localStorage.accountId,
    title: surveyTitle,
    language: surveyLanguage,
    template: selectedTemplate,
    type: surveyType,
  });
export const addSurveyQuestion = async (question, surveyId) => {
  return await authenticatedRequest.post(`${surveyUrl}/${surveyId}/questions`, {
    surveyId: surveyId,
    ...question,
  });
};

export const updateSurvey = async (surveyId, survey) =>
  await authenticatedRequest.put(`${surveyUrl}/${surveyId}/edit`, survey);
export const duplicateSurvey = async (surveyId) =>
  await authenticatedRequest.get(`${surveyUrl}/${surveyId}/duplicate`);
export const updateSurveyQuestion = async (questionId, question) =>
  await authenticatedRequest.put(
    `${surveyUrl}/${questionId}/questions`,
    question
  );
export const updateAllQuestionBySurvey = async (surveyId, questions) =>
  await authenticatedRequest.put(
    `${surveyUrl}/${surveyId}/update-all-questions`,
    questions
  );

export const deleteQuestionById = async (questionId, surveyId) =>
  await authenticatedRequest.delete(
    `${surveyUrl}/${surveyId}/questions/${questionId}`
  );
export const deleteSurveyById = async (surveyId) =>
  await authenticatedRequest.delete(`${surveyUrl}/surveys/${surveyId}`);
export const deleteManySurveysById = async (surveyIdArray) =>
  await authenticatedRequest.delete(
    `${surveyUrl}/surveys/delete_many/${surveyIdArray}`
  );

//merge surveys
export const getMergedSurvey = async (mergeid) =>
  await axios.get(`${surveyUrl}/merge/${mergeid}`);
export const getAllMergedSurveys = async (accountid, mergeid) =>
  await axios.get(`${surveyUrl}/merge/${accountid}/${mergeid}`);
export const addMergedSurveys = async (account_id, merge_name, merged_array) =>
  await authenticatedRequest.post(`${surveyUrl}/merge/mergesurveys`, {
    account_id: account_id,
    name: merge_name,
    merged_array: merged_array,
  });
export const deleteMergedSurvey = async (mergeId) =>
  await authenticatedRequest.delete(`${surveyUrl}/merge/${mergeId}`);

//response api
export const getRespondentLocation = async (id) =>
  await axios.get(
    `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_GEOLOCATION_API}`
  );
export const fetchFeedbackQuestions = async (id) =>
  await axios.get(`${feedbackUrl}/${id}`);

// if admin, only returns responses from same branch
export const fetchFeedbackResponse = async (id) =>
  await authenticatedRequest.get(`${feedbackUrl}/${id}/results`);
export const fetchFeedbakResponseByRespondentId = async (respondentId) =>
  await authenticatedRequest.get(
    `${feedbackUrl}/respondent/${respondentId}/results`
  );
export const getFeedbackResponseById = async (responseId) =>
  await authenticatedRequest.get(`${feedbackUrl}/responses/${responseId}`);
export const getResponseLocationBySurveyId = async (surveyId) =>
  await authenticatedRequest.get(
    `${feedbackUrl}/${surveyId}/responses/location`
  );

export const addFeedbackResponse = async (
  surveyId,
  response,
  branch,
  respondentId,
  location
) =>
  await axios.post(feedbackUrl + "/" + surveyId + "/" + respondentId, {
    surveyId: surveyId,
    response: response,
    branch: branch,
    location: location,
  });

export const sendOTP = async (phone) =>
  await axios.get(`${feedbackUrl}/sendotp/${phone}`);
export const verifyOTP = async (otp, phone) =>
  await axios.get(`${feedbackUrl}/verifyotp/${otp}/${phone}`);

export const deleteFeedbackByQuestionId = async (surveyId, questionId) =>
  await authenticatedRequest.delete(
    feedbackUrl + "/" + surveyId + "/" + questionId
  );

export const updateResponseById = async (responseId, data) =>
  await authenticatedRequest.put(
    `${feedbackUrl}/updateresponse/${responseId}`,
    data
  );

//huddle api

export const fetchRespondentDetails = async (respondentId) =>
  await axios.get(
    `https://inperson.huddleglobal.co.in/api/user/data/${respondentId}`
  );

//testimonials
export const fetchTestimonials = async (surveyId, questionId) =>
  await axios.get(`${testimonialsUrl}/${surveyId}/${questionId}`);
export const updateTestimonials = async (surveyId, questionId, data) =>
  await axios.post(`${testimonialsUrl}/${surveyId}/${questionId}`, data);

//Menu
export const fetchAllMenu = async () => {
  const accountId = window.localStorage.getItem("accountId");
  return await axios.get(`${url}/menu/all/${accountId}`);
};
export const createMenu = async (data) => {
  const accountId = window.localStorage.getItem("accountId");
  return await axios.post(`${url}/menu/${accountId}`, data);
};
export const deleteMenu = async (id) => await axios.delete(`${url}/menu/${id}`);

export const fetchFullMenu = async (id) => await axios.get(`${url}/menu/${id}`);
export const createCategory = async (name, menuId) =>
  await axios.post(`${url}/menu/${menuId}/category`, { name });
export const createItem = async (data, categoryId, menuId) =>
  await axios.post(`${url}/menu/${menuId}/category/${categoryId}/item`, data);
export const deleteCategory = async (categoryId, menuId) =>
  await axios.delete(`${url}/menu/${menuId}/category/${categoryId}`);
export const deleteItem = async (itemId, categoryId, menuId) =>
  await axios.delete(
    `${url}/menu/${menuId}/category/${categoryId}/item/${itemId}`
  );

//querymessages
export const postMessage = async (data) =>
  await axios.post(`${messageUrl}/sendmessage`, data);
export const getMessage = async () =>
  await axios.get(`${messageUrl}/getmessage`);

//admin

export const adminLogin = async (username, passCode) =>
  await axios.post(`${userUrl}/admin/login`, { passCode }).then((res) => {
    if (res.status == 200) {
      localStorage.setItem("passCode", passCode);
      adminAuthenticatedRequest = axios.create({
        headers: { Authorization: "Bearer " + passCode },
      });
    }
    return res;
  });
export const adminGetAllRequests = async () =>
  await adminAuthenticatedRequest.get(`${userUrl}/admin/getallrequests`);
export const adminChangeAccountStatus = async (id, status) =>
  await adminAuthenticatedRequest.put(`${userUrl}/admin/${id}/${status}`);
// team
export const getTeamMemberTasks = async (memberId) =>
  await authenticatedRequest.get(`${userUrl}/fetchissues/${memberId}`);

export const assignTaskToTeamMember = async (memberId, data) =>
  await authenticatedRequest.post(`${userUrl}/assignissues/${memberId}`, data);

export const updateTask = async (taskId, data) =>
  await authenticatedRequest.post(`${userUrl}/issues/${taskId}`, data);

export const fetchTasks = async (role, accountId, branchId) => {
  console.log(branchId !== "undefined");
  if (branchId !== "undefined")
    return await authenticatedRequest.get(
      `${userUrl}/issues/${role}/${accountId}/${branchId}`
    );
  else
    return await authenticatedRequest.get(
      `${userUrl}/issues/${role}/${accountId}`
    );
};

export const deleteTask = async (taskId) =>
  await authenticatedRequest.delete(`${userUrl}/issues/${taskId}`);

//plan
export const createPlan = async (data) =>
  await axios.post(`${url}/plan/createplan`, data);
export const getPlans = async () => await axios.get(`${url}/plan/getplans`);
export const getPlanByNumberOrId = async (pid, pnumber) =>
  await axios.get(`${url}/plan/${pid}/${pnumber}`);
export const updatePlan = async (pid) =>
  await axios.put(`${url}/plan/updateplan/${pid}`);
export const deletePlan = async (pid) =>
  await axios.delete(`${url}/plan/deleteplan/${pid}`);
export const addFeature = async (data) =>
  await axios.post(`${url}/plan/addfeature`, data);
export const getAllFeatures = async () =>
  await axios.get(`${url}/plan/getallfeatures`);
// Theme

export const getTheme = async (surveyId) =>
  await axios.get(`${themeUrl}/${surveyId}`);

export const createTheme = async (surveyId, styles) =>
  await axios.post(`${themeUrl}/${surveyId}`, { styles });
export const updateTheme = async (surveyId, styles) =>
  await axios.put(`${themeUrl}/${surveyId}`, { styles });
export const setIntro = async (surveyId, introData) =>
  await authenticatedRequest.put(`${surveyUrl}/${surveyId}/intro`, introData);
export const getIntro = async (surveyId) =>
  await authenticatedRequest.get(`${surveyUrl}/${surveyId}/intro`);

export const getNpsData = async (questionId, branchId) =>
  await axios.get(`${embedGraphUrl}/${questionId}/${branchId}/nps`);
export const getChartData = async (questionId, branchId) =>
  await axios.get(`${embedGraphUrl}/${questionId}/${branchId}/chart`);
export const getSurveyPublicResponses = async (surveyId) =>
  await axios.get(`${embedSurveyUrl}/surveyResponse/${surveyId}`);

export const makeResponsePublic = async (questionId, branchId) =>
  await authenticatedRequest.post(`${embedGraphUrl}/${questionId}/${branchId}`);
export const makeSurveyResponsePublic = async (
  surveyId,
  makeResponsesPublic
) => {
  await authenticatedRequest.post(
    `${embedSurveyUrl}/surveyResponse/${surveyId}`,
    { makeResponsesPublic }
  );
};
export const deleteResponsePublic = async (questionId, branchId) =>
  await authenticatedRequest.delete(
    `${embedGraphUrl}/${questionId}/${branchId}`
  );
export const getPublicResponses = async () =>
  await authenticatedRequest.get(`${embedGraphUrl}`);
