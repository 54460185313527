import React from "react";
import { AppBar } from "@mui/material";
import { newlogo } from "../../Assets";
import "./Adminnavbar.css";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Shared/customButton/Custombutton";
function AdminNavbar({ reload, setReload, selectedNav, setselectedNav }) {
  const navigate = useNavigate();
  return (
    <AppBar position="static" className="adminAppbar">
      <img src={newlogo} alt="" />
      <div>
        <Custombutton
          content="Requests"
          color="white"
          backgroundColor={selectedNav === "requests" && "#060a32"}
          onCLick={() => setselectedNav("requests")}
        />
        <Custombutton
          content="Messages"
          color="white"
          backgroundColor={selectedNav === "messages" && "#060a32"}
          onCLick={() => setselectedNav("messages")}
        />
        <Custombutton
          content="Onboard"
          color="white"
          backgroundColor={selectedNav === "useronboard" && "#060a32"}
          onCLick={() => setselectedNav("useronboard")}
        />
        <Custombutton
          content="Plans"
          color="white"
          backgroundColor={selectedNav === "plans" && "#060a32"}
          onCLick={() => setselectedNav("plans")}
        />
        <Custombutton
          onCLick={() => {
            navigate("/");
          }}
          variant="outlined"
          content="website"
          color="white"
        />
        <Custombutton
          onCLick={() => {
            localStorage.removeItem("adminLogin");
            navigate("/");
          }}
          variant="outlined"
          content="Logout"
          color="white"
        />
      </div>
    </AppBar>
  );
}
export default AdminNavbar;
