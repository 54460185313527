import { Box, Divider, FormLabel } from "@mui/material";
import { Formik } from "formik";
import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { addFeedbackResponse, updateSurvey } from "../../../Api";
import { Capitalise } from "../../../Shared";
import NextPrevious from "../../../Shared/FormComponent/NextPrevious/NextPrevous";
import ThankYouForm from "../../../Shared/FormComponent/ThankYouForm";
import LikeorDislike from "./LikeorDislike";
import MultipleChoice from "./MultipleChoice";
import OpinionScale from "./OpinionScale";
import PhotoCapture from "./PhotoCapture";
import Rating from "./Rating";
import "./SinglePageView.css";
import Smiley from "./Smiley";
import Testimonial from "./Testimonial";
import Text from "./Text";
import YesorNo from "./YesorNo";

let requiredresult = [];
let feedback = [];
let thankyouMessage, thankyouDescription, thankyouImage;

export default function SinglePageView(props) {
  const surveydetails = props?.surveydetails;
  const questions = props.questions;
  const branch = useParams().branch;
  const id = useParams().id;
  const respondentId = useParams()?.respondentId;
  let hideButton = props.hideButton;
  console.log(hideButton);
  const [ShowThankyou, setShowThankyou] = useState(false);
  let validateForm = (values) => {
    let errors = {};
    questions.map((question) => {
      if (question.required && !values[question._id]) {
        errors[question._id] = "This question is mandatory*";
        return errors;
      } else if (
        question.type === "email" &&
        values[question._id] &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values[question._id])
      ) {
        errors[question._id] = "Invalid email address";
        return errors;
      } else if (
        question.type === "phone number" &&
        values[question._id] &&
        (!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(
          values[question._id]
        ) ||
          values[question._id].length !== 10)
      ) {
        errors[question._id] = "Enter a valid phone number";
        return errors;
      }
      return errors;
    });
    return errors;
  };

  let submitFeedback = (values) => {
    feedback = [];
    questions.map((q) =>
      feedback.push({ questionId: q._id, answer: values[q._id] })
    );
    let branchToAdd = branch === "owner" ? null : branch;

    addFeedbackResponse(id, feedback, branchToAdd, respondentId).then((res) => {
      updateSurvey(id, { responses: surveydetails?.responses + 1 });
      if (res) {
        if (questions.filter((q) => q.type === "thank you").length !== 0) {
          thankyouMessage = questions.filter((q) => q.type === "thank you")[0]
            .l1_title;
          thankyouDescription = questions.filter(
            (q) => q.type === "thank you"
          )[0].l1_description;
          thankyouImage = questions.filter((q) => q.type === "thank you")[0]
            .image;
        }
        setShowThankyou(true);
      }
    });
  };

  let intialvalues = {};
  questions.map((q) => (intialvalues[q._id] = ""));

  return (
    <Box className="singlePageSurveyWrapper">
      {!ShowThankyou ? (
        <Formik
          enableReinitialize
          validate={validateForm}
          onSubmit={submitFeedback}
          initialValues={intialvalues}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                marginBottom: "6.75rem",
                position: "relative",
              }}
            >
              {questions?.map(
                (question, i) =>
                  question.type !== "thank you" &&
                  question.type !== "otp" && (
                    <Fragment>
                      <div className="singlePageFormComponentWrapper" key={i}>
                        <FormLabel
                          className="singlePageSurveyLabel"
                          required={question.required}
                        >
                          {Capitalise(question.l1_title)}
                        </FormLabel>
                        {question.type === "yes or no" ? (
                          <YesorNo
                            selected={(selected) =>
                              (values[question._id] = selected)
                            }
                          />
                        ) : question.type === "like or dislike" ? (
                          <LikeorDislike
                            selected={(selected) =>
                              (values[question._id] = selected)
                            }
                          />
                        ) : question.type === "photo capture" ? (
                          <PhotoCapture
                            selected={(selected) =>
                              (values[question._id] = selected)
                            }
                          />
                        ) : question.type === "multiple choice" ? (
                          <MultipleChoice
                            options={question.l1_options}
                            selected={(selected) =>
                              (values[question._id] = selected)
                            }
                          />
                        ) : question.type === "email" ||
                          question.type === "text" ||
                          question.type === "phone number" ? (
                          <Text
                            type={question.type}
                            selected={(selected) =>
                              (values[question._id] = selected)
                            }
                          />
                        ) : question.type === "rating" ? (
                          <Rating
                            selected={(selected) =>
                              (values[question._id] = selected)
                            }
                          />
                        ) : question.type === "opinion scale" ? (
                          <OpinionScale
                            selected={(selected) =>
                              (values[question._id] = selected)
                            }
                          />
                        ) : question.type === "smiley" ? (
                          <Smiley
                            selected={(selected) =>
                              (values[question._id] = selected)
                            }
                          />
                        ) : (
                          question.type === "testimonial" && (
                            <Testimonial
                              selected={(selected) =>
                                (values[question._id] = selected)
                              }
                            />
                          )
                        )}
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "8px",
                          }}
                        >
                          {errors[question._id] &&
                            touched[question._id] &&
                            errors[question._id]}
                        </div>
                      </div>
                      <Divider className="singlePageSurveyQuestionDivider" />
                    </Fragment>
                  )
              )}
              {hideButton ? "" : <NextPrevious count={1} totalQuestions={1} />}
            </form>
          )}
        </Formik>
      ) : (
        <ThankYouForm
          label={thankyouMessage}
          description={thankyouDescription}
          image={thankyouImage}
        />
      )}
    </Box>
  );
}
