import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { Capitalise } from "../../../Shared";

const items = ["yes", "no"];
export default function YesorNo({ selected }) {
  const [Value, setValue] = useState("");
  return (
    <Box width={"100%"} display="flex" marginTop={"26px"} gap={2}>
      {items.map((item, i) => (
        <Box
          border={Value === item ? "1px solid #3A63F3" : "1px solid #bebebe"}
          bgcolor={Value === item ? "#EFF4FF" : "#FFF"}
          className="yesorno"
          onClick={() => {
            setValue(item);
            selected(item);
          }}
        >
          <Typography
            className="singlePageSurveyOption"
            style={{
              color: Value === item ? "#3A63F3" : "#979797",
              margin: "auto",
            }}
          >
            {Capitalise(item)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
