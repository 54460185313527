import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { addBranch } from "../../Api";

export default function AddBranchModal(props) {
  let validateForm = (values) => {
    const errors = {};
    if (!values.Name) errors.Name = "Enter name for the branch";
    if (!values.code) errors.code = "Enter branch code";
    return errors;
  };

  let add = (values, isSubmitting) => {
    if (props.currentBranches.find((b) => b.name === values.Name)) {
      toast.error("A branch with same name already exists!");
      isSubmitting.setSubmitting(false);
      return;
    }
    if (values.code.length > 4) {
      toast.error("Branch code length cannot exceed 4 characters!");
      isSubmitting.setSubmitting(false);
      return;
    }
    if (props.currentBranches.find((b) => b.code === values.code)) {
      toast.error("A branch with same branch code already exists!");
      isSubmitting.setSubmitting(false);
      return;
    }
    addBranch({
      name: values.Name,
      code: values.code,
      user_id: localStorage.userId,
      account_id: localStorage.accountId,
    })
      .then((res) => {
        toast.success("Added branch successfully");
        props.add(res);
        props.close();
      })
      .catch((err) => {
        isSubmitting.setSubmitting(false);
        toast.error("Could not add branch. please try again");
      });
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.close()}
      aria-labelledby="add-branch-modal-title"
      aria-describedby="add-branch-modal-description"
    >
      <Box sx={{ width: { xs: "81%", sm: "350px" } }} className="basic-modal">
        <h2 id="add-branch-modal-title">Add Branch</h2>
        <Formik
          validate={validateForm}
          initialValues={{ Name: "", Phone: "", Email: "" }}
          onSubmit={(values, isSubmitting) => add(values, isSubmitting)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} id="add-branch-modal-description">
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                name="Name"
                variant="outlined"
                onBlur={handleBlur}
                label="Name of the branch"
                value={values.Name}
                onChange={handleChange}
              />
              <div
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginBottom: "16px",
                }}
              >
                {errors.Name && touched.Name && errors.Name}
              </div>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                name="code"
                variant="outlined"
                onBlur={handleBlur}
                label="Code (short form)"
                value={values.code}
                onChange={handleChange}
              />
              <div
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginBottom: "16px",
                }}
              >
                {errors.code && touched.code && errors.code}
              </div>
              <Grid display="flex" justifyContent={"end"}>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ marginTop: "24px", textTransform: "none" }}
                  disabled={isSubmitting}
                >
                  Add
                </Button>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}
