import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik } from "formik";
import React from "react";
import { loginModalImage } from "../../../Assets";
import { verifyUser } from "../../../Api";

export default function VerifyPage() {
  const navigate = useNavigate();
  const accountId = useParams().accountId;
  const userId = useParams().id;

  let validateForm = (values) => {
    const errors = {};
    if (!values.Password) errors.Password = "Enter your password";
    else if (
      !/^(?=.*[A-Za-z])+(?=.*\d)+(?=.*[@$!%*#?&])+[A-Za-z\d@$!%*#?&]{8,}$/i.test(
        values.Password
      )
    )
      errors.Password =
        "Password must contain minimum 8 characters and must include Upper case, Lower case, Numbers and Special characters";
    return errors;
  };

  let Verify = (values, isSubmitting) => {
    verifyUser(accountId, userId, { password: values.Password })
      .then((res) => {
        if (res.data?.user?.role === "Team Member") {
          navigate("/team");
        } else {
          localStorage.setItem("userId", res.data?.user?._id);
          localStorage.setItem("accountId", res.data?.user?.account_id);
          localStorage.setItem("role", res.data?.user?.role);
          localStorage.setItem("branch", res.data?.user?.branch);
          localStorage.setItem("userName", res.data?.user?.name);
          localStorage.setItem("planId", res.data?.account?.plan_id);
          localStorage.setItem("email", res.data?.user?.email);

          if (
            localStorage.userId &&
            localStorage.accountId &&
            localStorage.role &&
            localStorage.branch &&
            localStorage.userName &&
            localStorage.planId &&
            localStorage.email
          ) {
            navigate("/surveys");
          }
        }
      })
      .catch((error) => {
        toast.error("An error occured. please try again");
        isSubmitting.setSubmitting(false);
      });
  };

  return (
    <Grid container height={"100vh"}>
      <Grid item xs={6} className="login-modal-image">
        <img src={loginModalImage} alt="" />
      </Grid>
      <Grid
        item
        xs={6}
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent={"center"}
        width={"100%"}
      >
        <Box
          className="login-modal"
          style={{
            width: "-webkit-fill-available",
            height: "-webkit-fill-available",
          }}
          display="flex"
          flexDirection={"column"}
          justifyContent="center"
        >
          {/* ===========================verify form=========================== */}
          <Formik
            initialValues={{ Password: "" }}
            validate={validateForm}
            onSubmit={(values, isSubmitting) => Verify(values, isSubmitting)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box>
                  <FormGroup style={{ marginBottom: "15px" }}>
                    <FormLabel style={{ color: "#000", marginBottom: "9px" }}>
                      Password
                    </FormLabel>
                    <TextField
                      type="password"
                      name="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Password}
                      placeholder="Enter Your password"
                      size="small"
                      variant="outlined"
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {" "}
                      {errors.Password && touched.Password && errors.Password}
                    </div>
                  </FormGroup>
                </Box>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "12px",
                  }}
                >
                  <Button
                    size="small"
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{ fontWeight: "700", height: "40px" }}
                    disabled={isSubmitting}
                  >
                    Verify
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
        <Typography
          align="center"
          variant="body2"
          style={{ color: "#3F3D56", marginBottom: "35px" }}
        >
          © SurveyFynext Inc | Privacy Policy |Terms & Conditions
        </Typography>
      </Grid>
    </Grid>
  );
}
