import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AccountNavbar } from "./Shared";
import "./ProtectedRoute.css";

export default function ProtectedRoute({ isLogged }) {
  if (localStorage.userId)
    return (
      <div className="protectedrouteWrapper">
        <AccountNavbar />
        <Outlet />
      </div>
    );
  else return <Navigate to="/" />;
}
