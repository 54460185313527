import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Switch,
  TextField,
  Typography,
  Tooltip as MuiTooltip,
} from "@mui/material";

export function SurveyResponseOverviewEmbedDialog({
  isPublic,
  open,
  close,
  setIsPublic,
  surveyId,
}) {
  const iFrameURL = isPublic
    ? `<iframe src="${window.location.origin}/#/public/embed/overview/${surveyId}" width="100%" height="100%" frameborder="0"></iframe>`
    : "Make the survey public to get the embed code";

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth={"lg"}>
      <DialogTitle sx={{ display: "flex" }}>
        <Typography variant="h6">Embed Survey Overview</Typography>
        <MuiTooltip title="Enabling this will make the survey responses public">
          <Switch
            checked={isPublic}
            onChange={(e) => setIsPublic(e.target.checked)}
            sx={{
              marginLeft: "12px",
            }}
          />
        </MuiTooltip>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <TextField
          fullWidth
          label="Embed Code"
          aria-readonly
          value={iFrameURL}
          onChange={() => {}}
          disabled={!isPublic}
        />
      </DialogContent>
    </Dialog>
  );
}
