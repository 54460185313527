import { Box, Modal } from "@mui/material";
import React from "react";
import DashboardView from "../../Views/TakeSurvey/DashboardView/DashboardView";
import { closeCircleIcon, closeIcon } from "../../Assets";

export default function QuestionPreviewModal(props) {
  return (
    <Modal
      open={props.open}
      onClose={() => props.close()}
      aria-labelledby="question-preview-modal-title"
      aria-describedby="question-preview-modal-description"
      style={{ zIndex: "9999" }}
    >
      <Box
        id="question-preview-modal-description"
        sx={{ width: "90vw", height: "90vh" }}
        className="basic-modal"
      >
        <Box
          display={"flex"}
          justifyContent="end"
          position="relative"
          marginBottom={"10px"}
        >
          <img src={closeCircleIcon} alt="" onClick={() => props.close()} />
          <img
            src={closeIcon}
            alt=""
            style={{ position: "absolute", right: "11px", top: "11px" }}
            onClick={() => props.close()}
          />
        </Box>
        <DashboardView
          logo={props?.logo}
          title={props.title}
          description={props.description}
          type={props.type}
          template={props?.template}
          options={props?.options}
          smileyLabels={props?.smileyLabels}
          reviewLink={props.reviewLink}
        />
      </Box>
    </Modal>
  );
}
