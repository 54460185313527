import { Box, Typography } from "@mui/material";
import { AiOutlineDislike, AiOutlineLike } from "react-icons/ai";
import React, { useState } from "react";

export default function LikeorDislike({ selected }) {
  const [Value, setValue] = useState("");
  return (
    <Box width={"100%"} display="flex" marginTop={"26px"} gap={2}>
      <Box
        border={Value === "like" ? "1px solid #3A63F3" : "1px solid #bebebe"}
        bgcolor={Value === "like" ? "#EFF4FF" : "#FFF"}
        className="yesorno"
        onClick={() => {
          setValue("like");
          selected("like");
        }}
      >
        <Box margin={"auto"} display="flex" alignItems={"center"}>
          <AiOutlineLike
            size="24px"
            color={Value === "like" ? "#3A63F3" : "#979797"}
          />
          <Typography
            className="singlePageSurveyOption"
            style={{ color: Value === "like" ? "#3A63F3" : "#979797" }}
          >
            Like
          </Typography>
        </Box>
      </Box>
      <Box
        border={Value === "dislike" ? "1px solid #3A63F3" : "1px solid #bebebe"}
        bgcolor={Value === "dislike" ? "#EFF4FF" : "#FFF"}
        className="yesorno"
        onClick={() => {
          setValue("dislike");
          selected("dislike");
        }}
      >
        <Box margin={"auto"} display="flex" alignItems={"center"}>
          <AiOutlineDislike
            size="24px"
            color={Value === "dislike" ? "#3A63F3" : "#979797"}
          />
          <Typography
            className="singlePageSurveyOption"
            style={{ color: Value === "dislike" ? "#3A63F3" : "#979797" }}
          >
            Dislike
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
