import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchFeedbackQuestions,
  fetchFeedbackResponse,
  fetchSurveyById,
  getBranchesByAccount,
  makeSurveyResponsePublic,
} from "../../../../Api";
import { Capitalise } from "../../../../Shared";
import ResultOverviewPage from "./ResultOverviewPage";
import ResultResponsePage from "./ResultResponsePage";
import ResultReportPage from "./ResultReportPage";
import "./Resultspage.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays, format } from "date-fns";
import { Stack } from "@mui/system";
import moment from "moment";
import { ShareOutlined } from "@mui/icons-material";
import { SurveyResponseOverviewEmbedDialog } from "./OverViewEmbedDialog";
import { gstdept } from "../../../../Assets";
import kdisc from "../../../../Assets/kdisc.gif";

let tableHeaderData = [],
  excelBodyData = [],
  tableBodyData = [],
  responseToShow,
  excelRowData = [],
  tableRowData = [];

export function ResultsPageWithoutData({
  socket,
  survey,
  responses,
  branches,
  fullResponses,
  questions,
}) {
  const id = useParams().id;
  const [Show, setShow] = useState("overview");
  const [filteredResponses, setFilteredResponses] = useState([]);

  const [Reload, setReload] = useState(false);
  const [SelectedBranch, setSelectedBranch] = useState("all");

  const [date, setDate] = useState({
    selection: {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  });
  const [formattedDate, setFormattedDate] = useState({
    startDate: "From",
    endDate: "To",
  });
  questions.map((question) =>
    tableHeaderData?.push(question.l1_title || question.l2_title)
  );
  const [openEmbedDialog, setOpenEmbedDialog] = useState(false);
  const [surveyIsPublic, setSurveyIsPublic] = useState(
    survey?.makeResponsesPublic
  );

  useEffect(() => {
    let newformat = {
      startDate: "From",
      endDate: "To",
    };
    let { startDate, endDate } = date.selection;
    if (!startDate || !endDate) {
      setFormattedDate(newformat);
      return;
    }

    newformat.startDate = format(new Date(startDate), "dd/MM/yyy");
    newformat.endDate = format(new Date(endDate), "dd/MM/yyy");
    setFormattedDate(newformat);
  }, [date.selection.startDate, date.selection.endDate, date]);

  useEffect(() => {
    let newRes = filterByDate(responses);

    newRes = filterByBranch(newRes);
    setFilteredResponses(newRes);
  }, [
    date.selection.startDate,
    date.selection.endDate,
    SelectedBranch,
    fullResponses,
    responses,
  ]);

  useEffect(() => {
    createResponseArray(filteredResponses);
  }, [filteredResponses, fullResponses]);

  const filterByDate = (responses) => {
    let { startDate, endDate } = date.selection;
    if (!startDate || !endDate) {
      return responses;
    }
    const newRes = responses.filter((r) => {
      if (!r.date) {
        return false;
      }
      const fillDate = moment(r.date).utcOffset("+05:30");
      startDate = moment(startDate).utcOffset("+05:30");
      endDate = moment(endDate).utcOffset("+05:30");

      return startDate < fillDate && endDate > fillDate;
    });
    return newRes;
  };

  const filterByBranch = (responses) => {
    if (!SelectedBranch || SelectedBranch == "all") {
      return responses;
    } else {
      return responses.filter((r) => r.branch == SelectedBranch);
    }
  };

  const createResponseArray = (currentResponses) => {
    excelBodyData = [];
    tableBodyData = [];
    currentResponses?.map((response, i) => {
      excelRowData = [];
      tableRowData = [];
      if (
        localStorage.role === "Account Owner" ||
        response?.location === localStorage?.location
      )
        questions.map((question) => {
          responseToShow = response.response.filter(
            (res) => res.questionId === question._id
          )[0];
          if (responseToShow) {
            if (responseToShow.show) {
              if (String(responseToShow.answer[0])?.includes("data:image")) {
                tableRowData?.push({
                  ...responseToShow,
                  answer: (
                    <img
                      src={`${responseToShow.answer[0]}`}
                      alt=""
                      height={"50px"}
                    />
                  ),
                });
              } else {
                excelRowData?.push(responseToShow.answer[0]);
                tableRowData?.push({
                  ...responseToShow,
                });
              }
            }
          } else {
            tableRowData.push({
              ...responseToShow,
            });
          }
        });
      tableBodyData?.push({
        id: response._id,
        issueId: response?.issueId,
        data: tableRowData,
        respondent: response.respondentId,
        branch: response.branch,
      });
      return excelBodyData?.push(excelRowData);
    });
    setReload(!Reload);
  };

  if (!survey || !questions || !branches || !fullResponses) return null;
  const isEmbed = window.location.hash.includes("embed");

  return (
    <Box className="resultspageWrapper">
      {isEmbed || Show !== "overview" ? (
        <div></div>
      ) : (
        <SurveyResponseOverviewEmbedDialog
          open={openEmbedDialog}
          close={() => setOpenEmbedDialog(false)}
          surveyId={id}
          isPublic={surveyIsPublic}
          setIsPublic={(value) =>
            makeSurveyResponsePublic(id, value).then(() =>
              setSurveyIsPublic(value)
            )
          }
        />
      )}
      <Box className="resultspageContentSwitchNavbar">
        {isEmbed ? (
          <>
            <p
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {survey.title}
            </p>
            <Stack
              direction={"row"}
              spacing={2}
              alignItems={"center"}
              style={{
                flexShrink: 1,
              }}
            >
              <img
                src={gstdept}
                alt="GST Department"
                style={{
                  maxHeight: "50px",
                  width: "auto",
                }}
              ></img>
              <img
                src={kdisc}
                alt="K-DISC"
                style={{
                  maxHeight: "45px",
                  width: "auto",
                }}
              ></img>
            </Stack>
          </>
        ) : (
          <Box className="resultpageContentSwitchNavbarButtonGroup">
            <Button
              variant="outlined"
              style={{
                backgroundColor: Show === "overview" ? "#2C7BE5" : "#FFF",
                color: Show === "overview" ? "#FFF" : "#2C7BE5",
              }}
              className="resultspageContentSwitchNavbarSingleButton"
              onClick={() => setShow("overview")}
            >
              Overview
            </Button>
            <Button
              variant="outlined"
              style={{
                backgroundColor: Show === "report" ? "#2C7BE5" : "#FFF",
                color: Show === "report" ? "#FFF" : "#2C7BE5",
              }}
              className="resultspageContentSwitchNavbarSingleButton"
              onClick={() => setShow("report")}
            >
              Report
            </Button>
            <Button
              variant="outlined"
              style={{
                backgroundColor: Show === "response" ? "#2C7BE5" : "#FFF",
                color: Show === "response" ? "#FFF" : "#2C7BE5",
              }}
              className="resultspageContentSwitchNavbarSingleButton"
              onClick={() => setShow("response")}
            >
              Responses
            </Button>
          </Box>
        )}
        <Box className="resultspageContentSwitchNavbarOptionGroup">
          {isEmbed || Show !== "overview" ? (
            <div></div>
          ) : (
            <Button
              onClick={() => setOpenEmbedDialog(true)}
              startIcon={<ShareOutlined />}
              sx={{
                flexShrink: 0,
              }}
              variant="contained"
            >
              Embed
            </Button>
          )}
          <CustomDateRange
            date={date}
            setDate={setDate}
            formattedDate={formattedDate}
          />
          {/* ===========Account owner can filter response based on branch=========== */}
          {localStorage.role === "Account Owner" && (
            <Box
              width={"100%"}
              display="flex"
              justifyContent={"end"}
              maxHeight="35px"
            >
              <FormControl sx={{ width: "150px", maxHeight: "35px" }}>
                <InputLabel id="select-label">Branch</InputLabel>
                <Select
                  defaultValue=""
                  name="Branch"
                  label="Branch"
                  id="simple-select"
                  labelId="-select-label"
                  value={SelectedBranch}
                  style={{ maxHeight: "35px" }}
                >
                  <MenuItem
                    value="all"
                    onClick={() => {
                      setSelectedBranch("all");
                    }}
                  >
                    All
                  </MenuItem>
                  {branches.map((branch, i) => (
                    <MenuItem
                      key={i}
                      value={branch._id}
                      onClick={() => {
                        setSelectedBranch(branch._id);
                      }}
                    >
                      {Capitalise(branch.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
      </Box>

      <Box component="main" className="resultspageResultContentWrapper">
        <Box className="resultspageOutcomesWrapper">
          {Show === "overview" ? (
            <ResultOverviewPage
              tableBodyData={tableBodyData}
              responses={filteredResponses}
              questions={questions}
              branches={branches}
              SelectedBranch={
                localStorage.role === "Admin"
                  ? localStorage.branch
                  : SelectedBranch
              }
            />
          ) : Show === "report" ? (
            <ResultReportPage
              responses={filteredResponses}
              surveyName={survey.title}
            />
          ) : (
            <ResultResponsePage
              header={tableHeaderData}
              responses={tableBodyData}
              questions={questions}
              branches={branches}
              socket={socket}
              branchFilter={filteredResponses}
              createResponseArray={createResponseArray}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
export function ResultsPage({ socket }) {
  const [survey, setSurvey] = useState(undefined);
  const id = useParams().id;
  const [responses, setResponses] = useState([]);
  const [branches, setBranches] = useState([]);
  const [fullResponses, setFullResponses] = useState([]);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    Promise.all([
      fetchFeedbackResponse(id),
      fetchFeedbackQuestions(id),
      getBranchesByAccount(),
      fetchSurveyById(id),
    ]).then((res) => {
      let qs = res[1]?.data;
      setSurvey(res[3]?.data);
      qs.sort((a, b) => a.order - b.order);
      setQuestions(qs);
      let fetchedResponses = res[0]?.data;
      // checking whether question is already deleted

      fetchedResponses.map((response) =>
        response.response.map((res) => {
          res.show =
            qs.filter((quest) => quest._id === res?.questionId).length !== 0
              ? true
              : false;
          res.type = qs?.filter(
            (quest) => quest._id === res?.questionId
          )[0]?.type;
          return res;
        })
      );
      setResponses(fetchedResponses);
      setFullResponses(fetchedResponses);
      setBranches(res[2]?.data);
    });
  }, [id]);

  if (!survey || !questions || !branches || !fullResponses) return null;
  return (
    <Box
      style={{
        overflow: "auto",
      }}
    >
      <ResultsPageWithoutData
        socket={socket}
        survey={survey}
        responses={responses}
        branches={branches}
        fullResponses={fullResponses}
        questions={questions}
      />
    </Box>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const CustomDateRange = ({ date, setDate, formattedDate }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClear = () => {
    setDate({
      selection: {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    });
    handleClose();
  };

  return (
    <Stack
      direction={"row"}
      sx={{ flexSrink: 0, flexGrow: 0, display: "flex", alignItems: "center" }}
    >
      <Typography sx={{ margin: "2px", color: "gray" }}>
        {formattedDate.startDate}
      </Typography>
      <span> - </span>
      <Typography sx={{ margin: "2px", color: "gray" }}>
        {formattedDate.endDate}
      </Typography>

      <Button onClick={handleOpen} sx={{ width: "120px" }}>
        Filter by date
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack>
          <Box sx={style}>
            <DateRangePicker
              onChange={(item) => {
                item.selection.endDate?.setHours(23, 59, 59, 999);
                setDate({ ...date, ...item });
              }}
              months={1}
              minDate={addDays(new Date(), -300)}
              maxDate={addDays(new Date(), 900)}
              direction="vertical"
              scroll={{ enabled: true }}
              ranges={[date.selection]}
            />
            <Stack
              direction="row"
              justifyContent={"end"}
              sx={{ width: "100%" }}
            >
              <Button sx={{ maxWidth: "150px" }} onClick={handleClear}>
                Clear
              </Button>
              <Button
                sx={{ maxWidth: "150px" }}
                variant={"contained"}
                onClick={handleClose}
              >
                Filter
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Modal>
    </Stack>
  );
};
