import React from "react";
import MultipleChoiceForm from "./MultipleChoiceForm";
import LikeOrDislikeForm from "./LikeOrDislikeForm";
import PhotoCaptureForm from "./PhotoCaptureForm";
import OpinionScaleForm from "./OpinionScaleForm";
import PhoneNumberForm from "./PhoneNumberForm";
import TestimonialForm from "./TestimonialForm";
import ThankYouForm from "./ThankYouForm";
import YesOrNoForm from "./YesOrNoForm";
import SmileyForm from "./SmileyForm";
import RatingForm from "./RatingForm";
import EmailForm from "./EmailForm";
import TextForm from "./TextForm";
import OtpForm from "./OtpForm";
import "./FormComponent.css";
import MultipleSelectForm from "./MultipleSelectForm";

export default function FormComponent(props) {
  let next = (name, value, type, image) => {
    props.next(value, name, type, image);
  };

  let prev = (count) => {
    props.prev(count);
  };
  let commonProps = {
    next: next,
    prev: prev,
    values: props.values,
    errors: props.errors,
    type: props.type,
    handleChange: props.handleChange,
    handleBlur: props.handleBlur,
    name: props.name,
    count: props.count,
    label: props.label,
    required: props.required,
    disabled: props.disabled,
    description: props?.description,
    initialValue: props.initialValue,
    totalQuestions: props.totalQuestions,
    hideButton: props?.hideButton,
  };

  return props.type === "text" ? (
    <TextForm {...commonProps} />
  ) : props.type === "multiple choice" ? (
    <MultipleChoiceForm
      {...commonProps}
      setyesorno={props.setyesorno}
      options={props.options}
    />
  ) : props.type === "multiple select" ? (
    <MultipleSelectForm
      {...commonProps}
      setyesorno={props.setyesorno}
      options={props.options}
      multipleSelectRes={props.multipleSelectRes}
      setMultipleSelectRes={props.setMultipleSelectRes}
    />
  ) : props.type === "photo capture" ? (
    <PhotoCaptureForm {...commonProps} setImage={props.setImage} />
  ) : props.type === "email" ? (
    <EmailForm {...commonProps} />
  ) : props.type === "phone number" ? (
    <PhoneNumberForm {...commonProps} />
  ) : props.type === "smiley" ? (
    <SmileyForm
      {...commonProps}
      setyesorno={props.setyesorno}
      reviewLink={props.reviewLink}
      smileyLabels={props.smileyLabels}
    />
  ) : props.type === "rating" ? (
    <RatingForm
      {...commonProps}
      setyesorno={props.setyesorno}
      reviewLink={props.reviewLink}
    />
  ) : props.type === "otp" ? (
    <OtpForm {...commonProps} />
  ) : props.type === "like or dislike" ? (
    <LikeOrDislikeForm {...commonProps} setyesorno={props.setyesorno} />
  ) : props.type === "opinion scale" ? (
    <OpinionScaleForm {...commonProps} setyesorno={props.setyesorno} />
  ) : props.type === "yes or no" ? (
    <YesOrNoForm {...commonProps} setyesorno={props.setyesorno} />
  ) : props.type === "thank you" ? (
    <ThankYouForm
      {...commonProps}
      showGoogleReview={props?.showGoogleReview}
      reviewLink={props?.reviewLink}
      image={props?.image}
    />
  ) : props.type === "testimonial" ? (
    <TestimonialForm {...commonProps} setImage={props.setImage} />
  ) : (
    <div>Page not found</div>
  );
}
