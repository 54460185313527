import React from "react";
import { Outlet } from "react-router-dom";
import "./SettingsPage.css";

function SettingsPage() {
  return (
    <div className="settingspageWrapper">
      <Outlet />
    </div>
  );
}

export default SettingsPage;
