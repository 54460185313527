import React from "react";
import "./Loading.css";
import { motion } from "framer-motion";
import Loader from "./Loader/Loader";
// import {logo} from '../../../../Assets'

function Loading({ logo }) {
  return (
    <div className="dashboardViewLogoWrapper">
      <motion.div
        className="container text-center"
        initial={{ opacity: 1, scale: 0.5 }}
        animate={{ opacity: 0, x: 0, y: -200, scale: 0.1 }}
        exit={{ opacity: 0.1 }}
        transition={{ delay: 1, duration: 1.5 }}
      >
        {logo && (
          <img
            style={{
              width: "35vw",
            }}
            src={logo}
            alt=""
          />
        )}
        <Loader />
      </motion.div>
    </div>
  );
}

export default Loading;
