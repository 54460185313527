import React, { useContext } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { EditContext } from "./EditTheme";

export const EditableText = ({
  name,
  handleClick,
  editElement,
  value,
  handleChange,
  zIndex,
}) => {
  const { styles, selected } = useContext(EditContext);
  return (
    <Box p={1} width="100%" sx={zIndex ? { zIndex } : {}}>
      {editElement === name ? (
        <TextField
          value={value[name]}
          multiline
          onChange={(e) => handleChange({ [name]: e.target.value })}
          fullWidth
          onClick={(e) => e.stopPropagation()}
        />
      ) : (
        <Typography
          onClick={(e) => handleClick(name, e.detail, e)}
          sx={{
            outline: selected === name ? "2px dashed black" : "none",
            outlineOffset: "1px",

            ...styles[name],
          }}
        >
          {value[name]}
        </Typography>
      )}
    </Box>
  );
};
