import React, { createContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  TextArea,
  Card,
  Grid,
  Stack,
  FormControl,
  FormControlLabel,
  Switch,
} from "@mui/material";
import CustomizeText from "./CustomizeText";
import BackgroundSelector from "./BackgroundSelector";
import CustomizeButton from "./CustomizeButton";
import { DisplayIntroduction } from "./DisplayIntroduction";
import { CustomizeLogo, HeaderTypeSelector } from "./CustomizeHeader";
import defaultStyles from "../../../../Shared/Theme/default";
import { getTheme, setIntro, updateTheme } from "../../../../Api";
import { useParams } from "react-router-dom";

export const EditContext = createContext();

const EditTheme = () => {
  const [selected, setSelected] = useState("");
  const [editElement, setEditElement] = useState(false);
  const [styles, setStyles] = useState(defaultStyles);
  const [introData, setIntroData] = useState({
    enabled: false,
    headerText: "welcome",
    description: "please fill the survey",
    buttonText: "Goto survey",
  });
  const { id } = useParams();

  const handleChange = (data) => {
    setStyles({
      ...styles,
      [selected]: {
        ...styles[selected],
        ...data,
      },
    });
  };
  const handleSave = () => {
    updateTheme(id, styles);
    setIntro(id, introData);
  };

  useEffect(() => {
    if (selected.startsWith("bg")) {
      setStyles({
        ...styles,
        background: selected.substring(2),
      });
    } else if (selected.startsWith("header")) {
      setStyles({
        ...styles,
        header: selected,
      });
    }
  }, [selected]);

  return (
    <Box
      p={1}
      sx={{
        width: "100%",
        flexGrow: 1,
        boxSizing: "border-box",
      }}
    >
      <EditContext.Provider
        value={{ styles, setStyles, selected, setSelected, handleChange }}
      >
        <Stack direction="row" spacing={1} height={"100%"}>
          {/*         <Stack flex={1} sx={{ border: "1px solid black" }}>
          items
        </Stack> */}
          <Box
            flex={3}
            sx={{
              border: "1px solid black",
              padding: 2,
              alignItems: "center",
              justifyItems: "centre",
              display: "flex",
              borderRadius: 8,
            }}
            className="themeBGPattern"
            onClick={() => {
              setSelected("");
              setEditElement("");
            }}
          >
            <Box
              sx={{
                aspectRatio: "16/9",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <DisplayIntroduction
                editMode
                editElement={editElement}
                setEditElement={setEditElement}
                styles={styles}
                setSelected={setSelected}
                selected={selected}
                setStyles={setStyles}
                introData={introData}
                setIntroData={setIntroData}
              />
            </Box>
          </Box>
          <Stack
            flex={1}
            sx={{ border: "1px solid black", borderRadius: "15px" }}
            p={1}
          >
            <FormControlLabel
              control={
                <Switch
                  value={introData.enabled}
                  onChange={(e) =>
                    setIntroData((prev) => ({
                      ...prev,
                      enabled: e.target.checked,
                    }))
                  }
                />
              }
              label="Enable Intro page"
            />
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
            {selected.startsWith("bg") ? <BackgroundSelector /> : null}
            {selected.startsWith("header") ? <HeaderTypeSelector /> : null}
            {selected === "headerLogo" ? <CustomizeLogo /> : null}
            {["headerText", "description", "buttonText"].includes(selected) ? (
              <CustomizeText />
            ) : null}

            {selected === "button" ? <CustomizeButton /> : null}
          </Stack>
        </Stack>
      </EditContext.Provider>
    </Box>
  );
};
export default EditTheme;
