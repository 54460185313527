import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  Grid,
  Modal,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik } from "formik";
import React, { useState } from "react";
import { loginUser } from "../Api";
import { loginModalImage } from "../Assets";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ForgotPasswordModal from "../Shared/Modals/ForgotpasswordModal";

export default function Govtlogin() {
  const navigate = useNavigate();
  const [pass, setPass] = useState({
    password: "",
    showPassword: false,
  });
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const forgotPass = () => {
    setShowForgotPassword(true);
  };

  let login = async (values, isSubmitting) => {
    loginUser({ email: values.Email, password: values.Password })
      .then((res) => {
        localStorage.setItem("userId", res.data.user?._id);
        localStorage.setItem("accountId", res.data.user?.account_id);
        localStorage.setItem("role", res.data.user?.role);
        localStorage.setItem("branch", res.data.user?.branch);
        localStorage.setItem("userName", res.data.user?.name);
        localStorage.setItem("planId", res.data.account.plan_id);
        localStorage.setItem("email", res.data.user.email);
        localStorage.setItem("token", res.data.jwtToken);
        if (
          localStorage.userId &&
          localStorage.accountId &&
          localStorage.role &&
          localStorage.branch &&
          localStorage.userName &&
          localStorage.planId &&
          localStorage.email
        ) {
          navigate("/surveys");
        }
      })
      .catch((err) => {
        if (err.response.status === 429) {
          toast.error("Too many requests, please try again after 15 minutes");
        } else {
          toast.error("Couldn't login");
          isSubmitting.setSubmitting(false);
        }
      });
  };
  let validateForm = (values) => {
    const errors = {};
    if (!values.Email) errors.Email = "Enter your registered email address";
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email))
      errors.Email = "Invalid email address";
    if (!values.Password) errors.Password = "Enter your password";
    return errors;
  };
  const handlePassowrd = () => {
    setPass({
      ...pass,
      showPassword: !pass.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Box style={{ maxWidth: "100vw", maxHeight: "100vh" }}>
      <ForgotPasswordModal
        open={showForgotPassword}
        close={() => setShowForgotPassword(false)}
      />
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        width={"100%"}
        style={{ height: "100vh" }}
      >
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* ===========================login form=========================== */}
          <Formik
            initialValues={{ Email: "", Password: "" }}
            validate={validateForm}
            onSubmit={(values, isSubmitting) => login(values, isSubmitting)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box>
                  <FormGroup style={{ marginBottom: "15px" }}>
                    <FormLabel style={{ color: "#000", marginBottom: "9px" }}>
                      Email
                    </FormLabel>
                    <TextField
                      type="email"
                      name="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Email}
                      size="small"
                      placeholder="Enter Your email"
                      variant="outlined"
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.Email && touched.Email && errors.Email}
                    </div>
                  </FormGroup>
                  <FormGroup style={{ marginBottom: "15px" }}>
                    <FormLabel style={{ color: "#000", marginBottom: "9px" }}>
                      Password
                    </FormLabel>
                    <TextField
                      type={pass.showPassword ? "text" : "password"}
                      name="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Password}
                      placeholder="Enter Your password"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handlePassowrd}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {pass.showPassword ? (
                                <VisibilityOff
                                  sx={{ width: "15px", height: "15px" }}
                                />
                              ) : (
                                <Visibility
                                  sx={{ width: "15px", height: "15px" }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {" "}
                      {errors.Password && touched.Password && errors.Password}
                    </div>
                  </FormGroup>
                </Box>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "12px",
                  }}
                >
                  <Button
                    size="small"
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{ fontWeight: "700", height: "40px" }}
                    disabled={isSubmitting}
                  >
                    Login
                  </Button>
                </Box>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1px",
                  }}
                >
                  <Typography style={{ marginTop: "29px" }} align="center">
                    <span
                      style={{ fontWeight: "600", cursor: "pointer" }}
                      onClick={() => forgotPass()}
                    >
                      Forgot your password?{" "}
                    </span>
                  </Typography>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
        {/* <Typography
          align="center"
          variant="body2"
          style={{ color: "#3F3D56", marginBottom: "35px" }}
        >
          © SurveyFynext Inc | Privacy Policy |Terms & Conditions
        </Typography> */}
      </Box>
    </Box>
  );
}
