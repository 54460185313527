import { Box } from "@mui/material";
import React from "react";
import { fullStar } from "../../Assets";

export default function Rating({ number, height }) {
  return (
    <Box display="flex" gap={1}>
      {number >= 1 && <img src={fullStar} alt="star" height={height} />}
      {number >= 2 && <img src={fullStar} alt="star" height={height} />}
      {number >= 3 && <img src={fullStar} alt="star" height={height} />}
      {number >= 4 && <img src={fullStar} alt="star" height={height} />}
      {number >= 5 && <img src={fullStar} alt="star" height={height} />}
    </Box>
  );
}
