import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import { addMergedSurveys } from "../../Api";
import React from "react";
import { toast } from "react-toastify";

export default function MergeSurveyModal({ open, close, surveys }) {
  let validateForm = (values) => {
    const errors = {};
    if (!values.Title) errors.Title = "Enter a title for your survey";
    // if (values.Language.length > 2)
    //   errors.Language = "Select any two languages only";
    return errors;
  };

  const addMerge = (values, isSubmitting) => {
    addMergedSurveys(
      localStorage.userId,
      values.Title,
      values.merged_array
    ).then((res) => {
      toast.success("surveys merged successfully");
      close(res.data.data);
    });
  };
  return (
    <Modal
      open={open}
      onClose={() => close()}
      aria-labelledby="create-survey-modal-title"
      aria-describedby="create-survey-modal-description"
    >
      <Box sx={{ width: { xs: "81%", sm: "400px" } }} className="basic-modal">
        <h2 id="create-survey-modal-title" style={{ marginBottom: "16px" }}>
          Merge Survey
        </h2>
        <Formik
          validate={validateForm}
          initialValues={{
            Title: "",
            merged_array: [],
          }}
          onSubmit={(values, isSubmitting) => addMerge(values, isSubmitting)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} id="create-survey-modal-description">
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                name="Title"
                variant="outlined"
                onBlur={handleBlur}
                label="Survey Title"
                value={values.Title}
                onChange={handleChange}
              />
              <div
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginBottom: "16px",
                }}
              >
                {errors.Title && touched.Title && errors.Title}
              </div>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Surveys</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.merged_array}
                  name="merged_array"
                  label="Surveys"
                  multiple
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {surveys.map((survey) => (
                    <MenuItem value={survey._id}>{survey.title}</MenuItem>
                  ))}
                </Select>
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginBottom: "16px",
                  }}
                >
                  {errors.merged_array &&
                    touched.merged_array &&
                    errors.merged_array}
                </div>
              </FormControl>

              <Box display="flex" justifyContent={"end"}>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ marginTop: "24px" }}
                  disabled={isSubmitting}
                >
                  Merge
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}
