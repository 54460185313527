import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { resultReportImage } from "../../../../Assets";
import "./ResultReportPage.css";
export default function ResultReportPage(props) {
  let { responses, surveyName } = props;
  return (
    <Card className="resultreportpageWrapper">
      <CardContent>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              padding: { sm: "16px 43px", xs: "16px" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>
              <Typography
                variant="subtitle2"
                style={{ color: "#565987" }}
                fontWeight="700"
              >
                Visitors Report
              </Typography>
              <Typography variant="h3" fontWeight="700">
                {surveyName}
              </Typography>
              <Typography variant="body2" fontWeight="500">
                Explore the data behind your survey responses. Gain a better
                perspective of your survey data and uncover insights for further
                planning.
              </Typography>
            </Box>
            <Grid
              container
              style={{
                flex: "1 1 auto",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xs={6}
                display={"flex"}
                justifyContent={"center"}
                flexDirection="column"
                alignItems={"center"}
              >
                <Typography variant="h4" align="center" fontWeight="700">
                  {localStorage.role === "Account Owner"
                    ? responses.length
                    : responses.filter(
                        (res) => res.location === localStorage?.location
                      ).length}
                  <Typography variant="subtitle2" style={{ color: "#A8A8A8" }}>
                    Visited
                  </Typography>
                </Typography>
              </Grid>
              <Grid
                item
                display={"flex"}
                justifyContent={"center"}
                flexDirection="column"
                alignItems={"center"}
                xs={6}
              >
                <Typography variant="h4" align="center" fontWeight="700">
                  2
                  <span style={{ color: "#A8A8A8", fontSize: "12px" }}>
                    min
                  </span>
                  <Typography variant="subtitle2" style={{ color: "#A8A8A8" }}>
                    avg time to complete
                  </Typography>
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                display={"flex"}
                justifyContent={"center"}
                flexDirection="column"
                alignItems={"center"}
              >
                <Typography variant="h4" align="center" fontWeight="700">
                  {localStorage.role === "Account Owner"
                    ? responses.length
                    : responses.filter(
                        (res) => res.location === localStorage?.location
                      ).length}
                  <Typography variant="subtitle2" style={{ color: "#A8A8A8" }}>
                    Started
                  </Typography>
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                display={"flex"}
                justifyContent={"center"}
                flexDirection="column"
                alignItems={"center"}
              >
                <Typography variant="h4" align="center" fontWeight="700">
                  {localStorage.role === "Account Owner"
                    ? responses.length
                    : responses.filter(
                        (res) => res.location === localStorage?.location
                      ).length}
                  <Typography variant="subtitle2" style={{ color: "#A8A8A8" }}>
                    Completed
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={7}
            xs={12}
            justifyContent="end"
            display={{ sm: "flex", xs: "none" }}
          >
            <img src={resultReportImage} alt="report" />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
