import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik } from "formik";
import React from "react";
import { loginModalImage } from "../../Assets";
import { createAccount, getUserByEmail } from "../../Api";

export default function RegisterModal(props) {
  const navigate = useNavigate();

  let Register = (values, isSubmitting) => {
    createAccount({
      name: values.Name,
      password: values.ConfirmPassword,
      email: values.Email,
      phone: values.Phone,
      domain: values.Domain,
    })
      .then((res) => {
        toast.success(
          "Request sent successfully. You will be notified on approval."
        );
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occured. please try again");
        isSubmitting.setSubmitting(false);
      });
  };

  // else {
  //   toast.error("Email already registered. Please use another email");
  //   isSubmitting.setSubmitting(false);
  // }

  let validateForm = (values) => {
    const errors = {};
    if (!values.Name) errors.Name = "Enter your name";
    if (!values.Email) errors.Email = "Enter your email address";
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email))
      errors.Email = "Invalid email address";
    if (values.Phone && !/^[0-9\b]+$/.test(values.Phone))
      errors.Phone = "Invalid phone number";
    return errors;
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.close()}
      aria-labelledby="register-modal-title"
      aria-describedby="register-modal-description"
    >
      <Grid
        container
        sx={{ width: "70%", height: "90%", padding: "0" }}
        className="basic-modal"
        id="register-modal-description"
      >
        <Grid item lg={6} className="login-modal-image">
          <img src={loginModalImage} alt="" />
        </Grid>
        <Grid
          item
          lg={6}
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          width={"100%"}
        >
          <Box
            className="login-modal"
            style={{
              width: "100%",
              height: "100%",
            }}
            display="flex"
            flexDirection={"column"}
            justifyContent="center"
          >
            {/* =====================Register form========================= */}
            <Formik
              initialValues={{
                Name: "",
                Email: "",
                Phone: "",
              }}
              validate={validateForm}
              onSubmit={(values, isSubmitting) =>
                Register(values, isSubmitting)
              }
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item lg={6} xs={6}>
                      <FormGroup style={{ marginBottom: "15px" }}>
                        <FormLabel
                          style={{ color: "#000", marginBottom: "9px" }}
                        >
                          Name
                        </FormLabel>
                        <TextField
                          name="Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Name}
                          placeholder="Enter Name"
                          size="small"
                          variant="standard"
                        />
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {errors.Name && touched.Name && errors.Name}
                        </div>
                      </FormGroup>
                      <FormGroup style={{ marginBottom: "15px" }}>
                        <FormLabel
                          style={{ color: "#000", marginBottom: "9px" }}
                        >
                          Email
                        </FormLabel>
                        <TextField
                          type="email"
                          name="Email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Email}
                          placeholder="Email"
                          size="small"
                          variant="standard"
                        />
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {errors.Email && touched.Email && errors.Email}
                        </div>
                      </FormGroup>
                    </Grid>
                    <Grid item lg={6} xs={6}>
                      <FormGroup style={{ marginBottom: "15px" }}>
                        <FormLabel
                          style={{ color: "#000", marginBottom: "9px" }}
                        >
                          Phone
                        </FormLabel>
                        <TextField
                          type="tel"
                          name="Phone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Phone}
                          placeholder="Phone"
                          size="small"
                          variant="standard"
                        />
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {errors.Phone && touched.Phone && errors.Phone}
                        </div>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "12px",
                    }}
                  >
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      style={{ fontWeight: "700", width: "157px" }}
                      disabled={isSubmitting}
                    >
                      Get Started
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>

            <Typography
              variant="body2"
              align="center"
              style={{ marginTop: "35px", marginBottom: "35px" }}
            >
              Already using SurveyFynext?&nbsp;
              <span
                style={{ fontWeight: "600", cursor: "pointer" }}
                onClick={() => props.login()}
              >
                {" "}
                Login
              </span>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
}
