import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { CustomizedColorPicker } from "./CustomizeText";
import ImageIcon from "@mui/icons-material/Image";
import { textTransform } from "@mui/system";
import { EditContext } from "./EditTheme";

const BackgroundSelector = () => {
  const { styles, selected, handleChange, setSelected } =
    useContext(EditContext);

  return (
    <Grid container>
      <Grid item xs={12}>
        <RadioGroup
          row
          value={styles.background}
          onChange={(e, value) => setSelected(`bg${value}`)}
        >
          <FormControlLabel value="image" control={<Radio />} label="Image" />
          <FormControlLabel value="color" control={<Radio />} label="Color" />
        </RadioGroup>
        {selected === "bgcolor" ? (
          <CustomizedColorPicker propName="backgroundColor" />
        ) : (
          <Stack>
            <ImageUploader
              propName="backgroundImage"
              textTransform={[
                (value) => `url('${value}')`,
                (value) => {
                  return value.substring(4, value.length - 2);
                },
              ]}
            />
            <CustomSlider
              propName="opacity"
              label="Opacity"
              textTransform={[
                (value) => `${value}%`,
                (value) => value.substring(0, value.length - 1),
              ]}
            />
            <CustomSlider
              propName="filter"
              label="Blur"
              textTransform={[
                (value) => `blur(${value}px)`,
                (value) => {
                  return value.substring(5, value.length - 3);
                },
              ]}
            />
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export function ImageUploader({ propName, textTransform }) {
  const { handleChange } = useContext(EditContext);
  const [getText, extractText] = textTransform;

  let uploadImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file.size > 2000000) return alert("File size should be less than 2MB");
    reader.readAsDataURL(file);
    reader.addEventListener("load", () => {
      handleChange({
        [propName]: getText(reader.result),
      });
    });
  };
  return (
    <Box>
      <Button
        variant="outlined"
        startIcon={<ImageIcon />}
        component="label"
        fullWidth
      >
        Upload Image
        <input type="file" hidden accept="image/*" onChange={uploadImage} />
      </Button>
      {/* TODO: aspect ratio warning system */}
      {/*       
      {image ? (
        <Box
          sx={{ width: "120px", aspectRatio: "16/9", backgroundColor: "unset" }}
        >
          <img src={image} width="120px" />
        </Box>
      ) : null} */}
    </Box>
  );
}

export function CustomSlider({ propName, label, textTransform, min, max }) {
  const { styles, selected, handleChange } = useContext(EditContext);

  const [setText, extractText] = textTransform
    ? textTransform
    : [(value) => value, (value) => value];
  const onChange = (e, value) => {
    handleChange({
      [propName]: textTransform ? setText(value) : value,
    });
  };

  return (
    <Box>
      <Typography>{label}</Typography>
      <Slider
        max={max ? max : 100}
        min={min ? min : 0}
        value={Number.parseInt(extractText(styles[selected][propName]))}
        valueLabelDisplay="auto"
        onChange={onChange}
      />
    </Box>
  );
}

export default BackgroundSelector;
