import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Custombutton from "../../../../Shared/customButton/Custombutton";

function Requesttable({ type, headerArray, bodyArray, changeAccountStatus }) {
  return (
    <Table aria-label="simple table" size="small">
      <TableHead>
        <TableRow>
          {headerArray.map((th, i) => (
            <TableCell
              style={{
                fontSize: "1.125rem",
                fontWeight: "900",
              }}
              align="center"
              key={i}
            >
              {th}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {bodyArray.map((bA, i) => (
          <TableRow key={i}>
            <TableCell align="center">{bA.name}</TableCell>
            <TableCell align="center">{bA.email}</TableCell>
            {bA.message && <TableCell align="center">{bA.message}</TableCell>}
            <TableCell align="center">{bA.phone}</TableCell>
            <TableCell align="center">{bA.category}</TableCell>
            <TableCell align="center">{bA.location}</TableCell>
            <TableCell align="center">{bA.comments}</TableCell>
            <TableCell align="center">
              {(type === "pending" || type === "rejected") && (
                <Custombutton
                  content="Approve"
                  onCLick={() => changeAccountStatus(bA.account_id, "approved")}
                />
              )}
              {(type === "pending" || type === "approved") && (
                <Custombutton
                  content="Deny"
                  onCLick={() => changeAccountStatus(bA.account_id, "rejected")}
                />
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default Requesttable;
