import { Box, Grid } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import {
  emailIcon,
  likeOrDislikeIcon,
  multipleChoiceIcon,
  opinionScaleIcon,
  otpIcon,
  phoneIcon,
  photoCaptureIcon,
  ratingIcon,
  smileyIcon,
  textIcon,
  thankyouIcon,
  yesorNoIcon,
} from "../../../Assets";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import "./SelectOptionSection.css";

export default function SelectOptionSection(props) {
  const handleThankYouClick = () => {
    if (props.thankYouExists) {
      toast.error("Only single Thank You slide can be added in a survey");
    } else {
      props.show("thank you");
    }
  };
  return (
    <Box margin="auto" padding={{ sm: "24px", xs: "12px" }} maxWidth="850px">
      <Grid container spacing={3} className={"SelectOptionContainer"}>
        <Grid item xs={3} onClick={() => props.show("yes or no")}>
          <img src={yesorNoIcon} alt="" className="questionTypeIcon" />
          Yes or No
        </Grid>
        <Grid item xs={3} onClick={() => props.show("like or dislike")}>
          <img src={likeOrDislikeIcon} alt="" className="questionTypeIcon" />
          Like or Dislike
        </Grid>
        <Grid item xs={3} onClick={() => props.show("photo capture")}>
          <img src={photoCaptureIcon} alt="" className="questionTypeIcon" />
          Photo Capture
        </Grid>
        {props.surveyType === "single page" || localStorage.planId !== "0" ? (
          <Grid item xs={3} style={{ color: "#A8A8A8", cursor: "not-allowed" }}>
            <img src={otpIcon} alt="" className="questionTypeIcon" />
            Otp
          </Grid>
        ) : (
          <Grid item xs={3} onClick={() => props.show("otp")}>
            <img src={otpIcon} alt="" className="questionTypeIcon" />
            Otp
          </Grid>
        )}

        <Grid item xs={3} onClick={() => props.show("multiple choice")}>
          <img src={multipleChoiceIcon} alt="" className="questionTypeIcon" />
          Multiple Choice
        </Grid>
        <Grid item xs={3} onClick={() => props.show("multiple select")}>
          <LibraryAddCheckOutlinedIcon
            sx={{ fontSize: 26, position: "relative" }}
          />
          Multiple Select
        </Grid>
        <Grid item xs={3} onClick={() => props.show("email")}>
          <img src={emailIcon} alt="" className="questionTypeIcon" />
          Email
        </Grid>
        <Grid item xs={3} onClick={() => props.show("rating")}>
          <img src={ratingIcon} alt="" className="questionTypeIcon" />
          Rating
        </Grid>
        <Grid item xs={3} onClick={() => props.show("text")}>
          <img src={textIcon} alt="" className="questionTypeIcon" />
          Text
        </Grid>

        <Grid item xs={3} onClick={() => props.show("phone number")}>
          <img src={phoneIcon} alt="" className="questionTypeIcon" />
          Phone Number
        </Grid>
        <Grid item xs={3} onClick={() => props.show("opinion scale")}>
          <img src={opinionScaleIcon} alt="" className="questionTypeIcon" />
          Opinion Scale
        </Grid>
        <Grid item xs={3} onClick={() => props.show("smiley")}>
          <img src={smileyIcon} alt="" className="questionTypeIcon" />
          Smiley
        </Grid>
        <Grid item xs={3} onClick={handleThankYouClick}>
          <img src={thankyouIcon} alt="" className="questionTypeIcon" />
          Thank you Page
        </Grid>
      </Grid>
    </Box>
  );
}
