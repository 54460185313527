import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

const opinionArray = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
export default function OpinionScale({ selected }) {
  const [Value, setValue] = useState("");
  return (
    <Box
      display={"flex"}
      gap={"0.5vw"}
      marginTop="24px"
      color={"#000"}
      maxWidth="100%"
      sx={{ overflowY: "auto" }}
    >
      {opinionArray.map((op, i) => (
        <Box
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            border: Value === op ? "1px solid #3A63F3" : "1px solid #bebebe",
            backgroundColor: Value === op ? "#EFF4FF" : "#FFF",
            height: "60px",
            width: "60px",
            cursor: "pointer",
          }}
          onClick={() => {
            setValue(op);
            selected(op);
          }}
          key={i}
        >
          <Typography
            style={{
              fontWeight: "500",
              fontSize: "1.5rem",
              color: Value === op ? "#3A63F3" : "#979797",
            }}
          >
            {op}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
