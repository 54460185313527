import React, { createContext, useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Stack } from "@mui/material";
import { EditableText } from "./EditableText";
import { EditContext } from "./EditTheme";
import { textAlign } from "@mui/system";
import { createTheme, getIntro, getTheme } from "../../../../Api";
import { useNavigate, useParams } from "react-router-dom";
import defaultStyles from "../../../../Shared/Theme/default";
import { Style } from "@mui/icons-material";

export function DisplayIntroduction({
  editMode,
  editElement,
  styles,
  setStyles,
  selected,
  setSelected,
  setEditElement,
  gotoSurvey,
  introData,
  setIntroData,
}) {
  const { id } = useParams();

  useEffect(() => {
    getTheme(id)
      .then((res) => {
        setStyles(res.data);
      })
      .catch((err) => {
        createTheme(id, styles);
      });
    getIntro(id)
      .then((res) => {
        if (Object.keys(res.data).length !== 0) setIntroData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleChange = (data) => {
    setIntroData({ ...introData, ...data });
  };

  const handleClick = (element, clicks, e) => {
    if (editMode) {
      e.stopPropagation();
      if (clicks === 1) {
        setSelected(element);
        setEditElement("");
      } else if (clicks === 2) {
        setEditElement(element);
      }
    } else if (element.startsWith("button")) {
      gotoSurvey();
    }
  };
  const { variant, ...restButtonStyles } = styles.button;
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        position: "relative",
        backgroundColor: "black",
        "&::before": {
          content: `""`,
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          zIndex: "1",
          ...styles[`bg${styles.background}`],
        },
        outline:
          editMode && selected.startsWith("bg") ? "2px dashed black" : "none",
        outlineOffset: "1px",
      }}
      onClick={(e) => handleClick(`bg${styles.background}`, 1, e)}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ zIndex: 2, position: "relative", height: "100%" }}
      >
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            justifyContent: "end",
          }}
        >
          {styles.header === "headerText" ? (
            <EditableText
              name="headerText"
              handleClick={handleClick}
              editElement={editElement}
              value={introData}
              handleChange={handleChange}
            />
          ) : (
            <Box
              onClick={(e) => handleClick("headerLogo", e.detail, e)}
              sx={{
                outline:
                  selected === "headerLogo" ? "2px dashed black" : "none",
                outlineOffset: "1px",
                width: "100%",
                textAlign: "center",
              }}
            >
              <img
                src={styles.headerLogo.image}
                alt="Logo"
                width={styles.headerLogo.width}
                height="auto"
              />
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={8}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            justifyContent: "start",
            paddingTop: "20px",
          }}
        >
          <EditableText
            name="description"
            handleClick={handleClick}
            editElement={editElement}
            value={introData}
            handleChange={handleChange}
          />

          <Button
            variant={"contained"}
            onClick={(e) => {
              handleClick("button", 1, e);
            }}
            sx={{
              ...{
                position: "relative",
                outline: selected === "button" ? "2px dashed black" : "none",
                outlineOffset: "1px",
                margin: "10px",

                zIndex: 0,
                "&::before": {
                  content: `""`,
                  position: "absolute",
                  top: "0",
                  left: "0",
                  right: "0",
                  bottom: "0",
                  zIndex: "1",
                  backgroundColor: restButtonStyles.backgroundColor,
                  borderRadius: restButtonStyles.borderRadius,
                },
                "&:hover::before": {
                  filter: "brightness(70%)",
                },
              },
              ...restButtonStyles,
            }}
          >
            <EditableText
              name="buttonText"
              handleClick={handleClick}
              editElement={editElement}
              value={introData}
              handleChange={handleChange}
              zIndex={3}
            />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export function SandBoxedIntroduction() {
  const [styles, setStyles] = useState(defaultStyles);
  const { branch, id, respondentId } = useParams();
  const navigate = useNavigate();
  const [displayIntro, setDisplayIntro] = useState(false);

  const [introData, setIntroData] = useState({
    headerText: "welcome",
    description: "please fill the survey",
    buttonText: "Goto survey",
  });

  let url = `/feedback/${branch}/${id}`;
  if (respondentId) {
    url = `${url}/${respondentId}`;
  }
  const gotoSurvey = () => {
    navigate(url);
  };

  useEffect(() => {
    getIntro(id)
      .then((res) => {
        if (!res.data.enabled) {
          gotoSurvey();
        } else {
          setIntroData(res.data);
          setDisplayIntro(true);
        }
      })
      .catch((err) => {
        console.log(err);
        gotoSurvey();
      });
  }, []);

  return (
    <EditContext.Provider value={{ styles, setStyles }}>
      <Box height="100vh" width="100vw">
        {displayIntro ? (
          <DisplayIntroduction
            styles={styles}
            setStyles={setStyles}
            startUrl={url}
            introData={introData}
            gotoSurvey={gotoSurvey}
          />
        ) : (
          //TODO: Display loader animation
          <div></div>
        )}
      </Box>
    </EditContext.Provider>
  );
}
