import { Box, FormLabel, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Capitalise from "../Capitalise/Capitalise";

export default function YesOrNoForm(props) {
  const [Value, setValue] = useState(props.initialValue || "");

  useEffect(() => {
    setValue(props.initialValue);
  }, [props.initialValue, props.errors]);

  let handleNext = (values, type) => {
    props.next(props.name, values, type);
  };

  let responseClick = (condition) => {
    props.setyesorno(condition);
  };

  return (
    <Box
      width="100%"
      paddingBottom="20px"
      flexDirection={"column"}
      justifyContent="center"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{ height: "100%" }}
      >
        <FormLabel
          required={props.required}
          sx={{
            display: "flex",
            marginBottom: "8px",
            color: "#3F3D56",
            fontSize: !props.hideButton
              ? {
                  md: props.label.length > 80 ? "1rem" : "1.5rem",
                  xs: props.label.length > 80 ? "0.75" : "1.25rem",
                }
              : {
                  md: props.label.length > 80 ? "0.9rem" : "1.1rem",
                  xs: props.label.length > 80 ? "0.75" : "1.1rem",
                },
            fontWeight: !props.hideButton ? "750" : "600",
            maxWidth: "100%",
          }}
          className="details-label bold"
        >
          {Capitalise(props.label)}
        </FormLabel>
        {props.description && (
          <Typography style={{ color: "grey", fontSize: "18px" }}>
            {props.description}
          </Typography>
        )}
        <Box marginTop={"24px"} display={"flex"} gap={2}>
          <Box
            style={{
              width: !props.hideButton ? "102px" : "64px",
              height: !props.hideButton ? "90px" : "50px",
              background:
                Value === "no" ? "#3A63F3" : "rgba(58, 99, 243, 0.05)",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: !props.disabled ? "pointer" : "inherit",
            }}
            onClick={
              !props.disabled
                ? () => {
                    setValue("no");
                    responseClick(1);
                    props.values.Value = "no";
                    setTimeout(() => handleNext(props.values, props.type), 300);
                  }
                : ""
            }
          >
            <Typography
              fontWeight={"500"}
              style={{ color: Value === "no" ? "white" : "black" }}
            >
              No
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection="column" alignItems={"start"}>
            <Box
              style={{
                width: !props.hideButton ? "102px" : "64px",
                height: !props.hideButton ? "90px" : "50px",
                background:
                  Value === "yes" ? "#3A63F3" : "rgba(58, 99, 243, 0.05)",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: !props.disabled ? "pointer" : "inherit",
              }}
              onClick={
                !props.disabled
                  ? () => {
                      setValue("yes");
                      responseClick(0);
                      props.values.Value = "yes";
                      setTimeout(
                        () => handleNext(props.values, props.type),
                        300
                      );
                    }
                  : ""
              }
            >
              <Typography
                fontWeight={"500"}
                style={{ color: Value === "yes" ? "white" : "black" }}
              >
                Yes
              </Typography>
            </Box>
          </Box>
        </Box>
      </motion.div>
    </Box>
  );
}
