import { AddAPhoto } from "@mui/icons-material";
import { Box, Button, FormLabel, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import Capitalise from "../Capitalise/Capitalise";
import "./NextPrevious/NextPrevious.css";

export default function PhotoCaptureForm(props) {
  const [OpenWebcam, setOpenWebcam] = useState(false);
  const [image, setImage] = useState(props.initialValue || null);

  useEffect(() => {
    setImage(props.initialValue || null);
  }, [props.initialValue]);

  return (
    <Box
      width="100%"
      paddingBottom={"20px"}
      flexDirection={"column"}
      justifyContent="center"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{ height: "100%" }}
      >
        <FormLabel
          required={props.required}
          sx={{
            display: "flex",
            marginBottom: "8px",
            color: "#3F3D56",
            fontSize: !props.hideButton
              ? {
                  md: props.label.length > 80 ? "1rem" : "1.5rem",
                  xs: props.label.length > 80 ? "0.75" : "1.25rem",
                }
              : {
                  md: props.label.length > 80 ? "0.9rem" : "1.1rem",
                  xs: props.label.length > 80 ? "0.75" : "1.1rem",
                },
            fontWeight: !props.hideButton ? "750" : "600",
            maxWidth: "100%",
          }}
          className="details-label bold"
        >
          {Capitalise(props.label)}
        </FormLabel>
        {props.description && (
          <Typography style={{ color: "grey", fontSize: "18px" }}>
            {props.description}
          </Typography>
        )}
        {!OpenWebcam && !image ? (
          <Box>
            <Box
              width={!props.hideButton ? "200px" : "100px"}
              height={!props.hideButton ? "200px" : "100px"}
              display="flex"
              justifyContent={"center"}
              alignItems="center"
              sx={{
                backgroundColor: "#A8A8A8",
                borderRadius: !props.hideButton ? "24px" : "4px",
              }}
              onClick={!props.disabled ? () => setOpenWebcam(true) : ""}
            >
              <AddAPhoto color="primary" size={!props.hideButton ? 24 : 14} />
            </Box>
            <Box
              display="flex"
              justifyContent="end"
              gap={3}
              style={{ position: "absolute", bottom: "0", right: "0" }}
            >
              {/* {props?.count !== 1 && !props.hideButton && (
                <Button
                  className="nextPreviousButton"
                  disabled={props.disabled}
                  onClick={handlePrev}
                >
                  <img
                    src={leftarrow}
                    alt=""
                    className="nextPreviousButtonImage"
                  />
                  <span className="nextPreviousButtonText">Prev</span>
                </Button>
              )} */}
              {/* {!props.required && !props.hideButton && (
                <Button
                  className="nextPreviousButton"
                  onClick={handleNext}
                  disabled={props.disabled}
                >
                  <span className="nextPreviousButtonText">
                    {props?.count === props?.totalQuestions
                      ? "Submit"
                      : "Next"}
                  </span>
                  <img
                    src={rightarrow}
                    alt=""
                    className="nextPreviousButtonImage"
                  />
                </Button>
              )} */}
            </Box>
          </Box>
        ) : !OpenWebcam && image ? (
          <Box
            display="flex"
            flexDirection={"column"}
            justifyContent="space-around"
            style={{ width: "100%" }}
          >
            <img
              src={image}
              alt="img"
              style={{ maxWidth: !props.hideButton ? "200px" : "100px" }}
            />
            <Box
              display="flex"
              justifyContent="end"
              gap={3}
              style={{ position: "absolute", bottom: "0", left: "0" }}
            >
              {/* {props?.count !== 1 && !props.hideButton && (
                <Button
                  className="nextPreviousButton"
                  disabled={props.disabled}
                  onClick={handlePrev}
                >
                  <img
                    src={leftarrow}
                    alt=""
                    className="nextPreviousButtonImage"
                  />
                  <span className="nextPreviousButtonText">Prev</span>
                </Button>
              )} */}
              {!props.hideButton && (
                <Button
                  className="nextPreviousButton"
                  disabled={props.disabled}
                  onClick={() => {
                    setImage(null);
                    setOpenWebcam(true);
                  }}
                >
                  <span className="nextPreviousButtonText">Retake</span>
                </Button>
              )}
              {/* {!props.hideButton &&
                <Button
                  className="nextPreviousButton"
                  onClick={handleNext}
                  disabled={props.disabled}
                >
                  <span className="nextPreviousButtonText">
                    {props?.count === props?.totalQuestions
                      ? "Submit"
                      : "Next"}
                  </span>
                  <img
                    src={rightarrow}
                    alt=""
                    className="nextPreviousButtonImage"
                  />
                </Button>} */}
            </Box>
          </Box>
        ) : OpenWebcam ? (
          <Box
            width={"100%"}
            display="flex"
            flexDirection={"column"}
            alignItems="center"
          >
            <Webcam
              mirrored
              screenshotFormat="image/jpeg"
              style={{
                width: !props.hideButton ? "300px" : "200px",
                height: !props.hideButton ? "300px" : "200px",
              }}
            >
              {({ getScreenshot }) => (
                <Button
                  className="medium"
                  onClick={() => {
                    let temp = getScreenshot();
                    setImage(temp);
                    setOpenWebcam(false);
                    props.setImage(temp);
                  }}
                >
                  Capture photo
                </Button>
              )}
            </Webcam>
          </Box>
        ) : (
          ""
        )}
      </motion.div>
    </Box>
  );
}
