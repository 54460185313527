import { useParams } from "react-router-dom";
import { deleteResponsePublic, getNpsData } from "../../../Api";
import { useEffect, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { npsChartColor } from "../../CreateSurvey/EditSurveyPage/Responses/colors";
import { Container, Stack, Typography } from "@mui/material";
import { ArrowRight } from "@mui/icons-material";
import { Capitalise } from "../../../Shared";

export default function NpsGraphEmbed() {
  const { questionId, branchId } = useParams();
  const [npsData, setNpsData] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    getNpsData(questionId, branchId || "all")
      .then((res) => {
        setNpsData(res.data.npsData);
        setTitle(res.data.title);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [questionId]);
  if (npsData.length === 0) {
    return <></>;
  }
  return (
    <Container
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack alignItems={"center"}>
        <Stack direction={"row"} width={"100%"}>
          <ArrowRight />
          <Typography>{Capitalise(title)}</Typography>
        </Stack>
        <PieChart width={200} height={200}>
          <Pie
            data={npsData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={55}
            fill="#82ca9d"
            endAngle={90}
            startAngle={360 + 90}
          >
            {npsData.map((entry, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={npsChartColor[index % npsChartColor.length]}
                />
              );
            })}
          </Pie>
          <Tooltip
            allowEscapeViewBox={{ x: true, y: true }}
            contentStyle={{
              zIndex: "1001",
              maxHeight: "100px",
            }}
            height={100}
          />
        </PieChart>
      </Stack>
    </Container>
  );
}
