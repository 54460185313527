import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import QuestionPreviewModal from "../Modals/QuestionPreviewModal";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import QuestionField from "../QuestionField/QuestionField";
import { deleteIcon } from "../../Assets";
import { MdOutlineAdd } from "react-icons/md";
import { toast } from "react-toastify";
import MultipleSelectOtptionCard from "../QuestionOptionCards/MultipleSelectOptionCard";

export default function MultipleSelectQuestion(props) {
  const [OpenPreviewModal, setOpenPreviewModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("first");
  const [Required, setRequired] = useState(props.question?.required || false);
  const [options, setOptions] = useState(
    selectedLanguage === "second"
      ? props.question.l2_options
      : props.question.l1_options
  );
  const [option, setOption] = useState("");
  const [logic, setLogic] = useState(props.question.logic || []);

  useEffect(() => {
    setOptions(
      selectedLanguage === "second"
        ? props.question.l2_options
        : props.question.l1_options
    );
    setRequired(props.question?.required || false);
  }, [props]);

  let addChoice = (e) => {
    //TODO: make this instantaneous
    if (option === "") {
      toast.error("Cannot create empty options!");
      return;
    } else if (options.indexOf(option) != -1) {
      toast.error("Cannot create duplicate options!");
      return;
    }
    const newChoices = [...options, option];
    setOptions(newChoices);
    if (selectedLanguage === "first") {
      props.updateQuestion({ l1_options: newChoices });
    } else {
      props.updateQuestion({ l2_options: newChoices });
    }
    setOption("");
  };
  const handleChoiceChange = (e) => {
    setOption(e.target.value);
  };

  let changeRequired = (value) => {
    setRequired(value);
    props.updateQuestion({ required: value });
  };

  useEffect(() => {
    if (selectedLanguage === "first") {
      setOptions(props.question.l1_options);
    } else {
      setOptions(props.question.l2_options);
    }
  }, [selectedLanguage]);

  let deleteChoice = (index) => {
    const newOptions = options.filter((v, i) => i != index);
    setOptions(newOptions);
    if (selectedLanguage === "first")
      props.updateQuestion({ l1_options: newOptions });
    else props.updateQuestion({ l2_options: newOptions });
  };

  const handleSave = () => {
    if (options.length < 2) {
      toast.error("Must have atleast 2 choices!");
    } else {
      props.updateQuestion(
        {
          required: Required,
          [selectedLanguage === "first" ? "l1_options" : "l2_options"]: options,
          logic,
        },
        "save"
      );
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: "auto",
      }}
    >
      <QuestionPreviewModal
        type="multiple choice"
        logo={props.logo}
        open={OpenPreviewModal}
        template={props?.template}
        close={() => setOpenPreviewModal(false)}
        options={options}
        title={
          selectedLanguage === "first"
            ? props.question.l1_title
            : props.question.l2_title
        }
        description={
          selectedLanguage === "first"
            ? props.question.l1_description
            : props.question.l2_description
        }
      />
      <div className="question-field-section">
        <Box className="savePreviewButtonWrapper">
          <Box
            sx={{ display: { xs: "none", sm: "block" }, width: "12%" }}
          ></Box>
          <Box>
            <Button
              size="small"
              variant="contained"
              className="save-button"
              sx={{
                fontWeight: "700",
                marginRight: "24px",
                background: "#CCC",
                color: "#000",
                borderRadius: "2.5px",
              }}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              variant="contained"
              size="small"
              className="preview-button"
              sx={{
                fontWeight: "700",
                background: "#FFC700",
                color: "#000",
                borderRadius: "2.5px",
              }}
              onClick={() => setOpenPreviewModal(true)}
            >
              Preview
            </Button>
          </Box>
          {props.language.length === 2 ? (
            <LanguageToggle
              language={props.language}
              setSelectedLanguage={setSelectedLanguage}
            />
          ) : (
            <Box
              sx={{ display: { xs: "none", sm: "block" }, width: "12%" }}
            ></Box>
          )}
        </Box>
        <QuestionField
          question={props.question}
          handleQuestionChange={props.handleQuestionChange}
          updateQuestion={props.updateQuestion}
          selectedLanguage={selectedLanguage}
        />
        <List>
          <div style={{ maxHeight: "140px", overflowY: "auto" }}>
            {options.map((option, i) => (
              <ListItem key={i} className="options">
                <ListItemText primary={option} />
                <Box className="option-icons">
                  <img
                    src={deleteIcon}
                    alt="delete"
                    width={"14px"}
                    onClick={() => deleteChoice(i)}
                  />
                </Box>
              </ListItem>
            ))}
          </div>
          <TextField
            placeholder="Add Selection Options"
            value={option}
            onChange={handleChoiceChange}
            size="small"
          />
          <MdOutlineAdd
            onClick={addChoice}
            size={24}
            style={{
              marginLeft: "14px",
              marginTop: "8px",
              color: "blue",
              cursor: "pointer",
            }}
          />
        </List>
      </div>

      <MultipleSelectOtptionCard
        id={props.questionId}
        isRequired={Required}
        required={(value) => changeRequired(value)}
        changeQuestionType={() => props.changeQuestionType()}
        addNextQuestion={() => props.addNextQuestion()}
        options={options}
        updateLogic={(value) => setLogic(value)}
        surveyType={props.surveyType}
        type="multiple select"
        totalQuestions={props.totalQuestions}
        questionLogic={props.question.logic}
      />
    </div>
  );
}
