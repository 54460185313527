import { Box, FormLabel, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import { fullStar, yellowStarIcon } from "../../Assets";
import Capitalise from "../Capitalise/Capitalise";

export default function RatingForm(props) {
  const [Value, setValue] = useState(props.initialValue || "");

  useEffect(() => {
    setValue(props.initialValue);
  }, [props.initialValue, props.errors]);

  let handleNext = (values, type) => {
    props.next(props.name, values, type);
    // setValue("");
    // values.Value = "";
    // props.errors.Value = ''
  };

  let responseClick = (condition) => {
    props.setyesorno(condition);
  };

  return (
    <Box
      width="100%"
      paddingBottom="20px"
      flexDirection={"column"}
      justifyContent="center"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{ height: "100%" }}
      >
        <FormLabel
          required={props.required}
          sx={{
            display: "flex",
            marginBottom: "8px",
            color: "#3F3D56",
            fontSize: !props.hideButton
              ? {
                  md: props.label.length > 80 ? "1rem" : "1.5rem",
                  xs: props.label.length > 80 ? "0.75" : "1.25rem",
                }
              : {
                  md: props.label.length > 80 ? "0.9rem" : "1.1rem",
                  xs: props.label.length > 80 ? "0.75" : "1.1rem",
                },
            fontWeight: !props.hideButton ? "750" : "600",
            maxWidth: "100%",
          }}
          className="details-label bold"
        >
          {Capitalise(props.label)}
        </FormLabel>
        {props.description && (
          <Typography style={{ color: "grey", fontSize: "18px" }}>
            {props.description}
          </Typography>
        )}
        <Box
          display={"flex"}
          gap={2}
          style={{
            maxWidth: "100%",
            overflowY: "auto",
          }}
        >
          <img
            src={Value >= "1" ? fullStar : yellowStarIcon}
            alt=""
            height={"30px"}
            onClick={
              !props.disabled
                ? () => {
                    setValue("1");
                    props.values.Value = "1";
                    responseClick(0);
                    setTimeout(() => handleNext(props.values, props.type), 300);
                  }
                : ""
            }
          />
          <img
            src={Value >= "2" ? fullStar : yellowStarIcon}
            alt=""
            height={"30px"}
            onClick={
              !props.disabled
                ? () => {
                    setValue("2");
                    props.values.Value = "2";
                    responseClick(1);
                    setTimeout(() => handleNext(props.values, props.type), 300);
                  }
                : ""
            }
          />
          <img
            src={Value >= "3" ? fullStar : yellowStarIcon}
            alt=""
            height={"30px"}
            onClick={
              !props.disabled
                ? () => {
                    setValue("3");
                    props.values.Value = "3";
                    responseClick(2);
                    setTimeout(() => handleNext(props.values, props.type), 300);
                  }
                : ""
            }
          />
          <img
            src={Value >= "4" ? fullStar : yellowStarIcon}
            alt=""
            height={"30px"}
            onClick={
              !props.disabled
                ? () => {
                    setValue("4");
                    props.values.Value = "4";
                    responseClick(3);
                    setTimeout(() => handleNext(props.values, props.type), 300);
                  }
                : ""
            }
          />
          <img
            src={Value >= "5" ? fullStar : yellowStarIcon}
            alt=""
            height={"30px"}
            onClick={
              !props.disabled
                ? () => {
                    setValue("5");
                    props.values.Value = "5";
                    responseClick(4);
                    setTimeout(() => handleNext(props.values, props.type), 300);
                  }
                : ""
            }
          />
        </Box>
      </motion.div>
    </Box>
  );
}
