import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
  Legend,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import {
  Stack,
  Box,
  Card,
  Grid,
  Typography,
  DialogContent,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  Switch,
  IconButton,
  Tooltip as MuiTooltip,
  Container,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Capitalise } from "../../../../Shared";
import "./ResultOverviewPage.css";
import ExportCSV from "../../../../Shared/ExportCSV/ExportCSV";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../../../../Shared/Modals/BootstrapDialogTitle";
import { toast } from "react-toastify";
import ResponseSidebar from "./ResponseSidebar";
import { isSubsetof } from "../../../../helpers/helpers";
import { defaultSmileyValues } from "../../../../Shared/QuestionOptionCards/SmileyOptionCard";
import { ArrowRight, Close, ShareOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  deleteResponsePublic,
  getPublicResponses,
  makeResponsePublic,
} from "../../../../Api";
import { supportedTypes } from "./colors";
import { colorScale, multipleOptionColor, COLORS } from "./colors";
import { npsChartColor } from "./colors";
import { parseResponse } from "./parsers";
const scale5 = ["very good", "good", "neutral", "bad", "very bad"];
const scale2 = ["positive", "negative"];
let availableFilters = [];

const npsMapData = {
  promoters: ["very good", "good", "positive"],
  demoters: ["very bad", "bad", "negative"],
  passive: ["neutral"],
};

function initCounter(options) {
  let counterInit = {};
  for (const key of [...scale5, ...scale2, ...options]) {
    counterInit[key] = 0;
  }
  return counterInit;
}
export default function ResultOverviewPage({
  tableBodyData,
  responses,
  questions,
  branches,
  SelectedBranch,
}) {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [center, setCenter] = useState({ lat: 20, lng: 76 });
  const [filteredResponse, setFilteredResponse] = useState(tableBodyData);
  const [barCharts, setBarCharts] = useState([]);
  const [npsCharts, setNpsCharts] = useState([]);
  const [publicResponses, setPublicResponses] = useState(null); //graph embed
  const [embedGraphData, setEmbedGraphData] = useState(null); //graph embed

  const isEmbed = window.location.href.includes("/embed/");
  useEffect(() => {
    if (!publicResponses && !isEmbed) {
      getPublicResponses()
        .then((res) => {
          setPublicResponses(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  const navigate = useNavigate();
  function getBranchName(branchId) {
    if (branchId === null) return "Owner";
    let branch = branches.find((b) => b._id === branchId);
    if (branch) {
      return branch.code ? branch.code : branch.name;
    }
    return false;
  }
  function getResponses(questionId, branchId) {
    let tempRes = filteredResponse.map(({ branch, data }) => {
      if (branch === undefined || branch === branchId) {
        const res = data.find((r) => r.questionId === questionId);
        if (res) {
          return res.answer[0];
        }
      }
    });
    return tempRes.filter((res) => res !== undefined && res !== "");
  }
  function getCount(questionId, branchId) {
    const branchResponses = getResponses(questionId, branchId);
    const q = questions.find((q) => q._id === questionId);
    let counter = initCounter(q.l1_options || q.l2_options);
    for (const response of branchResponses) {
      let key;
      if (q.type !== "multiple select") {
        key = parseResponse(response, q.type, q.l1_options || q.l2_options);
        counter[key] = counter[key] + 1;
      } else {
        response.map((res) => {
          key = parseResponse(res, q.type, q.l1_options || q.l2_options);
          return (counter[key] = counter[key] + 1);
        });
      }
    }
    let scale;
    if (["rating", "smiley", "opinion scale"].includes(q.type)) {
      scale = scale5;
    } else if (["multiple choice", "multiple select"].includes(q.type)) {
      scale = q.l1_options || q.l2_options;
    } else {
      scale = scale2;
    }
    let filteredCounter = {};
    for (const key of scale) {
      filteredCounter[key] = counter[key];
    }
    return filteredCounter;
  }
  function getNpsCountFromBarCount(data) {
    let count = {
      promoters: 0,
      demoters: 0,
      passive: 0,
    };

    for (let branchData of data) {
      for (let key of Object.keys(branchData)) {
        if (npsMapData.promoters.includes(key)) {
          count.promoters += branchData[key];
        } else if (npsMapData.demoters.includes(key)) {
          count.demoters += branchData[key];
        } else if (npsMapData.passive.includes(key)) {
          count.passive += branchData[key];
        }
      }
    }
    let convertedData = [];
    for (let key of ["promoters", "demoters", "passive"]) {
      convertedData.push({
        name: key,
        value: count[key],
      });
    }
    return convertedData;
  }
  function getTextWidth(text, font) {
    // re-use canvas object for better performance
    var canvas =
      getTextWidth.canvas ||
      (getTextWidth.canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;
    return context.measureText(text).width;
  }
  useEffect(() => {
    let filteredBranches = branches;
    if (SelectedBranch !== "all") {
      filteredBranches = branches.filter((b) => b._id === SelectedBranch);
    } else {
      // add a branch with _id = null to include results taken without any branch
      // if there is no response in owner branch then no need
      if (responses.find((r) => r.branch === null)) {
        if (!filteredBranches.find((b) => b._id === null)) {
          filteredBranches.push({
            _id: null,
          });
        }
      }
    }
    setBarCharts([]);
    setNpsCharts([]);
    for (const q of questions) {
      if (supportedTypes.includes(q.type)) {
        let barChart = {
          title: q.l1_title || q.l2_title,
          type: q.type,
          questionId: q._id,
          smileyLabels: q.smileyLabels,
          data: filteredBranches.map((b) => ({
            branch: getBranchName(b._id),
            ...getCount(q._id, b._id),
          })),
        };
        if (SelectedBranch !== "all") {
          let pieChart = [];
          const branchData = barChart.data[0];
          for (let key of Object.keys(branchData)) {
            if ([...scale2, ...scale5].includes(key)) {
              pieChart.push({
                name: key,
                value: branchData[key],
              });
            }
          }

          barChart.pieData = pieChart;
        }
        setBarCharts((prev) => [...prev, barChart]);

        if (q.type !== "multiple choice" && q.type !== "multiple select") {
          let npsChart = {
            title: q.l1_title || q.l2_title,
            questionId: q._id,
            data: getNpsCountFromBarCount(barChart.data),
          };
          setNpsCharts((prev) => [...prev, npsChart]);
        }
      }
    }
    // setFilteredResponse(tableBodyData);
    // setSelectedFilters([]);
  }, [
    tableBodyData,
    filteredResponse,
    selectedFilters,
    SelectedBranch,
    responses,
    questions,
  ]);

  useEffect(() => {
    let filteredResponses = tableBodyData;
    for (const { type, answer } of selectedFilters) {
      let tempFilter = [];
      filteredResponses?.map((response) => {
        response.data?.map((item) => {
          if (item.type === type) {
            if (
              item.answer[0] === answer ||
              (Array.isArray(item.answer[0]) &&
                isSubsetof(answer, item.answer[0]))
            ) {
              if (!tempFilter.includes(response)) {
                tempFilter.push(response);
              }
            }
          }
        });
      });
      filteredResponses = tempFilter;
    }
    setFilteredResponse(filteredResponses);
  }, [selectedFilters, tableBodyData]);

  const [enabledCharts, setEnabledCharts] = useState(["bar", "pie"]);

  const handleCheckChange = (enabled, type) => {
    if (enabled) {
      if (!enabledCharts.includes(type)) {
        setEnabledCharts([...enabledCharts, type]);
      }
    } else {
      if (enabledCharts.includes(type)) {
        setEnabledCharts(enabledCharts.filter((c) => c !== type));
      }
    }
  };

  useEffect(() => {
    setFilteredResponse(tableBodyData);
    setSelectedFilters([]);
  }, [tableBodyData]);

  const getAvailableFilters = () => {
    availableFilters = [];
    questions.filter((q) => {
      if (q.quantitative_question) availableFilters.push({ type: q.type });
      else if (q.type === "multiple select" || q.type === "multiple choice") {
        let options =
          questions.filter((item) => item._id === q._id)[0].l1_options ||
          questions.filter((item) => item._id === q._id)[0].l2_options;
        availableFilters.push({
          type: q.type,
          options: options,
          questionId: q._id,
        });
      }
      return availableFilters;
    });
    return availableFilters;
  };

  const width = getTextWidth(
    responses.length.toString() + "A",
    "bold 16px Roboto"
  );

  if (!questions || !responses) {
    return null;
  }
  return (
    <Box className="resultoverviewpageWrapper">
      <ResponseSidebar
        responses={responses}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        availableFilters={getAvailableFilters()}
        filteredResponse={filteredResponse}
        setfilteredResponse={setFilteredResponse}
        choices={
          questions.filter((q) => q.type === "multiple choice")[0]
            ?.l1_options ||
          questions.filter((q) => q.type === "multiple choice")[0]?.l2_options
        }
      />
      <Grid container spacing={4} className="gridContainerWrapper">
        <Grid item xs={12} className="npsGridItemWrapper">
          <Card className="cardWrapper">
            <Typography
              variant="h6"
              fontWeight={"700"}
              style={{ color: "#757575" }}
            >
              NPS Overview
            </Typography>
            {npsCharts.map((pieChart, i) => (
              <Box
                marginTop="12px"
                key={i}
                sx={{
                  position: "relative",
                }}
              >
                <Stack direction={"row"} gap={1}>
                  <Typography sx={{ color: "grey", fontWeight: "600" }}>{`${
                    i + 1
                  })`}</Typography>
                  <Typography>{Capitalise(pieChart.title)}</Typography>
                </Stack>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    padding: "0",
                  }}
                >
                  <NpsChart pieChart={pieChart} />
                </Box>
                <Box width="100%" display="flex" justifyContent={"end"}>
                  <Typography fontWeight={"900"}>
                    NPS Score =
                    {Math.round(
                      ((pieChart.data[0].value - pieChart.data[1].value) *
                        100) /
                        (pieChart.data[0].value +
                          pieChart.data[2].value +
                          pieChart.data[1].value)
                    )}
                    %
                  </Typography>
                </Box>
                <Stack
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                  }}
                  direction={"row"}
                >
                  <ExportCSV
                    filename={`QuickFeedback_NPS_${pieChart.title}.csv`}
                    headers={["Question", "promoters", "demoters", "passive"]}
                    responses={[
                      [
                        pieChart.title,
                        pieChart.data[0].value.toString(),
                        pieChart.data[1].value.toString(),
                        pieChart.data[2].value.toString(),
                      ],
                    ]}
                  />
                  {isEmbed ? (
                    <div></div>
                  ) : (
                    <ShareOutlined
                      sx={{
                        height: "28px",
                        width: "28px",
                        cursor: "pointer",
                        marginLeft: "8px",
                      }}
                      onClick={() => {
                        setEmbedGraphData({
                          questionId: pieChart.questionId,
                          chartData: pieChart,
                          chartType: "nps",
                          question: questions.find(
                            (q) => q._id === pieChart.questionId
                          ),
                        });
                      }}
                    />
                  )}
                </Stack>
              </Box>
            ))}
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card className="cardWrapper">
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="h6"
                fontWeight={"700"}
                style={{ color: "#757575" }}
              >
                Question-wise Overview
              </Typography>
              {SelectedBranch !== "all" && false ? (
                <Box>
                  <FormControlLabel
                    disabled={
                      enabledCharts.length === 1 &&
                      enabledCharts.includes("bar")
                    }
                    checked={enabledCharts.includes("bar")}
                    onChange={(e, v) => handleCheckChange(v, "bar")}
                    control={<Checkbox />}
                    label="Bar Charts"
                  />
                  <FormControlLabel
                    disabled={
                      enabledCharts.length === 1 &&
                      enabledCharts.includes("pie")
                    }
                    checked={enabledCharts.includes("pie")}
                    control={<Checkbox />}
                    label="Pie Charts"
                    onChange={(e, v) => handleCheckChange(v, "pie")}
                  />
                </Box>
              ) : null}
            </Stack>
            {barCharts.map((barChart, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    position: "relative",
                    marginBottom: "20px",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      down: "10px",
                      right: "10px",
                    }}
                  >
                    <ExportCSV
                      filename={`QuickFeedback_bar_${barChart.title}`}
                      {...generateBarExportData(barChart)}
                    />
                    {isEmbed ? (
                      <div></div>
                    ) : (
                      <ShareOutlined
                        sx={{
                          cursor: "pointer",
                          marginLeft: "8px",
                        }}
                        onClick={() => {
                          setEmbedGraphData({
                            questionId: barChart.questionId,
                            chartData: barChart,
                            chartType: "bar",
                            question: questions.find(
                              (q) => q._id === barChart.questionId
                            ),
                          });
                        }}
                      />
                    )}
                  </Box>
                  <Stack direction={"row"} gap={1}>
                    <Typography sx={{ color: "grey", fontWeight: "600" }}>{`${
                      i + 1
                    })`}</Typography>
                    <Typography>{Capitalise(barChart.title)}</Typography>
                  </Stack>
                  <BarGraph
                    SelectedBranch={SelectedBranch}
                    enabledCharts={enabledCharts}
                    barChart={barChart}
                    yWidth={width}
                  />
                </Box>
              );
            })}
          </Card>
        </Grid>
      </Grid>
      <GraphEmbedDialog
        open={!!embedGraphData}
        close={() => setEmbedGraphData(null)}
        branchId={SelectedBranch}
        graphData={embedGraphData}
        publicResponses={publicResponses}
        setPublicResponses={setPublicResponses}
        yWidth={width}
      />
    </Box>
  );
}

export function BarGraph({ SelectedBranch, enabledCharts, barChart, yWidth }) {
  return (
    <Grid container>
      {SelectedBranch === "all" || enabledCharts.includes("bar") ? (
        <Grid
          item
          xs={enabledCharts.length === 1 || SelectedBranch === "all" ? 12 : 6}
        >
          <ResponsiveContainer height={260} width={"100%"}>
            <BarChart
              barSize={50}
              height={260}
              data={barChart.data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="branch" />
              <YAxis width={yWidth} />
              <Tooltip
                content={({ payload, active, label }) => {
                  if (
                    barChart.smileyLabels &&
                    barChart.smileyLabels.length === 5
                  ) {
                    payload.map(
                      (p) => (p.dataKey = p.name ? p.name : p.dataKey)
                    );
                  }
                  return (
                    <CustomTooltip
                      payload={payload}
                      active={active}
                      label={label}
                    />
                  );
                }}
                wrapperStyle={{
                  pointerEvents: "auto",
                  overflow: "auto",
                }}
              />
              <Legend
                layout="horizontal"
                wrapperStyle={{
                  maxHeight: "100px",
                  overflow: "auto",
                }}
              />
              {localStorage.role === "Account Owner" &&
              SelectedBranch === "all" ? (
                barChart.type === "smiley" ||
                barChart.type === "rating" ||
                barChart.type === "opinion scale" ? (
                  <>
                    <Bar
                      name={
                        barChart.smileyLabels
                          ? barChart.smileyLabels[4]
                          : "very good"
                      }
                      dataKey="very good"
                      stackId="a"
                      fill={
                        barChart.type === "smiley" ? "#4ECC56" : colorScale[0]
                      }
                    />
                    <Bar
                      name={
                        barChart.smileyLabels
                          ? barChart.smileyLabels[3]
                          : "good"
                      }
                      dataKey="good"
                      stackId="a"
                      fill={
                        barChart.type === "smiley" ? "#ACD91A" : colorScale[1]
                      }
                    />
                    <Bar
                      name={
                        barChart.smileyLabels
                          ? barChart.smileyLabels[2]
                          : "neutral"
                      }
                      dataKey="neutral"
                      stackId="a"
                      fill={
                        barChart.type === "smiley" ? "#FFCF2B" : colorScale[2]
                      }
                    />
                    <Bar
                      name={
                        barChart.smileyLabels ? barChart.smileyLabels[1] : "bad"
                      }
                      dataKey="bad"
                      stackId="a"
                      fill={
                        barChart.type === "smiley" ? "#FE7E1F" : colorScale[3]
                      }
                    />
                    <Bar
                      name={
                        barChart.smileyLabels
                          ? barChart.smileyLabels[0]
                          : "very bad"
                      }
                      dataKey="very bad"
                      stackId="a"
                      fill={
                        barChart.type === "smiley" ? "#E8354D" : colorScale[4]
                      }
                    />
                  </>
                ) : barChart.type === "multiple choice" ||
                  barChart.type === "multiple select" ? (
                  <>
                    {barChart.data[0] &&
                      Object.keys(barChart?.data[0])?.map(
                        (item, i) =>
                          item !== "branch" && (
                            <Bar
                              key={i}
                              id={`options-${i}`}
                              dataKey={item}
                              stackId="m0"
                              fill={
                                multipleOptionColor[
                                  i % multipleOptionColor.length
                                ]
                              }
                              className="mc-bar"
                            />
                          )
                      )}
                  </>
                ) : (
                  <>
                    <Bar dataKey="positive" stackId="a" fill={colorScale[0]} />
                    <Bar dataKey="negative" stackId="a" fill={colorScale[5]} />
                  </>
                )
              ) : barChart.type === "smiley" ||
                barChart.type === "rating" ||
                barChart.type === "opinion scale" ? (
                <>
                  <Bar
                    name={
                      barChart.smileyLabels
                        ? barChart.smileyLabels[4]
                        : "very good"
                    }
                    dataKey="very good"
                    stackId="a"
                    fill={
                      barChart.type === "smiley" ? "#4ECC56" : colorScale[0]
                    }
                  />
                  <Bar
                    name={
                      barChart.smileyLabels ? barChart.smileyLabels[3] : "good"
                    }
                    dataKey="good"
                    stackId="b"
                    fill={
                      barChart.type === "smiley" ? "#ACD91A" : colorScale[1]
                    }
                  />
                  <Bar
                    name={
                      barChart.smileyLabels
                        ? barChart.smileyLabels[2]
                        : "neutral"
                    }
                    dataKey="neutral"
                    stackId="c"
                    fill={
                      barChart.type === "smiley" ? "#FFCF2B" : colorScale[2]
                    }
                  />
                  <Bar
                    name={
                      barChart.smileyLabels ? barChart.smileyLabels[1] : "bad"
                    }
                    dataKey="bad"
                    stackId="d"
                    fill={
                      barChart.type === "smiley" ? "#FE7E1F" : colorScale[3]
                    }
                  />
                  <Bar
                    name={
                      barChart.smileyLabels
                        ? barChart.smileyLabels[0]
                        : "very bad"
                    }
                    dataKey="very bad"
                    stackId="e"
                    fill={
                      barChart.type === "smiley" ? "#E8354D" : colorScale[4]
                    }
                  />
                </>
              ) : barChart.type === "multiple choice" ||
                barChart.type === "multiple select" ? (
                <>
                  {Object.keys(barChart.data[0]).map(
                    (item, i) =>
                      item !== "branch" && (
                        <Bar
                          key={i}
                          dataKey={item}
                          stackId={i}
                          fill={
                            multipleOptionColor[i % multipleOptionColor.length]
                          }
                          className="mc-bar"
                        />
                      )
                  )}
                </>
              ) : (
                <>
                  <Bar dataKey="positive" stackId="b" fill={colorScale[0]} />
                  <Bar dataKey="negative" stackId="a" fill={colorScale[2]} />
                </>
              )}
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      ) : null}
      {SelectedBranch !== "all" &&
      enabledCharts.includes("pie") &&
      barChart.pieData !== undefined ? (
        <Grid item xs={enabledCharts.length === 1 ? 12 : 6}>
          <ResponsiveContainer height={260}>
            <PieChart>
              <Pie
                data={barChart.pieData}
                outerRadius={70}
                dataKey={"value"}
                cx="50%"
                cy="50%"
                nameKey="name"
                endAngle={90}
                startAngle={360 + 90}
              >
                {barChart.pieData.map((d, i) => (
                  <Cell
                    name={
                      barChart.smileyLabels
                        ? barChart.smileyLabels[4 - i]
                        : defaultSmileyValues[4 - i]
                    }
                    key={i}
                    fill={COLORS[d.name]}
                  />
                ))}
              </Pie>
              <Tooltip allowEscapeViewBox={{ x: true, y: true }} />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </Grid>
      ) : null}
    </Grid>
  );
}

/*
graphdata => {
  questionId: <questionId>,
  type: "nps" | "bar" | "pie",
  data: <GraphData>
}
*/

function findPublicResponse(questionId, branchId, publicResponses) {
  return publicResponses.find(
    (res) => res.questionId === questionId && res.branchId === branchId
  );
}
const GraphEmbedDialog = ({
  open,
  close,
  branchId,
  graphData,
  publicResponses,
  setPublicResponses,
  yWidth,
}) => {
  const [isPublic, setIsPublic] = useState(false);
  const [chartType, setChartType] = useState(graphData?.chartType || "bar");

  let enabledCharts = ["bar"];
  if (["pie", "bar"].includes(chartType)) {
    enabledCharts = [chartType];
  }

  useEffect(() => {
    if (!publicResponses || !graphData || !branchId) return;
    setIsPublic(
      !!findPublicResponse(graphData.questionId, branchId, publicResponses)
    );
  }, [publicResponses, branchId, graphData]);

  useEffect(() => {
    if (!graphData || !branchId) return;
    const existing = findPublicResponse(
      graphData.questionId,
      branchId,
      publicResponses
    );
    if (isPublic) {
      if (!existing) {
        makeResponsePublic(graphData.questionId, branchId)
          .then((res) => {
            setPublicResponses([...publicResponses, res.data]);
          })
          .catch((e) => {
            console.log(e);
            toast.error("Error occured while making response public");
            setIsPublic(false);
          });
      }
    } else {
      if (existing) {
        deleteResponsePublic(graphData.questionId, branchId)
          .then(() => {
            setPublicResponses(
              publicResponses.filter((res) => res._id !== existing._id)
            );
          })
          .catch((e) => {
            console.log(e);
            toast.error("Error occured while making response private");
            setIsPublic(true);
          });
      }
    }
  }, [isPublic]);

  useEffect(() => {
    if (!graphData) return;
    setChartType(graphData.chartType);
  }, [graphData]);

  if (!graphData) return null;
  const title = graphData.question.l1_title || graphData.question.l2_title;
  const IFrameUrl = `<iframe src="${
    window.location.origin
  }/#/public/embed/graph/${graphData.questionId}/${branchId}/type/${
    chartType || graphData.chartType
  }" width="100%" height="100%" frameborder="0"></iframe>`;
  return (
    <Dialog open={open} onClose={close} maxWidth={"lg"} fullWidth={true}>
      <DialogTitle sx={{ display: "flex" }}>
        <Typography variant="h6">Embed Graph</Typography>
        <MuiTooltip title="Enabling this will make the graph & responses public">
          <Switch
            checked={isPublic}
            onChange={(e) => setIsPublic(e.target.checked)}
            sx={{
              marginLeft: "12px",
            }}
          />
        </MuiTooltip>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>

      {isPublic && (
        <DialogContent>
          <Stack alignItems={"center"} gap={2}>
            <Stack direction={"row"} width={"100%"}>
              <ArrowRight />
              <Typography>{Capitalise(title)}</Typography>
            </Stack>
            {graphData.chartType === "nps" ? (
              <NpsChart pieChart={graphData.chartData} />
            ) : (
              <BarGraph
                SelectedBranch={branchId}
                enabledCharts={enabledCharts}
                barChart={graphData.chartData}
                yWidth={yWidth}
              />
            )}

            {branchId !== "all" && graphData.chartType !== "nps" && (
              <FormControl fullWidth>
                <FormLabel id="chart-type-radio">Select Chart Type</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="chart-type-radio"
                  value={chartType}
                  onChange={(e) => setChartType(e.target.value)}
                >
                  <FormControlLabel
                    value="bar"
                    control={<Radio />}
                    label="Bar"
                  />
                  <FormControlLabel
                    value="pie"
                    control={<Radio />}
                    label="Pie"
                  />
                </RadioGroup>
              </FormControl>
            )}

            <TextField
              fullWidth
              label="IFrame Embed Code"
              multiline
              aria-readonly
              value={IFrameUrl}
              onChange={() => {}}
              sx={{
                fontFamily: "monospace !important",
              }}
            />
          </Stack>
        </DialogContent>
      )}
    </Dialog>
  );
};
const generateBarExportData = ({ type, data }) => {
  let headers = ["Branch"];
  let keys = [];
  if (["rating", "smiley", "opinion scale"].includes(type)) {
    keys = scale5;
  } else {
    keys = scale2;
  }
  keys.forEach((key) => headers.push(key));

  const responses = data.map((branchData) => {
    let row = [branchData.branch];
    keys.forEach((key) => row.push(branchData[key]));
    return row;
  });
  return { headers, responses };
};

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Stack
        sx={{
          backgroundColor: "white",
          borderRadius: "8px",
          padding: "10px",
        }}
      >
        <Typography variant="h6">{label}</Typography>
        {payload.map(({ dataKey, value }, index) => (
          <Stack direction="row" justifyContent={"space-between"} key={index}>
            <Typography sx={{ padding: "4px" }}>{`${dataKey}:`}</Typography>
            <Typography sx={{ padding: "4px" }}>{value}</Typography>
          </Stack>
        ))}
      </Stack>
    );
  }

  return null;
};

function NpsChart({ pieChart }) {
  return (
    <PieChart width={140} height={140}>
      <Pie
        data={pieChart.data}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={55}
        fill="#82ca9d"
        endAngle={90}
        startAngle={360 + 90}
      >
        {pieChart.data.map((entry, index) => {
          return (
            <Cell
              key={`cell-${index}`}
              fill={npsChartColor[index % npsChartColor.length]}
            />
          );
        })}
      </Pie>
      <Tooltip />
    </PieChart>
  );
}

function CompareBranchDialog({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  return (
    <Box>
      <BootstrapDialog onClose={handleClose} open={open}>
        <BootstrapDialogTitle onClose={handleClose} />
        <DialogContent dividers>
          <Typography>helo </Typography>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}
