import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Slider,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { changeQuestionType } from "../../Assets";
import Logiccard from "./Logiccard";
let selectedQuestions = [];
export default function RatingOptionCard(props) {
  const [ShowGoogleReview, setShowGoogleReview] = useState(
    props?.survey?.enable_google_review || false
  );
  const [MinReviewRange, setMinReviewRange] = useState(
    props.survey?.min_review_range || 4
  );

  const marks = [
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];
  const [showOptions, setShowOptions] = useState(true);
  const [showLogic, setShowLogic] = useState(false);
  const handleSelectChange = (value, type, length) => {
    for (let i = 0; i < length; i++) {
      if (i === type) {
        selectedQuestions[type] = value;
      } else if (!selectedQuestions[i]) {
        selectedQuestions[i] = "empty";
      }
    }
    props.updateQuestion(selectedQuestions);
  };

  return (
    <Card className="optionCardWrapper">
      <CardActions
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box gap={3} display={{ xs: "block", sm: "flex" }}>
          <img
            src={changeQuestionType}
            alt="change type"
            style={{ cursor: "pointer" }}
            onClick={() => props.changeQuestionType()}
          />
          <Typography
            sx={{ marginRight: "24px" }}
            onClick={(e) => {
              setShowOptions(true);
              setShowLogic(false);
            }}
          >
            {" "}
            Options
          </Typography>
          <Typography
            onClick={(e) => {
              setShowLogic(true);
              setShowOptions(false);
            }}
          >
            {" "}
            Logic{" "}
          </Typography>
        </Box>
        <Box display={"flex"}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => props.required(e.target.checked)}
                checked={props.isRequired}
              />
            }
            label="Required"
          />
          <Button
            className="addNextQuestionButton"
            variant="contained"
            onClick={() => props.addNextQuestion()}
          >
            Add Next Question
          </Button>
        </Box>
      </CardActions>
      <CardContent
        style={{
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          overflowY: "auto",
          gap: "20px",
        }}
      >
        {showOptions && (
          <>
            <Box gap={2} display={"flex"} marginTop="30px">
              <FormGroup>
                <FormLabel>Google review</FormLabel>
                <FormControlLabel
                  control={
                    <Switch
                      checked={ShowGoogleReview}
                      disabled={localStorage.planId === "0"}
                      onChange={(e) => {
                        setShowGoogleReview(e.target.checked);
                        props.updateSurvey({
                          enable_google_review: e.target.checked,
                        });
                      }}
                    />
                  }
                />
              </FormGroup>
              <FormGroup
                style={{ display: ShowGoogleReview ? "flex" : "none" }}
              >
                <FormLabel>Google review range</FormLabel>
                <Slider
                  max={5}
                  min={3}
                  size="small"
                  marks={marks}
                  track="inverted"
                  // getAriaValueText={ valuetext}
                  defaultValue={MinReviewRange}
                  aria-labelledby="track-inverted-slider"
                />
              </FormGroup>
            </Box>
            <Box
              width="100%"
              display={{ xs: "block", sm: "flex" }}
              marginTop="12px"
              justifyContent={"space-between"}
            >
              <div>
                <TextField
                  size="small"
                  label="Paste your google review link"
                  value={props.survey?.google_review_link || ""}
                  onChange={(e) =>
                    props.updateSurvey({
                      enable_google_review: ShowGoogleReview,
                      min_review_range: MinReviewRange,
                      google_review_link: e.target.value,
                    })
                  }
                  style={{
                    width: "250px",
                    display: ShowGoogleReview ? "flex" : "none",
                  }}
                />
              </div>
            </Box>
          </>
        )}
        {showLogic && props.surveyType !== "single page" && (
          <Logiccard
            handleSelectChange={handleSelectChange}
            totalQuestions={props.totalQuestions}
            type={props.type}
            questionLogic={props.questionLogic}
          />
        )}
        <Typography className="optionCardQuestionId">
          Question ID:&nbsp;{props.id}
        </Typography>
      </CardContent>
    </Card>
  );
}
