import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import tick from "../../Assets/icon-tick.png";
import useQuery from "../Modals/helperFunctions/useQuery";

export default function ThankYouForm(props) {
  const [ShowLink, setShowLink] = useState(true);
  const query = useQuery();
  const kioskMode = query.get("autorestart");
  const isMerged = query.get("merged");
  console.log(kioskMode, isMerged);
  return (
    <Box
      width="100%"
      height={"100%"}
      display={"flex"}
      alignItems="center"
      flexDirection={"column"}
      justifyContent={"center"}
      marginBottom="24px"
      sx={{ overflowY: "auto" }}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "30px",
        }}
      >
        {props.image && (
          <img src={props?.image} alt="thank you" width="400px" />
        )}
        <img src={tick} alt="" style={{ width: "80px" }} />
        <Typography
          variant="h3"
          fontWeight={"600"}
          sx={{
            color: "#3F3D56",
            marginTop: "12px",
            fontWeight: "bolder",
            fontSize: { xs: "7vw", sm: "6vw", md: "5vw", lg: "4vw" },
          }}
          align="center"
        >
          {props.label || "Thank you"}
        </Typography>

        <Typography style={{ color: "grey", fontSize: "3vw" }}>
          {props?.description || "Thank you for submitting your feedback"}
        </Typography>

        {props?.showGoogleReview && props?.reviewLink && ShowLink && (
          <Box marginTop={"16px"}>
            <Typography variant="h6" style={{ color: "#000" }} align="center">
              Please take a minute to rate us in google
            </Typography>
            <Box
              display={"flex"}
              marginTop="24px"
              gap={2}
              justifyContent="center"
            >
              <Button
                variant="outlined"
                onClick={() => setShowLink(false)}
                style={{
                  textTransform: "none",
                  // borderColor: '#FFF',
                  color: "#000",
                  background: "#E8E8E8",
                }}
              >
                Skip
              </Button>
              <a
                href={props?.reviewLink}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  style={{
                    background: "#FFCC00",
                    color: "#000",
                    fontWeight: "600",
                    textTransform: "none",
                  }}
                >
                  continue
                </Button>
              </a>
            </Box>
          </Box>
        )}
        {kioskMode ? <SurveyRestartCounter /> : null}
      </motion.div>
    </Box>
  );
}

function SurveyRestartCounter() {
  const [remainingTime, setRemainingTime] = useState(5);
  const [intervalId, setIntervalId] = useState(null);
  const query = useQuery();

  useEffect(() => {
    setIntervalId(
      setInterval(() => setRemainingTime((prevValue) => prevValue - 1), 1000)
    );
  }, []);

  useEffect(() => {
    if (remainingTime <= 0) {
      clearTimeout(intervalId);
      if (query.get("merged") == "true") {
        const url = localStorage.getItem("restartUrl");
        window.location.assign(url);
        window.location.reload();
      } else {
        window.location.reload();
      }
    }
  }, [remainingTime, intervalId]);

  return (
    <Typography
      style={{
        color: "#757575",
        textAlign: "center",
        margin: "10px",
      }}
    >
      Restarting survey in {remainingTime}
    </Typography>
  );
}
