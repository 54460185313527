import { Navigate, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import ShareSurveyPage from "./Views/CreateSurvey/EditSurveyPage/ShareSurveyPage/ShareSurveyPage";
import BranchesSettingsPage from "./Views/CreateSurvey/SettingsPage/BranchesSettingsPage";
import UsersSettingsPage from "./Views/CreateSurvey/SettingsPage/UsersSettingsPage";
import BuildSurveyPage from "./Views/CreateSurvey/EditSurveyPage/BuildSurveyPage";
import ResetPassword from "./Views/CreateSurvey/EditSurveyPage/ResetPassword";
import { ResultsPage } from "./Views/CreateSurvey/EditSurveyPage/Responses/ResultsPage";
import SettingsPage from "./Views/CreateSurvey/SettingsPage/SettingsPage";
import SurveyPage from "./Views/CreateSurvey/EditSurveyPage/SurveyPage";
import HomePage from "./Views/CreateSurvey/SurveysPage/SurveysPage";
import VerifyPage from "./Views/CreateSurvey/VerifyPage/VerifyPage";
import SurveyId from "./Views/CreateSurvey/EditSurveyPage/SurveyId";
import OutputView from "./Views/TakeSurvey/OutputView/OutputView";
import ProtectedRoute from "./ProtectedRoute";
import "./App.css";
import { SandBoxedIntroduction } from "./Views/CreateSurvey/EditSurveyPage/Theme/DisplayIntroduction";
import Govtlogin from "./Views/Govtlogin";
import Admin from "./Admin/Admin";
import RegisterModal from "./Shared/Modals/RegisterModal";
import NpsGraphEmbed from "./Views/Embed/Graphs/NpsGraphEmbed";
import BarGraphEmbed from "./Views/Embed/Graphs/BarGraphEmbed";
import { OverviewEmbed } from "./Views/Embed/Survey/OverviewEmbed";

const theme = createTheme({
  typography: {
    fontFamily: `"Poppins", sans-serif`,
  },
  palette: {
    primary: {
      light: "#757ce8",
      main: "#3A63F3",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

function App() {
  const [Title, setTitle] = useState("Enter your title");
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    // Frame guard to prevent clickjacking
    if (window.location !== window.top.location) {
      window.top.location = window.location;
    }
  }, []);
  return (
    <>
      <ToastContainer position="top-center" />
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Govtlogin />} />
          <Route path="/register" element={<RegisterModal open={true} />} />
          <Route path="/:accountId/verify/:id" element={<VerifyPage />} />
          <Route
            path="/feedback/start/:branch/:id"
            element={<SandBoxedIntroduction />}
          />
          <Route path="/feedback/:branch/:id" element={<OutputView />} />
          {/* Todo: make respondentId optional */}
          <Route
            path="/feedback/:branch/:id/:respondentId"
            element={<OutputView />}
          />
          For kiosk
          <Route
            path="/feedback/:branch/:id/autorestart"
            element={<OutputView />}
          />
          <Route path="/admin" element={<Admin />} />
          {/* Todo: make respondentId optional */}
          <Route
            path="/feedback/:branch/:id/:respondentId/autorestart"
            element={<OutputView />}
          />
          <Route
            path="/resetpassword/:token/:userId"
            element={<ResetPassword />}
          />
          <Route
            path="*"
            element={
              <Navigate
                to={localStorage.getItem("userId") ? "/surveys" : "/"}
              />
            }
          />
          <Route element={<ProtectedRoute />}>
            <Route path="surveys" element={<HomePage />} />
            <Route path="surveys" element={<SurveyPage />}>
              <Route path=":id" element={<SurveyId />}>
                <Route path="build" element={<BuildSurveyPage />} />
                {/* <Route path="theme" element={<EditTheme />} /> */}
                <Route
                  path="share"
                  element={
                    <ShareSurveyPage Title={Title} setTitle={setTitle} />
                  }
                />
                <Route path="result" element={<ResultsPage />} />
              </Route>
            </Route>
            <Route path="settings" element={<SettingsPage />}>
              {/* <Route path="general" element={<GeneralSettingsPage />} />
              <Route path="sub-account" element={<SubAccountSettingsPage />} />
              <Route path="portal" element={<PortalSettingsPage />} />
              <Route path="contacts" element={<ContactsSettingsPage />} /> */}
              <Route path="branches" element={<BranchesSettingsPage />} />
              {/* <Route
                path="single-sign-on"
                element={<SingleSignOnSettingsPage />}
              />
              <Route
                path="account-access"
                element={<AccountAccessSettingsPage />}
              /> */}
              <Route path="users" element={<UsersSettingsPage />} />
              {/* <Route path="teams" element={<TeamsSettingsPage />} /> */}
              {/* <Route path="roles" element={<RolesSettingsPage />} /> */}
            </Route>
          </Route>
        </Routes>
      </ThemeProvider>
    </>
  );
}

export const PublicRoutes = () => {
  return (
    <Routes>
      {/* EMBED ROUTES */}
      <Route path="/embed">
        <Route path="graph/:questionId/:branchId">
          <Route path="type/nps" element={<NpsGraphEmbed />} />
          <Route path="type/:chartType" element={<BarGraphEmbed />} />
        </Route>
        <Route path="overview/:surveyId" element={<OverviewEmbed />} />
      </Route>
    </Routes>
  );
};
export default App;
