import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { changeQuestionType } from "../../Assets";
import Logiccard from "./Logiccard";
let selectedQuestions = [];
export default function OpinionScaleOptionCard(props) {
  const [showOptions, setShowOptions] = useState(true);
  const [showLogic, setShowLogic] = useState(false);
  const handleSelectChange = (value, type, length) => {
    for (let i = 0; i < length; i++) {
      if (i === type) {
        selectedQuestions[type] = value;
      } else if (!selectedQuestions[i]) {
        selectedQuestions[i] = "empty";
      }
    }
    props.updateQuestion(selectedQuestions);
  };

  return (
    <Card className="optionCardWrapper">
      <CardActions
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box gap={3} display={{ xs: "block", sm: "flex" }}>
          <img
            src={changeQuestionType}
            alt="change type"
            style={{ cursor: "pointer" }}
            onClick={() => props.changeQuestionType()}
          />
          <Typography
            sx={{ marginRight: "24px" }}
            onClick={(e) => {
              setShowOptions(true);
              setShowLogic(false);
            }}
          >
            {" "}
            Options
          </Typography>
          <Typography
            onClick={(e) => {
              setShowLogic(true);
              setShowOptions(false);
            }}
          >
            {" "}
            Logic{" "}
          </Typography>
        </Box>
        <Box display={"flex"}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => props.required(e.target.checked)}
                checked={props.isRequired}
              />
            }
            label="Required"
          />
          <Button
            className="addNextQuestionButton"
            variant="contained"
            onClick={() => props.addNextQuestion()}
          >
            Add Next Question
          </Button>
        </Box>
      </CardActions>
      <CardContent
        style={{
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          overflowY: "auto",
          gap: "20px",
        }}
      >
        {showOptions && <></>}
        {showLogic && props.surveyType !== "single page" && (
          <Logiccard
            handleSelectChange={handleSelectChange}
            totalQuestions={props.totalQuestions}
            type={props.type}
            opinionScaleArray={props.opinionScaleArray}
            questionLogic={props.questionLogic}
          />
        )}
        <Typography className="optionCardQuestionId">
          Question ID:&nbsp;{props.id}
        </Typography>
      </CardContent>
    </Card>
  );
}
