import React, { useState } from "react";
import { Box } from "@mui/material";
import "./Adminlogin.css";
import { adminLogin } from "../../Api";

function Adminlogin({ isLogin }) {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  function handleChange(evt) {
    const value = evt.target.value;
    setCredentials({
      ...credentials,
      [evt.target.name]: value,
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    adminLogin(credentials.username, credentials.password).then((res) => {
      if (res.status == 200) {
        localStorage.setItem("passCode", credentials.password);
        isLogin(true);
      }
    });
  }
  return (
    <Box>
      <form action="" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="">Username : </label>
          <input
            type="text"
            name="username"
            value={credentials.username}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="">Password : </label>
          <input
            type="password"
            name="password"
            value={credentials.password}
            onChange={handleChange}
          />
        </div>
        <input type="submit" />
      </form>
    </Box>
  );
}

export default Adminlogin;
