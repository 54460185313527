import { Button } from "@mui/material";
import React from "react";

function Custombutton({
  content,
  backgroundColor,
  color,
  variant,
  onCLick,
  type,
}) {
  return (
    <Button
      variant={variant}
      onClick={onCLick}
      type={type}
      sx={{
        padding: "10px",
        textTransform: "none",
        fontWeight: "800",
        fontSize: "1rem",
        boxShadow: "none",
        color: color,
        background: backgroundColor,
        borderRadius: "4px",
      }}
    >
      {content}
    </Button>
  );
}

export default Custombutton;
