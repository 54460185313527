import { CSVLink } from "react-csv";
import { BiExport } from "react-icons/bi";

const ExportCSV = ({ headers, responses, filename }) => {
  //  console.log(headers, responses);
  return (
    <CSVLink
      data={responses}
      headers={headers}
      filename={filename}
      style={{
        textDecoration: "none",
      }}
    >
      <BiExport color="#3A63F3" size={"30px"} />
    </CSVLink>
  );
};
export default ExportCSV;
