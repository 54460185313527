import { AddAPhoto } from "@mui/icons-material";
import {
  Box,
  Button,
  FormLabel,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  IconButton,
  Card,
} from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import Capitalise from "../Capitalise/Capitalise";
import UploadIcon from "@mui/icons-material/Upload";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { Stack } from "@mui/system";
import { addIcon } from "../../Assets";

export default function TestimonialForm(props) {
  const [OpenWebcam, setOpenWebcam] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [image, setImage] = useState(null);
  const [active, setActive] = useState("");

  useEffect(() => {
    setImage(null);
  }, [props.InitialValue]);

  let uploadImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file.size > 2000000) return alert("File size should be less than 2MB");
    reader.readAsDataURL(file);
    reader.addEventListener("load", () => {
      setImage(reader.result);
      props.setImage(reader.result);
    });
  };
  return (
    <Box
      width="100%"
      paddingBottom="20px"
      flexDirection={"column"}
      justifyContent="center"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{ height: "100%" }}
      >
        <FormLabel
          required={props.required}
          sx={{
            display: "flex",
            marginBottom: "8px",
            color: "#3F3D56",
            fontSize: !props.hideButton
              ? {
                  md: props.label.length > 80 ? "1rem" : "1.5rem",
                  xs: props.label.length > 80 ? "0.75" : "1.25rem",
                }
              : {
                  md: props.label.length > 80 ? "0.9rem" : "1.1rem",
                  xs: props.label.length > 80 ? "0.75" : "1.1rem",
                },
            fontWeight: !props.hideButton ? "750" : "600",
            maxWidth: "100%",
          }}
          className="details-label bold"
        >
          {Capitalise(props.label)}
        </FormLabel>
        {props.description && (
          <Typography style={{ color: "grey", fontSize: "18px" }}>
            {props.description}
          </Typography>
        )}
        <TextField
          multiline
          maxRows={4}
          size="small"
          name="Value"
          onBlur={props.handleBlur}
          value={props.values?.Value}
          onChange={props.handleChange}
          disabled={props.disabled}
          sx={{
            fontFamily: "sans-serif",
            border: "1px solid #3A63F3",
            marginTop: "24px",
            minHeight: { xs: "50px", md: !props.hideButton ? "60px" : "50px" },
            display: "flex",
            borderRadius: "8px",
            overflow: "auto",
            resize: "vertical",
          }}
          placeholder="Enter the answer"
          autoComplete="nope"
          className="survey-input"
        />
        <TextField
          placeholder="Enter your name"
          name="name"
          value={props.values?.name ? props.values.name : ""}
          disabled={props.disabled}
          onChange={props.handleChange}
          sx={{
            fontFamily: "sans-serif",
            border: "1px solid #3A63F3",
            marginTop: "24px",
            minHeight: { xs: "50px", md: !props.hideButton ? "60px" : "50px" },
            display: "flex",
            borderRadius: "8px",
            overflow: "auto",
            resize: "vertical",
          }}
          className="survey-input"
        />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="15vh"
        >
          <Button
            variant="outlined"
            sx={{
              fontFamily: "sans-serif",
              padding: "8px 14px",
              border: "1px solid #3A63F3",
              borderRadius: "5px",
              color: "#000",
            }}
            endIcon={<UploadIcon />}
            disabled={props.disabled}
            onClick={() => setOpenPopup(true)}
          >
            Image upload
          </Button>
        </Box>
        <div>
          <Dialog open={openPopup}>
            <DialogActions>
              <Stack direction="row" spacing={3}>
                <IconButton
                  aria-label="capture"
                  onClick={() => {
                    setOpenPopup(false);
                    setActive("capture");
                  }}
                >
                  <PhotoCameraIcon />
                </IconButton>
                <IconButton
                  aria-label="choose-file"
                  onClick={() => {
                    setOpenPopup(false);
                    setActive("choose");
                  }}
                >
                  <PhotoLibraryIcon />
                </IconButton>
              </Stack>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          {active === "capture" && (
            <Card
              title="capture"
              sx={{
                flex: "1 1 auto",
                marginTop: "32px",
                display: "flex",
                flexDirection: "column",
                background: "#FFF",
                border: "none",
                alignItems: "center",
                boxShadow: "none",
              }}
            >
              {!OpenWebcam && !image ? (
                <>
                  <Box
                    width="100px"
                    height="100px"
                    display="flex"
                    justifyContent={"center"}
                    alignItems="center"
                    sx={{
                      backgroundColor: "#A8A8A8",
                      borderRadius: "24px",
                    }}
                    onClick={() => setOpenWebcam(true)}
                  >
                    <AddAPhoto color="primary" size={24} />
                  </Box>
                </>
              ) : !OpenWebcam && image ? (
                <Box
                  position={"relative"}
                  display="flex"
                  flexDirection={"column"}
                  justifyContent="space-around"
                  style={{
                    width: "200px",
                    height: "200px",
                    overflowY: "auto",
                  }}
                >
                  <img src={image} alt="img" style={{ maxHeight: "150px" }} />
                  <Button
                    className="nextPreviousButton"
                    size="small"
                    disabled={props.disabled}
                    onClick={() => {
                      setImage(undefined);
                      setOpenWebcam(true);
                    }}
                  >
                    <span className="nextPreviousButtonText">Retake</span>
                  </Button>
                </Box>
              ) : OpenWebcam ? (
                <Box
                  width={"100%"}
                  display="flex"
                  flexDirection={"column"}
                  alignItems="center"
                  height="200px"
                  sx={{ overflowY: "scroll" }}
                >
                  <Webcam
                    mirrored
                    screenshotFormat="image/jpeg"
                    style={{ width: "200px", height: "150px" }}
                  >
                    {({ getScreenshot }) => (
                      <Button
                        className="medium"
                        onClick={() => {
                          let temp = getScreenshot();
                          setImage(temp);
                          setOpenWebcam(false);
                          props.setImage(temp);
                        }}
                      >
                        Capture photo
                      </Button>
                    )}
                  </Webcam>
                </Box>
              ) : (
                ""
              )}
            </Card>
          )}

          {active === "choose" && (
            <Card
              title="choose-file"
              sx={{
                flex: "1 1 auto",
                marginTop: "32px",
                display: "flex",
                flexDirection: "column",
                background: "#FFF",
                alignItems: "center",
                boxShadow: "none",
              }}
            >
              <Box
                style={{
                  background: "#E9EDF9",
                  border: "1px solid rgba(0, 0, 0, 0.15)",
                  borderRadius: "24px",
                  backgroundColor: "#A8A8A8",
                  width: "100px",
                  height: "100px",
                  marginLeft: "12px",
                  position: "relative",
                }}
              >
                <label
                  role="button"
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    className="form-control"
                    style={{
                      visibility: "hidden",
                      position: "absolute",
                    }}
                    onChange={(e) => uploadImage(e)}
                  />
                  {!image ? (
                    <img src={addIcon} alt="add" height={"13px"} width="13px" />
                  ) : (
                    <img
                      src={image}
                      alt="bgimage"
                      width={"100%"}
                      height="100%"
                    />
                  )}
                </label>
              </Box>
            </Card>
          )}
        </div>
      </motion.div>
    </Box>
  );
}
