import React from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CiWarning } from "react-icons/ci";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Typography,
  Stack,
  Checkbox,
  ListItemText,
  capitalize,
} from "@mui/material";

import { addSurvey } from "../../Api";
import "./Modal.css";
import HighlightOff from "@mui/icons-material/HighlightOff";

export default function CreateSurveyModal(props) {
  const navigate = useNavigate();

  let createSurvey = (values, isSubmitting) => {
    addSurvey(
      values.Title,
      values.Language,
      props?.selectedTemplate,
      values.Type
    )
      .then((res) => {
        navigate(`/surveys/${res.data._id}/build`);
      })
      .catch((err) => {
        let message = "";
        try {
          message = err.response.data.message;
        } catch (err) {}
        if (!message || message == "") {
          message = "An error occured. please try again";
        }
        toast.error(message);
        isSubmitting.setSubmitting(false);
      });
  };

  let validateForm = (values) => {
    const errors = {};
    if (!values.Title) errors.Title = "Enter a title for your survey";
    if (values.Language.length > 2)
      errors.Language = "Select any two languages only";
    return errors;
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.close()}
      aria-labelledby="create-survey-modal-title"
      aria-describedby="create-survey-modal-description"
    >
      {/* <Box sx={{ width: { xs: "81%", sm: "400px" } }} className="basic-modal"> */}
      <Stack
        className="basic-modal"
        sx={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "8px",
          width: { xs: "81%", sm: "400px" },
          borderColor: "transparent",
          outlineColor: "transparent",
        }}
      >
        <Box justifyContent={"end"} display="flex">
          <HighlightOff
            onClick={props.close}
            fontSize="large"
            sx={{ cursor: "pointer" }}
          />
        </Box>
        <h2 id="create-survey-modal-title" style={{ marginBottom: "16px" }}>
          Create Survey
        </h2>
        <Formik
          validate={validateForm}
          initialValues={{
            Title: "",
            Language: ["english"],
            Type: "multiple page",
          }}
          onSubmit={(values, isSubmitting) =>
            createSurvey(values, isSubmitting)
          }
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} id="create-survey-modal-description">
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                name="Title"
                variant="outlined"
                onBlur={handleBlur}
                label="Survey Title"
                value={values.Title}
                onChange={handleChange}
              />
              <div
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginBottom: "16px",
                }}
              >
                {errors.Title && touched.Title && errors.Title}
              </div>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.Language}
                  name="Language"
                  label="Language"
                  multiple
                  onChange={handleChange}
                  renderValue={(selected) => {
                    selected = selected.map(
                      (v) => v[0].toUpperCase() + v.slice(1)
                    );
                    return selected.join(", ");
                  }}
                  onBlur={handleBlur}
                >
                  <MenuItem value={"english"}>
                    <ListItemText primary={"English"} />
                    <Checkbox checked={values.Language.includes("english")} />
                  </MenuItem>
                  <MenuItem value={"malayalam"}>
                    <ListItemText primary={"Malayalam"} />
                    <Checkbox checked={values.Language.includes("malayalam")} />
                  </MenuItem>
                  <MenuItem value={"kannada"}>
                    <ListItemText primary={"Kannada"} />
                    <Checkbox checked={values.Language.includes("kannada")} />
                  </MenuItem>
                </Select>
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginBottom: "16px",
                  }}
                >
                  {errors.Language && touched.Language && errors.Language}
                </div>
              </FormControl>
              <FormControl>
                <FormLabel
                  id="survey-type"
                  style={{
                    fontSize: "0.8rem",
                  }}
                >
                  Survey type
                </FormLabel>
                <RadioGroup
                  aria-labelledby="survey-type"
                  name="Type"
                  value={values.Type}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="multiple page"
                    control={<Radio />}
                    label="Multiple page"
                    id="survey-type"
                  />
                  <FormControlLabel
                    value="single page"
                    control={<Radio />}
                    label="Single page"
                    id="survey-type"
                  />
                </RadioGroup>
              </FormControl>
              <Box
                display={"flex"}
                bgcolor={"rgba(256,225,0,0.4)"}
                padding="10px"
                marginTop={"12px"}
              >
                <CiWarning size={50} />
                <Typography style={{ marginLeft: "16px", fontSize: "14px" }}>
                  OTP type question and logic implementation are not available
                  in <strong>Single page survey</strong>
                </Typography>
              </Box>
              <Grid display="flex" justifyContent={"end"}>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ marginTop: "24px" }}
                  disabled={isSubmitting}
                >
                  Create
                </Button>
              </Grid>
            </form>
          )}
        </Formik>
      </Stack>
    </Modal>
  );
}
