import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchFeedbackQuestions, fetchSurveyById } from "../../../Api";
import { alreadyFilled } from "../../../helpers/helpers";
import DashboardView from "../DashboardView/DashboardView";
import SinglePageView from "../SinglePageView/SinglePageView";

let surveydetails, questions;
export default function OutputView() {
  const { branch, id } = useParams();
  const [Loading, setLoading] = useState(true);
  const [filled, setFilled] = useState(false);
  useEffect(() => {
    Promise.all([fetchSurveyById(id), fetchFeedbackQuestions(id)])
      .then((res) => {
        surveydetails = res[0].data;
        questions = res[1].data;
        setLoading(false);
        if (surveydetails.oneResponsePerDay) {
          setFilled(alreadyFilled(surveydetails._id, branch));
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occured");
      });
  }, [id]);

  if (filled) {
    return <Status text="You have already filled the survey. Thanks!" />;
  }

  return !Loading ? (
    surveydetails?.status === "active" &&
    surveydetails?.type !== "single page" ? (
      <DashboardView surveydetails={surveydetails} questions={questions} />
    ) : surveydetails?.status === "active" ? (
      <SinglePageView surveydetails={surveydetails} questions={questions} />
    ) : (
      <Status text="This survey is paused" />
    )
  ) : (
    <div></div>
  );
}

function Status({ text }) {
  return (
    <Box
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
      }}
    >
      <Typography variant="h4" fontWeight={"700"} style={{ color: "#A4A4A4" }}>
        {text}
      </Typography>
    </Box>
  );
}
