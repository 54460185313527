import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import kdisc from "../../../Assets/kdisc.gif";
import {
  addFeedbackResponse,
  getRespondentLocation,
  updateSurvey,
} from "../../../Api";
import LanguageForm from "../../../Shared/FormComponent/LanguageForm";
import { FormComponent } from "../../../Shared";
import { fynextlogo, gstdept } from "../../../Assets";
import "./DashboardView.css";
import FormPreview from "../../../Shared/FormComponent/FormPreview";
import Progressbar from "../../../Shared/Progressbar/Progressbar";
import { Formik } from "formik";
// import Loading from "./Loading/Loading";
import NextPrevious from "../../../Shared/FormComponent/NextPrevious/NextPrevous";
import Loading from "./Loading/Loading";
import { isArrayEqual, markFilledTime } from "../../../helpers/helpers";
import { InfoOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
let feedback = [],
  questions = [],
  surveydetails,
  logicMap = [],
  nextOrder = 1,
  responseIndex = "",
  respondentLocation;

export default function DashboardView(props) {
  const id = useParams().id;
  const respondentId = useParams()?.respondentId;

  const branch = useParams().branch;
  const [loading, setLoading] = useState(true);
  const [Language, setLanguage] = useState("first");
  const [IntialValue, setInitialValue] = useState("");
  const [showLanguageSelection, setShowLanguageSelection] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [history, setHistory] = useState([]);
  const [Image, setImage] = useState(null);
  const [logicCheck, setLogicCheck] = useState(false);
  const [multipleSelectRes, setMultipleSelectRes] = useState([]);
  const [openInfo, setOpenInfo] = useState(false);

  useEffect(() => {
    getRespondentLocation()
      .then((res) => (respondentLocation = res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const prevQId = history[history.length - 1];
    if (currentQuestion._id !== prevQId) {
      setHistory([...history, currentQuestion._id]);
    }
    const currentAnswer = feedback.find(
      (f) => f.questionId === currentQuestion._id
    );
    if (currentAnswer && currentQuestion.type === "phone number") {
      setInitialValue(currentAnswer.answer[0].slice(3));
    } else if (currentAnswer && currentQuestion.type === "multiple select") {
      setMultipleSelectRes(currentAnswer.answer[0]);
    } else if (currentAnswer) {
      setInitialValue(currentAnswer.answer[0]);
    } else {
      setInitialValue("");
    }
  }, [currentQuestion, history]);

  useEffect(() => {
    if (props.surveydetails && props.questions) {
      surveydetails = props.surveydetails;
      questions = props.questions;
      if (questions.filter((q) => q.logic.length > 0).length > 0) {
        setLogicCheck(true);
      }
      startSurvey();
      setTimeout(() => setLoading(false), 1700);
    } else {
      const dummyQuestion = {
        l1_title: props.title,
        l2_title: props.title,
        l1_description: props.description,
        l2_description: props.description,
        type: props.type,
        l1_options: props.options,
        l2_options: props.options,
        required: props.required,
        order: 0,
        smileyLabels: props.smileyLabels,
      };
      setCurrentQuestion(dummyQuestion);
      setLoading(false);
    }
  }, [id, props, loading]);
  let selectLanguage = (lang) => {
    setLanguage(lang);
    setCurrentQuestion(questions.filter((q) => q.order === 1)[0]);
    setShowLanguageSelection(false);
  };

  let getNextOrder = (type, _id) => {
    try {
      logicMap = currentQuestion.logic;
      let order;

      if (logicMap?.length >= 1) {
        if (type === "multiple select") {
          console.log(logicMap);
          console.log(feedback);
          const response = feedback.find((f) => f.questionId === _id).answer[0];
          const logic = logicMap.find((l) =>
            isArrayEqual(l.response, response)
          );
          if (logic) {
            if (logic.questionId === "endsurvey") {
              return "endsurvey";
            }
            return questions.find((q) => q._id === logic.questionId).order;
          }
          return currentQuestion.order + 1;
        }
        if (
          logicMap[responseIndex] !== "empty" ||
          logicMap[responseIndex] === "endsurvey"
        ) {
          if (logicMap[responseIndex] === "endsurvey") {
            order = "endsurvey";
          } else {
            order = questions.filter(
              (q) => q._id === logicMap[responseIndex]
            )[0].order;
          }
        } else {
          order = nextOrder + 1;
          return order;
        }
        if (
          type === "yes or no" ||
          type === "like or dislike" ||
          type === "rating" ||
          type === "opinion scale" ||
          type === "smiley" ||
          type === "multiple choice"
        ) {
          return order;
        }
      } else {
        return currentQuestion.order + 1;
      }
      responseIndex = null;
    } catch (err) {
      console.log(err);
      return nextOrder + 1;
    }
  };

  let next = (value, questionId, type, image) => {
    // TODO: remove questionId from parameters
    questionId = currentQuestion._id;
    let answer = {};
    answer.questionId = questionId;
    if (type === "testimonial") {
      answer.answer = [value.Value, Image, value.name];
    } else if (type === "photo capture") {
      answer.answer = [Image];
    } else if (type === "phone number") {
      answer.answer = ["+91" + value.Value];
    } else if (type == "multiple select") {
      answer.answer = [multipleSelectRes];
      setMultipleSelectRes([]);
    } else {
      answer.answer = [value.Value];
    }
    setImage(null);

    let answerIndex = feedback.findIndex(
      (feed) => feed.questionId === questionId
    );
    if (answerIndex === -1) {
      feedback.push(answer);
    } else {
      if (
        currentQuestion.logic.length !== 0 &&
        feedback[answerIndex].answer[0] !== answer.answer[0]
      ) {
        if (
          getNextOrder(currentQuestion.type, currentQuestion._id) !==
          "endsurvey"
        )
          feedback.splice(
            answerIndex,
            getNextOrder(currentQuestion.type, currentQuestion._id) -
              (answerIndex + 1),
            answer
          );
        else feedback.splice(answerIndex, feedback.length, answer);
      } else {
        feedback.splice(answerIndex, 1, answer);
      }
    }

    nextOrder = getNextOrder(type, questionId);
    const [nextQ] = questions.filter((q) => q.order === nextOrder);
    if (nextQ && nextQ.type !== "thank you") {
      setCurrentQuestion(nextQ);
    } else {
      showPreview();
    }
    value.Value = "";
  };

  let prev = (newCountValue) => {
    const prevQuestion = questions.find(
      (q) => q._id === history[history.length - 2]
    );
    setHistory(history.slice(0, -1));
    setCurrentQuestion(prevQuestion);
  };

  let startSurvey = () => {
    if (surveydetails.language.length === 2)
      // questions available in more than one language
      setShowLanguageSelection(true);

    setCurrentQuestion(questions.find((q) => q.order === 1));
  };

  let validateForm = (values) => {
    let errors = {};
    if (
      currentQuestion.required &&
      currentQuestion.type !== "photo capture" &&
      !values.Value
    ) {
      errors.Value = "This question is mandatory*";
      return errors;
    } else if (
      currentQuestion.required &&
      currentQuestion.type === "photo capture" &&
      !Image
    ) {
      errors.Value = "This question is mandatory*";
      return errors;
    } else if (
      currentQuestion.type === "email" &&
      values.Value &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Value)
    ) {
      errors.Value = "Invalid email address";
      return errors;
    } else if (
      currentQuestion.type === "phone number" &&
      values.Value &&
      (!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(
        values.Value
      ) ||
        values.Value.length !== 10)
    ) {
      errors.Value = "Enter a valid phone number";
      return errors;
    } else errors = {};
    return () => errors;
  };

  const showPreview = () => {
    setHistory([...history, null]);
    setCurrentQuestion({
      _id: null,
      type: "preview",
    });
  };
  const showThankyou = () => {
    setCurrentQuestion({
      _id: null,
      type: "thank you",
      l1_title: "Thank you",
      l2_title: "Thank you",
    });
  };
  console.log(currentQuestion);

  let completeSurvey = (isCompleted) => {
    if (isCompleted === "not completed") {
      updateSurvey(id, {
        responses: surveydetails.responses + 1,
        uncompleted_responses: surveydetails?.uncompleted_responses + 1,
      }).then((res) => {
        showThankyou();
      });
    } else {
      let branchToAdd = branch === "owner" ? null : branch;
      addFeedbackResponse(
        id,
        feedback,
        branchToAdd,
        respondentId,
        respondentLocation
      )
        .then(() => {
          markFilledTime(surveydetails._id, branchToAdd);
          showThankyou();
          const [nextQ] = questions.filter((q) => q.order === nextOrder);
          if (nextQ && nextQ.type === "thank you") {
            setCurrentQuestion(nextQ);
          } else {
            showThankyou();
          }

          // Save to localStorage that current survey has been filled.
          /*           if (!window.location.href.includes("/autorestart")) {
            saveFilledStatusToLocal(surveydetails._id, surveydetails.questions);
          } */
        })
        .catch((err) => {
          toast.error(`Could not record your survey. Please try again`);
          console.log(err);
        });
    }
  };
  if (loading) {
    return <Loading logo={surveydetails?.logo} />;
  } else {
    return (
      Object.keys(props).length !== 0 && (
        <Box
          className={
            surveydetails?.template === "template 1" ||
            props?.template === "template 1"
              ? "dashboard-template-1"
              : surveydetails?.template === "template 2" ||
                props?.template === "template 2"
              ? "dashboard-template-2"
              : surveydetails?.template === "template 4" ||
                props?.template === "template 4"
              ? "dashboard-template-4"
              : "dashboard-template-3"
          }
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            height: "calc(var(--vh, 1vh) * 100)",
          }}
        >
          <img
            src={kdisc}
            alt=""
            style={{
              position: "absolute",
              top: "5px",
              left: "5px",
              // width: "150px",
              height: "60px",
            }}
          />
          <img
            src={gstdept}
            alt="K-DISC"
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              // width: "110px"
              height: "60px",
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <InfoOutlined
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenInfo(true)}
            />
            <InfoDialogue open={openInfo} onClose={() => setOpenInfo(false)} />
            <Typography fontSize={10}>powered by</Typography>
            <img src={fynextlogo} style={{ width: "15px" }} />
          </div>
          <Box width="100%">
            {surveydetails?.logo && (
              <img
                src={surveydetails?.logo || props?.logo}
                alt="logo"
                height="auto"
                style={{
                  width: "20vw",
                  marginTop: "5px",
                  marginLeft: "5px",
                  maxWidth: "200px",
                }}
              />
            )}
          </Box>

          <Card
            sx={{
              width: { xs: "100%", lg: "80%" },
              flex: "1 1 auto",
              color: "#FFF",
              height: "100%",
              backgroundColor: "inherit",
              borderWidth: "0px",
              boxShadow: "none",
            }}
          >
            <CardContent
              sx={{
                height: "-webkit-fill-available",
                height: "100%",
              }}
            >
              <Box
                display={"flex"}
                flexDirection="column"
                alignItems="center"
                // padding={{ xs: "2px 2px 0px 2px", sm: "3px 3px 0px 3px" }}
                minHeight="390px"
                height="100%"
              >
                {!showLanguageSelection ? (
                  currentQuestion.type === "preview" ? (
                    <FormPreview
                      questions={questions}
                      responses={feedback}
                      language={Language}
                      handlePrev={prev}
                      handleSubmit={completeSurvey}
                    />
                  ) : (
                    <Box
                      sx={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        flexDirection: { md: "column", xs: "column-reverse" },
                        alignItems: "center",
                      }}
                    >
                      <div className="formComponentWrapper">
                        {currentQuestion.type !== "otp" &&
                        currentQuestion.type !== "thank you" ? (
                          <Formik
                            enableReinitialize
                            validate={validateForm}
                            onSubmit={(values) =>
                              next(values, null, currentQuestion.type, Image)
                            }
                            initialValues={{ Value: IntialValue }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                            }) => (
                              <form
                                onSubmit={handleSubmit}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  height: "100%",
                                  position: "relative",
                                }}
                              >
                                <FormGroup
                                  sx={{
                                    flex: "0.9",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    width: { xs: "100%", sm: "100%" },
                                  }}
                                >
                                  <FormComponent
                                    values={values}
                                    errors={errors}
                                    setyesorno={(cond) => {
                                      responseIndex = cond;
                                    }}
                                    //TODO: remove name
                                    name={null}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setImage={setImage}
                                    image={currentQuestion.image || ""}
                                    next={next}
                                    prev={prev}
                                    type={currentQuestion.type}
                                    count={currentQuestion.order}
                                    label={
                                      Language === "first"
                                        ? currentQuestion.l1_title
                                        : currentQuestion.l2_title
                                    }
                                    options={
                                      Language === "first"
                                        ? currentQuestion.l1_options
                                        : currentQuestion.l2_options
                                    }
                                    stop={showPreview}
                                    required={currentQuestion.required}
                                    start={startSurvey}
                                    description={
                                      Language === "first"
                                        ? currentQuestion.l1_description
                                        : currentQuestion.l2_description
                                    }
                                    smileyLabels={currentQuestion.smileyLabels}
                                    initialValue={IntialValue}
                                    multipleSelectRes={multipleSelectRes}
                                    setMultipleSelectRes={setMultipleSelectRes}
                                    reviewLink={
                                      props.surveydetails && props.questions
                                        ? surveydetails?.google_review_link
                                        : props?.reviewLink
                                    }
                                    showGoogleReview={
                                      props.surveydetails && props.questions
                                        ? surveydetails?.enable_google_review
                                        : props?.enableGoogleReview
                                    }
                                    totalQuestions={questions.length}
                                    disabled={
                                      props.surveydetails && props.questions
                                        ? false
                                        : true
                                    }
                                  />
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errors?.Value &&
                                      touched?.Value &&
                                      errors?.Value}
                                  </div>
                                </FormGroup>
                                {!props.hideButton && (
                                  <NextPrevious
                                    required={currentQuestion.required}
                                    prev={prev}
                                    disabled={
                                      props.surveydetails && props.questions
                                        ? false
                                        : true
                                    }
                                    totalQuestions={questions.length}
                                    count={currentQuestion.order}
                                  />
                                )}
                              </form>
                            )}
                          </Formik>
                        ) : (
                          <FormComponent
                            //TODO: remove name
                            name={null}
                            image={currentQuestion.image || ""}
                            next={next}
                            prev={prev}
                            type={currentQuestion.type}
                            count={currentQuestion.order}
                            label={
                              Language === "first"
                                ? currentQuestion.l1_title
                                : currentQuestion.l2_title
                            }
                            smileyLabels={currentQuestion.smileyLabels}
                            stop={showPreview}
                            required={currentQuestion.required}
                            start={startSurvey}
                            description={
                              Language === "first"
                                ? currentQuestion.l1_description
                                : currentQuestion.l2_description
                            }
                            initialValue={IntialValue}
                            totalQuestions={questions.length}
                            multipleSelectRes={multipleSelectRes}
                            setMultipleSelectRes={setMultipleSelectRes}
                            disabled={
                              props.surveydetails && props.questions
                                ? false
                                : true
                            }
                          />
                        )}
                      </div>
                      {currentQuestion.type !== undefined &&
                        currentQuestion.type !== "thank you" && (
                          <div className="formComponentProgressWrapper">
                            <Progressbar
                              present={
                                (currentQuestion.order * 100) / questions.length
                              }
                              startcaption={currentQuestion.order}
                              endcaption={questions.length}
                            />
                          </div>
                        )}
                    </Box>
                  )
                ) : (
                  <LanguageForm
                    availableLanguage={surveydetails?.language}
                    selectLanguage={(lang) => selectLanguage(lang)}
                  />
                )}
              </Box>
            </CardContent>
          </Card>
          {/* <div style={{ fontSize: '1.25em' }} className='mt-3'> */}
          {/* <Image src={govtLogo} className='logo' /> <Image src={akshayaLogo} className='logo' /> */}
          {/* </div> */}
        </Box>
      )
    );
  }
}

function saveFilledStatusToLocal(id, questionCount) {
  let surveys = [];
  try {
    surveys = JSON.parse(localStorage.getItem("surveys"));
    if (!Array.isArray(surveys)) {
      surveys = [];
    }
  } catch (err) {
    localStorage.clear("surveys");
  }
  surveys.push([id, questionCount]);
  localStorage.setItem("surveys", JSON.stringify(surveys));
}

const InfoDialogue = (props) => {
  return (
    <Dialog {...props}>
      <DialogTitle>
        Info{" "}
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>
          An initiative by Government of Kerala to survey the Citizen's
          Satisfaction level for various services.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
