import React, { useState } from "react";
import { Box } from "@mui/material";
import AdminNavbar from "../Adminnavbar/AdminNavbar";
import "./AdminDashboard.css";
import Request from "./Request/Request";
import Messages from "./Messages/Messages";
import Useronboard from "./Useronboard/Useronboard";
import Manageplans from "./Manageplans/Manageplans";
function AdminDashboard({ reload, setReload }) {
  const [selectedNav, setselectedNav] = useState("requests");
  return (
    <Box className="adminDashboardWrapper">
      <AdminNavbar
        reload={reload}
        setReload={setReload}
        selectedNav={selectedNav}
        setselectedNav={setselectedNav}
      />
      <Box className="adminDashboardComponentWrapper">
        {selectedNav === "requests" && (
          <Request reload={reload} setReload={setReload} />
        )}
      </Box>
    </Box>
  );
}
export default AdminDashboard;
