import { Box, FormLabel, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  dislikeIcon,
  filledDislikeIcon,
  filledLikeIcon,
  likeIcon,
} from "../../Assets";
import { motion } from "framer-motion";
import Capitalise from "../Capitalise/Capitalise";

export default function LikeOrDislikeForm(props) {
  const [Value, setValue] = useState(props.initialValue || "");

  useEffect(() => {
    setValue(props.initialValue);
  }, [props.initialValue, props.errors]);

  let handleNext = (values, type) => {
    props.next(props.name, values, type);
    // setValue("");
    // values.Value = "";
    // props.errors.Value = ''
  };

  let responseClick = (condition) => {
    props.setyesorno(condition);
  };

  return (
    <Box
      paddingBottom={"20px"}
      flexDirection={"column"}
      justifyContent="center"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{ height: "100%" }}
      >
        <FormLabel
          required={props.required}
          sx={{
            display: "flex",
            marginBottom: "8px",
            fontSize: !props.hideButton
              ? {
                  md: props.label.length > 80 ? "1rem" : "1.5rem",
                  xs: props.label.length > 80 ? "0.75" : "1.25rem",
                }
              : {
                  md: props.label.length > 80 ? "0.9rem" : "1.1rem",
                  xs: props.label.length > 80 ? "0.75" : "1.1rem",
                },
            fontWeight: !props.hideButton ? "750" : "600",
            maxWidth: "100%",
          }}
          className="details-label bold"
        >
          {Capitalise(props.label)}
        </FormLabel>
        {props.description && (
          <Typography style={{ color: "grey", fontSize: "18px" }}>
            {props.description}
          </Typography>
        )}
        <Box marginTop={"24px"} display={"flex"} gap={2}>
          <Box display={"flex"} flexDirection="column" alignItems={"start"}>
            <Box
              style={{
                width: !props.hideButton ? "102px" : "64px",
                height: !props.hideButton ? "90px" : "50px",
                background: "rgba(58, 99, 243, 0.05)",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: !props.disabled ? "pointer" : "inherit",
              }}
              onClick={
                !props.disabled
                  ? () => {
                      setValue("like");
                      responseClick(0);
                      props.values.Value = "like";
                      setTimeout(
                        () => handleNext(props.values, props.type),
                        300
                      );
                    }
                  : ""
              }
            >
              {Value === "like" ? (
                <img
                  src={filledLikeIcon}
                  alt="like-selected"
                  style={{
                    width: !props.hideButton ? "45px" : "28px",
                  }}
                />
              ) : (
                <img
                  src={likeIcon}
                  alt="like"
                  style={{
                    width: !props.hideButton ? "auto" : "24px",
                  }}
                />
              )}
            </Box>
            <Typography>{props.leftLabel}</Typography>
          </Box>
          <Box
            style={{
              width: !props.hideButton ? "102px" : "64px",
              height: !props.hideButton ? "90px" : "50px",
              background: "rgba(58, 99, 243, 0.05)",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: !props.disabled ? "pointer" : "inherit",
            }}
            onClick={
              !props.disabled
                ? () => {
                    setValue("dislike");
                    responseClick(1);
                    props.values.Value = "dislike";
                    setTimeout(() => handleNext(props.values, props.type), 300);
                  }
                : ""
            }
          >
            {Value === "dislike" ? (
              <img
                src={filledDislikeIcon}
                alt="dislike-selected"
                style={{ width: !props.hideButton ? "35px" : "24px" }}
              />
            ) : (
              <img
                src={dislikeIcon}
                alt="dislike"
                style={{
                  width: !props.hideButton ? "auto" : "24px",
                }}
              />
            )}
          </Box>
        </Box>
      </motion.div>
    </Box>
  );
}
