import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Capitalise } from "../../../Shared";

export default function MultipleChoice({ options, selected }) {
  const [Value, setValue] = useState("");

  return (
    <Grid container spacing={5} marginTop="26px">
      {options.map((option, idx) => (
        <Grid item xs={12} md={6} key={idx} padding="0">
          <Box
            className="yesorno"
            onClick={() => {
              setValue(option);
              selected(option);
            }}
            style={{
              width: "100%",
              border:
                Value === option ? "1px solid #3A63F3" : "1px solid #bebebe",
              backgroundColor: Value === option ? "#EFF4FF" : "#FFF",
            }}
          >
            <Typography
              className="singlePageSurveyOption"
              style={{
                color: Value === option ? "#3A63F3" : "#979797",
                margin: "auto",
              }}
            >
              {Capitalise(option)}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
