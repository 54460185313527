import React from "react";
import { Outlet } from "react-router-dom";
import "./SurveyPage.css";

function SurveyPage() {
  return (
    <div className="surveypageWrapper">
      <Outlet />
    </div>
  );
}

export default SurveyPage;
