import { useEffect, useState } from "react";
import { getSurveyPublicResponses } from "../../../Api";
import { useParams } from "react-router-dom";
import { ResultsPageWithoutData } from "../../CreateSurvey/EditSurveyPage/Responses/ResultsPage";
import { Box } from "@mui/material";

export function OverviewEmbed() {
  const [survey, setSurvey] = useState(undefined);
  const id = useParams().surveyId;
  const [responses, setResponses] = useState([]);
  const [branches, setBranches] = useState([]);
  const [fullResponses, setFullResponses] = useState([]);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    localStorage.setItem("role", "Account Owner");
    getSurveyPublicResponses(id).then(({ data }) => {
      let qs = data.questions;
      setSurvey(data.survey);
      qs.sort((a, b) => a.order - b.order);
      setQuestions(qs);
      let fetchedResponses = data.responses;
      // checking whether question is already deleted

      fetchedResponses.map((response) =>
        response.response.map((res) => {
          res.show =
            qs.filter((quest) => quest._id === res?.questionId).length !== 0
              ? true
              : false;
          res.type = qs?.filter(
            (quest) => quest._id === res?.questionId
          )[0]?.type;
          return res;
        })
      );
      setResponses(fetchedResponses);
      setFullResponses(fetchedResponses);
      setBranches(data.branches);
    });
  }, [id]);

  if (!survey || !questions || !branches || !fullResponses) return null;
  return (
    <Box
      bgcolor={"whitesmoke"}
      sx={{
        width: "100%",
      }}
    >
      <ResultsPageWithoutData
        survey={survey}
        responses={responses}
        branches={branches}
        fullResponses={fullResponses}
        questions={questions}
      />
    </Box>
  );
}
