import { Box, FormLabel, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { opinionScaleCircleIcon } from "../../Assets";
import Capitalise from "../Capitalise/Capitalise";

export default function OpinionScaleForm(props) {
  const [Value, setValue] = useState(props.initialValue || "");
  useEffect(() => {
    setValue(props.initialValue);
  }, [props.initialValue, props.errors]);

  let handleNext = (values, type) => {
    props.next(props.name, values, type);
    // setValue("");
    // values.Value = "";
    // props.errors.Value = ''
  };

  let responseClick = (condition) => {
    props.setyesorno(condition);
  };

  const opinionArray = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  return (
    <Box
      width="100%"
      paddingBottom="20px"
      flexDirection={"column"}
      justifyContent="center"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{ height: "100%" }}
      >
        <FormLabel
          required={props.required}
          sx={{
            display: "flex",
            marginBottom: "8px",
            color: "#3F3D56",
            fontSize: !props.hideButton
              ? {
                  md: props.label.length > 80 ? "1rem" : "1.5rem",
                  xs: props.label.length > 80 ? "0.75" : "1.25rem",
                }
              : {
                  md: props.label.length > 80 ? "0.9rem" : "1.1rem",
                  xs: props.label.length > 80 ? "0.75" : "1.1rem",
                },
            fontWeight: !props.hideButton ? "750" : "600",
            maxWidth: "100%",
          }}
          className="details-label bold"
        >
          {Capitalise(props.label)}
        </FormLabel>
        {props.description && (
          <Typography style={{ color: "grey", fontSize: "18px" }}>
            {props.description}
          </Typography>
        )}
        <Box
          display={"flex"}
          gap={"0.4vw"}
          marginTop="24px"
          color={"#000"}
          maxWidth="100%"
          sx={{ overflowY: "auto" }}
        >
          {opinionArray.map((op, i) => (
            <Box
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: Value === op ? "#3A63F3" : "#FFF",
                borderRadius: "50%",
              }}
              onClick={
                !props.disabled
                  ? () => {
                      setValue(op);
                      props.values.Value = op;
                      responseClick(parseInt(op) - 1);
                      setTimeout(
                        () => handleNext(props.values, props.type),
                        300
                      );
                    }
                  : ""
              }
              key={i}
            >
              <img src={opinionScaleCircleIcon} alt="normal" height="34px" />
              <Typography
                style={{
                  color: Value === op ? "white" : "black",
                  position: "absolute",
                  fontWeight: "600",
                  top: "7px",
                  left: "12px",
                  cursor: !props.disabled ? "pointer" : "inherit",
                }}
              >
                {op}
              </Typography>
            </Box>
          ))}
        </Box>
      </motion.div>
    </Box>
  );
}
