import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import OTPInput from "otp-input-react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import Capitalise from "../Capitalise/Capitalise";
import { sendOTP, verifyOTP } from "../../Api";
import { Stack } from "@mui/system";
import { flagofIndia } from "../../Assets";
import leftarrow from "../../Assets/leftarrow.png";
import rightarrow from "../../Assets/rightarrow.png";

export default function OtpForm(props) {
  const [Show, setShow] = useState(true);
  const [OTP, setOTP] = useState("");
  const [Phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("+91");

  useEffect(() => {
    setPhone("");
    setOTP("");
    setShow(true);
  }, []);

  let getOtp = (values) => {
    sendOTP(countryCode + values.Value)
      .then((res) => {
        toast.success(`otp sent successfully`);
        setPhone(countryCode + values.Value);
        setShow(false);
      })
      .catch((err) => {
        values.Value = "";
        toast.error(`Otp couldn't send, please try again`);
      });
  };

  let verifyOtp = (values) => {
    verifyOTP(OTP, Phone)
      .then((res) => {
        values.Value = countryCode + values.Value;
        props.next(props.name, values, props.type);
      })
      .catch((err) => {
        toast.error("Otp does not match, please try again");
      });
  };

  let handlePrev = () => {
    props.prev(props.count);
  };

  let validateForm = (values) => {
    let errors = {};
    if (!values.Value) {
      errors.Value = "This question is mandatory";
      return errors;
    } else if (
      values.Value &&
      (!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(
        values.Value
      ) ||
        values.Value.length > 10)
    ) {
      errors.Value = "Enter a valid phone number";
      return errors;
    } else errors = {};
    return () => errors;
  };

  return (
    <Box
      width="100%"
      display="flex"
      height={"100%"}
      paddingBottom="20px"
      flexDirection={"column"}
      justifyContent="center"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{ height: "100%" }}
      >
        {Show ? (
          <Formik
            enableReinitialize
            validate={validateForm}
            onSubmit={(values) => getOtp(values)}
            initialValues={{
              Value: props.initialValue?.replace(countryCode, ""),
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Box
                  width="100%"
                  height="100%"
                  display={"flex"}
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <FormGroup
                    sx={{
                      flex: "0.9",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: { xs: "100%", sm: "100%" },
                    }}
                  >
                    <FormLabel
                      required={props.required}
                      sx={{
                        display: "flex",
                        marginBottom: "8px",
                        color: "#3F3D56",
                        fontSize: {
                          md: props.label.length > 80 ? "1rem" : "1.5rem",
                          xs: props.label.length > 80 ? "0.75" : "1.25rem",
                        },
                        fontWeight: "750",
                        maxWidth: "100%",
                      }}
                      className="details-label bold"
                    >
                      {Capitalise(props.label)}
                    </FormLabel>
                    {props.description && (
                      <Typography style={{ color: "grey", fontSize: "18px" }}>
                        {props.description}
                      </Typography>
                    )}
                    <Stack direction="column" spacing={2}>
                      <Stack direction="row" spacing={1}>
                        <Select
                          disabled={props.disabled}
                          defaultValue="+91"
                          className="select-action"
                          size="small"
                          sx={{
                            display: "flex",
                            borderRadius: "8px",
                            border: "1px solid rgba(0,0,0,0.15)",
                            fontSize: "15px",
                            fontWeight: "120px",
                            height: { md: "60px", xs: "50px" },
                          }}
                          onChange={(e) => setCountryCode(e.target.value)}
                        >
                          <MenuItem value="+91">
                            <img src={flagofIndia} alt="INDIA" width={"25px"} />{" "}
                            &nbsp; IN
                          </MenuItem>
                        </Select>
                        <TextField
                          name="Value"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Value}
                          size="small"
                          style={{ width: "100%" }}
                          disabled={props.disabled}
                          sx={{
                            height: { xs: "50px", md: "60px" },
                            fontFamily: "sans-serif",
                            border: "2px solid rgba(0,0,0,0.15)",
                            borderRadius: "8px",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "24px",
                          }}
                          placeholder="Enter the phone number"
                          autoComplete="nope"
                          className="survey-input"
                          // InputProps={{
                          //   startAdornment: (
                          //     <InputAdornment position="start">+91</InputAdornment>
                          //   ),
                          // }}
                        />
                      </Stack>
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {" "}
                        {errors.Value && touched.Value && errors.Value}
                      </div>
                    </Stack>
                  </FormGroup>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  gap={3}
                  style={{
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                  }}
                >
                  {props?.count !== 1 && (
                    <Button
                      className="nextPreviousButton"
                      disabled={props.disabled}
                      onClick={handlePrev}
                    >
                      <img
                        src={leftarrow}
                        alt=""
                        className="nextPreviousButtonImage"
                      />
                      <span className="nextPreviousButtonText">Prev</span>
                    </Button>
                  )}
                  <Button
                    type="submit"
                    className="nextPreviousButton"
                    disabled={props.disabled}
                  >
                    <span className="nextPreviousButtonText">Get OTP</span>
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        ) : (
          <Formik
            enableReinitialize
            validate={validateForm}
            onSubmit={(values) => verifyOtp(values)}
            initialValues={{ Value: props.initialValue }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                  position: "relative",
                }}
              >
                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                  secure
                />

                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  {errors.Value && touched.Value && errors.Value}
                </div>

                {/* </Box> */}
                <Box
                  display="flex"
                  justifyContent="end"
                  gap={3}
                  style={{ position: "absolute", bottom: "0", right: "0" }}
                >
                  {
                    <Button
                      className="nextPreviousButton"
                      disabled={props.disabled}
                      onClick={() => setShow(true)}
                    >
                      <img
                        src={leftarrow}
                        alt=""
                        className="nextPreviousButtonImage"
                      />
                      <span className="nextPreviousButtonText">Prev</span>
                    </Button>
                  }
                  <Button
                    type="submit"
                    className="nextPreviousButton"
                    disabled={props.disabled}
                  >
                    <span className="nextPreviousButtonText">
                      {props.count === props.totalQuestions ? "Submit" : "Next"}
                    </span>
                    <img
                      src={rightarrow}
                      alt=""
                      className="nextPreviousButtonImage"
                    />
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        )}
      </motion.div>
    </Box>
  );
}
