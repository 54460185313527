import { Box, FormLabel, TextField, Typography } from "@mui/material";
import React from "react";
import Capitalise from "../Capitalise/Capitalise";
import { motion } from "framer-motion";

export default function EmailForm(props) {
  return (
    <Box
      paddingBottom={"20px"}
      flexDirection={"column"}
      justifyContent="center"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{ height: "100%" }}
      >
        <FormLabel
          required={props.required}
          sx={{
            display: "flex",
            marginBottom: "8px",
            color: "#3F3D56",
            fontSize: !props.hideButton
              ? {
                  md: props.label.length > 80 ? "1rem" : "1.5rem",
                  xs: props.label.length > 80 ? "0.75" : "1.25rem",
                }
              : {
                  md: props.label.length > 80 ? "0.9rem" : "1.1rem",
                  xs: props.label.length > 80 ? "0.75" : "1.1rem",
                },
            fontWeight: !props.hideButton ? "750" : "600",
            maxWidth: "100%",
          }}
          className="details-label bold"
        >
          {Capitalise(props.label)}
        </FormLabel>
        {props.description && (
          <Typography style={{ color: "grey", fontSize: "18px" }}>
            {props.description}
          </Typography>
        )}
        <TextField
          type={"email"}
          size="small"
          name="Value"
          disabled={props.disabled}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props?.values?.Value}
          sx={{
            fontFamily: "sans-serif",
            border: "1px solid #3A63F3",
            marginTop: "24px",
            minHeight: { xs: "50px", md: !props.hideButton ? "60px" : "50px" },
            display: "flex",
            justifyContent: "center",
            borderRadius: "8px",
          }}
          placeholder="Enter the email"
          autoComplete="nope"
          className="survey-input normal"
        />
      </motion.div>
    </Box>
  );
}
