import React, { Profiler } from "react";
import ReactDOM from "react-dom/client";
import App, { PublicRoutes } from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { HashRouter, Route, Routes } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <HashRouter>
    <Routes>
      <Route path="/public/*" element={<PublicRoutes />} />
      <Route path="/*" element={<App />} />
    </Routes>
  </HashRouter>
  // </React.StrictMode>
);
reportWebVitals();
