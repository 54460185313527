import { Switch, ToggleButton } from "@mui/material";
import React, { useEffect } from "react";
import Capitalise from "../Capitalise/Capitalise";

export default function LanguageToggle(props) {
  useEffect(() => {}, [props]);

  return (
    <ToggleButton
      value="left"
      aria-label="left aligned"
      style={{
        textTransform: "none",
        border: "none",
      }}
    >
      {Capitalise(props?.language[0])}
      <Switch
        size="small"
        onClick={(e) =>
          e.target.checked
            ? props.setSelectedLanguage("second")
            : props.setSelectedLanguage("first")
        }
      />
      {Capitalise(props?.language[1])}
    </ToggleButton>
  );
}
