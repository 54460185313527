import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React from "react";
import { changeQuestionType } from "../../Assets";

export default function OtpValidationOptionCard(props) {
  return (
    <Card className="optionCardWrapper">
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
        }}
      >
        <Box display={{ xs: "block", sm: "flex" }} gap={3}>
          <img
            src={changeQuestionType}
            alt="change type"
            style={{ cursor: "pointer" }}
            onClick={() => props.changeQuestionType()}
          />
          <Typography> Options</Typography>
          <Typography> Logic </Typography>
        </Box>
        <Box display={"flex"}>
          <FormControlLabel
            control={
              <Checkbox onChange={(e) => props.required(e.target.checked)} />
            }
            label="Required"
          />
          <Button
            className="addNextQuestionButton"
            variant="contained"
            onClick={() => props.addNextQuestion()}
          >
            Add Next Question
          </Button>
        </Box>
      </CardActions>
      <CardContent
        style={{
          display: "flex",
          color: "#AEB2BF",
          flex: "1 1 auto",
          alignItems: "end",
          justifyContent: "end",
        }}
      >
        <Typography className="optionCardQuestionId">
          Question ID:&nbsp;{props.id}
        </Typography>
      </CardContent>
    </Card>
  );
}
