import { toast } from "react-toastify";

function opinionParser(response) {
  try {
    let answer = Number(response);
    if (answer > 8) {
      return "very good";
    } else if (answer > 5) {
      return "good";
    } else if (answer == 5) {
      return "neutral";
    } else if (answer > 2) {
      return "bad";
    } else if (answer > 0) {
      return "very bad";
    } else {
      throw new Error("Invalid value");
    }
  } catch (e) {
    console.log(e);
    toast.error("Error occured while creating graphs. Please report to devs!");
    return "neutral";
  }
}
function multipleChoiceParser(response, options) {
  try {
    for (let opt of options) {
      if (response === opt) {
        return opt;
      }
    }
  } catch (e) {
    console.log(e);
    toast.error("Error occured while creating graphs. Please report to devs!");
    return "neutral";
  }
}
export function parseResponse(response, type, options) {
  if (type === "opinion scale") {
    return opinionParser(response);
  }
  if (type === "multiple choice" || type === "multiple select") {
    return multipleChoiceParser(response, options);
  }
  if (typeof response === "string") {
    response = response.toLowerCase();
  }
  if (typeof response === "number") {
    response = String(response);
  }
  if (["5", "very good", "very happy"].includes(response)) {
    return "very good";
  } else if (["4", "good", "happy"].includes(response)) {
    return "good";
  } else if (["3", "normal"].includes(response)) {
    return "neutral";
  } else if (["2", "sad"].includes(response)) {
    return "bad";
  } else if (["1", "very sad"].includes(response)) {
    return "very bad";
  } else if (["yes", "like"].includes(response)) {
    return "positive";
  } else if (["no", "dislike"].includes(response)) {
    return "negative";
  } else {
    throw new Error("Couldnt parse response");
    return undefined;
  }
}
