import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Adminlogin from "./Adminlogin/Adminlogin";
import "./Admin.css";
import AdminDashboard from "./AdminDashboard/AdminDashboard";

function Admin() {
  const [login, isLogin] = useState(false);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("passCode") !== null) {
      isLogin(true);
    } else {
      isLogin(false);
    }
  }, [reload]);
  return (
    <Box className="adminOverallWrapper">
      {login ? (
        <AdminDashboard reload={reload} setReload={setReload} />
      ) : (
        <Adminlogin isLogin={isLogin} />
      )}
    </Box>
  );
}

export default Admin;
