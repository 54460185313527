import React, { useContext } from "react";
import {
  Box,
  TextField,
  Typography,
  Select,
  Autocomplete,
  MenuItem,
  Grid,
  InputAdornment,
  ButtonGroup,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { fontList } from "../../../../Shared/Theme/fontList";
import WebFont from "webfontloader";
import ColorPicker from "../Testimonials/ColorPicker";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import { EditContext } from "./EditTheme";

function FontSelector() {
  const { handleChange } = useContext(EditContext);
  const onChange = (event, value) => {
    WebFont.load({
      google: {
        families: [value],
      },
      active: () => {
        handleChange({
          fontFamily: value,
        });
      },
    });
  };

  return (
    <Autocomplete
      disablePortal
      options={fontList}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Font" />}
      onChange={onChange}
    />
  );
}
const weights = [
  {
    name: "Black",
    value: 900,
  },
  {
    name: "Extra Bold",
    value: 800,
  },
  {
    name: "Bold",
    value: 700,
  },
  {
    name: "Semi Bold",
    value: 600,
  },
  {
    name: "Medium",
    value: 500,
  },
  {
    name: "Regular",
    value: 400,
  },
  {
    name: "Light",
    value: 300,
  },
  {
    name: "Extra Light",
    value: 200,
  },
  {
    name: "Thin",
    value: 100,
  },
];
function WeightSelector() {
  const { styles, selected, handleChange } = useContext(EditContext);

  const onChange = (e) => {
    handleChange({
      fontWeight: e.target.value,
    });
  };
  return (
    <Select
      onChange={onChange}
      value={styles[selected].fontWeight}
      sx={{ width: "100%" }}
    >
      {weights.map(({ value, name }) => (
        <MenuItem key={value} value={value}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
}

export function CustomNumberField({ propName, step, unit }) {
  const { styles, selected, handleChange } = useContext(EditContext);

  const onChange = (e) => {
    handleChange({
      [propName]: e.target.value + unit,
    });
  };
  return (
    <TextField
      InputProps={{
        endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
      }}
      onChange={onChange}
      value={styles[selected][propName].split(unit)[0]}
      type="number"
      inputProps={{ step }}
      sx={{
        height: "50px",
      }}
    />
  );
}

export function CustomizedColorPicker({ propName }) {
  const { styles, selected, handleChange } = useContext(EditContext);

  const onChange = (data) => {
    handleChange(data[selected]);
  };
  return (
    <ColorPicker
      itemName={selected}
      propName={propName}
      onChange={onChange}
      prevColor={styles[selected].color}
    />
  );
}

const positions = [
  {
    value: "left",
    icon: <FormatAlignLeftIcon />,
  },
  {
    value: "center",
    icon: <FormatAlignCenterIcon />,
  },
  {
    value: "right",
    icon: <FormatAlignRightIcon />,
  },
];

function AlignmentSelector() {
  const { styles, selected, handleChange } = useContext(EditContext);
  const onChange = (e, value) => {
    handleChange({
      textAlign: value,
    });
  };
  return (
    <ToggleButtonGroup
      value={styles[selected].textAlign}
      exclusive
      onChange={onChange}
    >
      {positions.map(({ value, icon }) => (
        <ToggleButton key={value} value={value}>
          {icon}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

function CustomizeText() {
  return (
    <Grid container sx={{ width: "100%" }} spacing={2}>
      <Grid item xs={12}>
        <FontSelector />
      </Grid>
      <Grid item xs={6}>
        <WeightSelector />
      </Grid>
      <Grid item xs={6}>
        <CustomNumberField propName="fontSize" step={0.1} unit="rem" />
      </Grid>
      <Grid item xs={6}>
        <CustomizedColorPicker propName="color" />
      </Grid>
      <Grid item xs={12}>
        <AlignmentSelector />
      </Grid>
    </Grid>
  );
}
export default CustomizeText;
