import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { MdSearch } from "react-icons/md";
import { BiExport } from "react-icons/bi";
import { toast } from "react-toastify";
import {
  dislikeIcon,
  happySmiley,
  likeIcon,
  normalSmiley,
  sadSmiley,
  veryHappySmiley,
  verySadSmiley,
} from "../../../../Assets";
import { RespondentProfileModal, Rating } from "../../../../Shared";
import ResponseSidebar from "./ResponseSidebar";
import "./ResultResponsePage.css";
import { isArrayEqual, isSubsetof } from "../../../../helpers/helpers";

let availableFilters = [];
export default function ResultResponsePage(props) {
  const { questions, responses } = props;
  const [OrderBy, setOrderBy] = useState(0);
  const [OrderDirection, setOrderDirection] = useState("asc");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredResponse, setFilteredResponse] = useState(responses);
  const [search, setSearch] = useState("");
  availableFilters = [];
  questions.filter((q) => {
    if (q.quantitative_question) availableFilters.push({ type: q.type });
    else if (q.type === "multiple select" || q.type === "multiple choice") {
      let options =
        questions.filter((item) => item._id === q._id)[0].l1_options ||
        questions.filter((item) => item._id === q._id)[0].l2_options;
      availableFilters.push({
        type: q.type,
        options: options,
        questionId: q._id,
      });
    }
    return availableFilters;
  });

  useEffect(() => {
    setFilteredResponse(responses);
    setSelectedFilters([]);
  }, [responses]);

  useEffect(() => {
    let filteredResponses = responses;
    //search filter
    if (search !== "") {
      filteredResponses = filteredResponses.filter(({ data }) => {
        try {
          for (const { answer } of data) {
            if (answer && answer.length > 0)
              if (answer[0].includes(search)) {
                return true;
              }
          }
          return false;
        } catch (error) {
          toast.error("Error while searching. Please report the bug.");
          console.log(error);
          return false;
        }
      });
    }
    //sidebar filters
    for (const { type, answer } of selectedFilters) {
      let tempFilter = [];
      filteredResponses?.map((response) => {
        response.data?.map((item) => {
          if (item.type === type) {
            if (
              item.answer[0] === answer ||
              (Array.isArray(item.answer[0]) &&
                isSubsetof(answer, item.answer[0]))
            ) {
              if (!tempFilter.includes(response)) {
                tempFilter.push(response);
              }
            }
          }
        });
      });
      filteredResponses = tempFilter;
    }
    setFilteredResponse(filteredResponses);
  }, [selectedFilters, search]);

  let sortBy = (e, colIndex) => {
    const isAscending = OrderBy === colIndex && OrderDirection === "asc";
    setOrderBy(colIndex);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  let sortedRowInformation = (rowArray, comparator) => {
    const stabilizedRowArray = rowArray?.map((el, i) => [el, i]);
    stabilizedRowArray?.sort((a, b) => {
      const order = comparator(a[0].data, b[0].data);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedRowArray?.map((el) => el[0]);
  };

  let getComparator = (order, orderby) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderby)
      : (a, b) => -descendingComparator(a, b, orderby);
  };

  let descendingComparator = (a, b, orderby) => {
    if (b[orderby]?.answer < a[orderby]?.answer) return -1;
    if (b[orderby]?.answer > a[orderby]?.answer) return 1;
    return 0;
  };
  const generateCsvData = () => {
    return filteredResponse.map(({ data, branch }) => {
      let branchName = props.branches.find((b) => b._id === branch);
      if (!branchName) {
        branchName = "";
      } else {
        branchName = branchName.name;
      }
      let tempRes = data.map(({ answer }) =>
        answer ? (answer[0] ? answer[0] : "") : ""
      );
      tempRes = [branchName, ...tempRes];
      return tempRes;
    });
  };
  const generateCsvHeaders = () => ["Branch", ...props.header];

  return (
    <Box className="resultresponsepage">
      <ResponseSidebar
        responses={responses}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        availableFilters={availableFilters}
        filteredResponse={filteredResponse}
        setfilteredResponse={setFilteredResponse}
        choices={
          questions.filter((q) => q.type === "multiple choice")[0]
            ?.l1_options ||
          questions.filter((q) => q.type === "multiple choice")[0]?.l2_options
        }
      />
      <Box className="resultresponsepageWrapper" id="resultresponsepagewrapper">
        {/* {selectedRespondent !== undefined && (
          <RespondentProfileModal
            open={ShowRespondentModal}
            close={() => setShowRespondentModal(false)}
            respondentId={selectedRespondent}
            selectedResponses={responses}
          />
        )} */}
        <div className="totalResponseOverviewWrapper">
          <Typography
            variant="h6"
            fontSize={"22px"}
            fontWeight="500"
            style={{ paddingLeft: "12px" }}
          >
            Total responses:&nbsp;
            {localStorage.role === "Account Owner"
              ? filteredResponse?.length
              : filteredResponse?.filter(
                  (res) => res?.location === localStorage?.location
                ).length}
          </Typography>
          <div style={{ display: "flex", gap: "6px" }}>
            {/* -----------------filter options------------------- */}
            <div style={{ position: "relative" }}>
              <TextField
                className="responseSearchbar"
                placeholder="Search responses"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <MdSearch
                style={{
                  color: "#3A63F3",
                  width: "18.33px",
                  height: "18.31px",
                  position: "absolute",
                  right: "10px",
                  top: "12px",
                }}
              />
            </div>
            <CSVLink
              data={generateCsvData()}
              headers={generateCsvHeaders()}
              filename={"Quickfeedback-response.csv"}
              style={{
                textDecoration: "none",
              }}
            >
              <div className="totalResponseButtonWrapper">
                <BiExport color="#3A63F3" size={"30px"} />
              </div>
            </CSVLink>
          </div>
        </div>

        <TableContainer className="resultresponsepageTableContainer">
          <Table
            sx={{ width: "100%", height: "auto" }}
            stickyHeader
            aria-label="sticky table"
            id="pdf"
            style={{ overflow: "scroll" }}
          >
            <TableHead>
              <TableRow>
                <TableCell className="responseTableHeaderCell responseTableHeaderFirstCell">
                  index
                </TableCell>
                {questions?.map(
                  (question, i) =>
                    question.type !== "thank you" && (
                      <TableCell
                        key={i}
                        className="responseTableHeaderCell"
                        sx={{
                          "&:last-child": {
                            borderRadius: "0px 9px 9px 0px",
                          },
                        }}
                      >
                        <TableSortLabel
                          active={OrderBy === i}
                          direction={OrderBy === i ? OrderDirection : "asc"}
                          onClick={(e) => sortBy(e, i)}
                        >
                          <Tooltip
                            title={question.l1_title || question.l2_title}
                          >
                            <Typography
                              sx={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                maxWidth: "300px",
                                fontSize: "1.125rem",
                                fontWeight: "600",
                                color: "#3F3D56",
                              }}
                            >
                              {question.l1_title || question.l2_title}
                            </Typography>
                          </Tooltip>
                        </TableSortLabel>
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody
              className="responseTableBodyWrapper"
              sx={{
                "& > :not(:last-child) td": {
                  borderBottom: "4px solid #eff0f4",
                },
              }}
            >
              {sortedRowInformation(
                filteredResponse,
                getComparator(OrderDirection, OrderBy)
              )
                ?.map((response, i) => (
                  <TableRow
                    className="responseTableBodyRowWrapper"
                    key={`row-${i}`}
                    sx={{
                      borderBottom: "thin solid #EDEDED",
                      borderRadius: "9px 9px 9px 9px",
                      backgroundColor: response.issueId
                        ? "rgba(236,209,107,0.7)"
                        : response.data.findIndex(
                            (res) =>
                              res.type === "rating" &&
                              (res.answer[0] === "3" ||
                                res.answer[0] === "2" ||
                                res.answer[0] === "1")
                          ) !== -1
                        ? "rgba(226,20,32,0.7)"
                        : "#FFF",
                    }}
                    // onClick={(e) => {
                    //   openRespondentProfileModal(response.respondent, e);
                    // }}
                  >
                    <TableCell
                      className="responseTableBodyCell"
                      sx={{
                        borderRadius: "9px 0px 0px 9px",
                      }}
                    >
                      {filteredResponse.length - i}
                    </TableCell>
                    {response.data?.map((res, i) => (
                      <TableCell
                        className="responseTableBodyCell"
                        key={`col-${i}`}
                        sx={{
                          "&:last-child": {
                            borderRadius: "0px 9px 9px 0px",
                          },
                        }}
                      >
                        {res.type === "rating" ? (
                          res.answer[0] === "1" ? (
                            <Rating height={"20px"} number={1} />
                          ) : res.answer[0] === "2" ? (
                            <Rating height={"20px"} number={2} />
                          ) : res.answer[0] === "3" ? (
                            <Rating height={"20px"} number={3} />
                          ) : res.answer[0] === "4" ? (
                            <Rating height={"20px"} number={4} />
                          ) : res.answer[0] === "5" ? (
                            <Rating height={"20px"} number={5} />
                          ) : (
                            ""
                          )
                        ) : res.type === "like or dislike" ? (
                          ["like", "Like"].includes(res.answer[0]) ? (
                            <img src={likeIcon} height="20px" alt="like" />
                          ) : ["dislike", "Dislike"].includes(res.answer[0]) ? (
                            <img
                              src={dislikeIcon}
                              height="20px"
                              alt="dislike"
                            />
                          ) : (
                            <span style={{ color: "#D4D4D4" }}>
                              Not answered
                            </span>
                          )
                        ) : res.type === "multiple select" ? (
                          res.answer[0].length > 0 ? (
                            res.answer[0].join(", ")
                          ) : (
                            <span style={{ color: "#D4D4D4" }}>
                              Not answered
                            </span>
                          )
                        ) : res.type === "yes or no" ? (
                          ["yes", "Yes"].includes(res.answer[0]) ? (
                            <span style={{ color: "#32a962" }}>yes</span>
                          ) : (
                            ["no", "No"].includes(res.answer[0]) && (
                              <span style={{ color: "#eb1313" }}>no</span>
                            )
                          )
                        ) : res.type === "smiley" ? (
                          res.answer[0] === "very happy" ? (
                            <img
                              src={veryHappySmiley}
                              alt="star"
                              height={"20px"}
                            />
                          ) : res.answer[0] === "happy" ? (
                            <img src={happySmiley} alt="star" height={"20px"} />
                          ) : res.answer[0] === "normal" ? (
                            <img
                              src={normalSmiley}
                              alt="star"
                              height={"20px"}
                            />
                          ) : res.answer[0] === "sad" ? (
                            <img src={sadSmiley} alt="star" height={"20px"} />
                          ) : res.answer[0] === "very sad" ? (
                            <img
                              src={verySadSmiley}
                              alt="star"
                              height={"20px"}
                            />
                          ) : (
                            <span style={{ color: "#D4D4D4" }}>
                              Not answered
                            </span>
                          )
                        ) : res.type === "testimonial" ? (
                          <>
                            {" "}
                            {res.answer[0]} <br />
                            {res.answer[1] && (
                              <img
                                src={res.answer[1]}
                                height={"50px"}
                                width={"50px"}
                                alt=""
                              />
                            )}
                            {res.answer[2] && (
                              <>
                                <br />
                                <b>Name: </b>
                                <span>{res.answer[2]}</span>
                              </>
                            )}
                          </>
                        ) : (
                          res.answer
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
                .reverse()}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
