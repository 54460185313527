import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import RatingOptionCard from "../QuestionOptionCards/RatingOptionCard";
import QuestionPreviewModal from "../Modals/QuestionPreviewModal";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import QuestionField from "../QuestionField/QuestionField";
import { yellowStarIcon } from "../../Assets";

export default function RatingQuestion(props) {
  const [OpenPreviewModal, setOpenPreviewModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("first");
  const [Required, setRequired] = useState(props.question?.required || false);

  const [logic, setLogic] = useState([]);

  let changeRequired = (value) => {
    setRequired(value);
    props.updateQuestion({ required: value });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: "auto",
      }}
    >
      <QuestionPreviewModal
        type="rating"
        logo={props.logo}
        template={props?.template}
        open={OpenPreviewModal}
        close={() => setOpenPreviewModal(false)}
        title={
          selectedLanguage === "first"
            ? props.question.l1_title
            : props.question.l2_title
        }
        description={
          selectedLanguage === "first"
            ? props.question.l1_description
            : props.question.l2_description
        }
      />
      <div className="question-field-section">
        <Box className="savePreviewButtonWrapper">
          <Box
            sx={{ display: { xs: "none", sm: "block" }, width: "12%" }}
          ></Box>
          <Box>
            <Button
              size="small"
              variant="contained"
              className="save-button"
              onClick={() =>
                props.updateQuestion(
                  {
                    required: Required,
                    logic: logic,
                  },
                  "save"
                )
              }
              sx={{
                fontWeight: "700",
                marginRight: "24px",
                background: "#CCC",
                color: "#000",
                borderRadius: "2.5px",
              }}
            >
              Save
            </Button>
            <Button
              size="small"
              variant="contained"
              className="preview-button"
              onClick={() => setOpenPreviewModal(true)}
              sx={{
                fontWeight: "700",
                background: "#FFC700",
                color: "#000",
                borderRadius: "2.5px",
              }}
            >
              Preview
            </Button>
          </Box>
          {props.language.length === 2 ? (
            <LanguageToggle
              language={props.language}
              setSelectedLanguage={setSelectedLanguage}
            />
          ) : (
            <Box
              sx={{ display: { xs: "none", sm: "block" }, width: "12%" }}
            ></Box>
          )}
        </Box>
        <QuestionField
          question={props.question}
          handleQuestionChange={props.handleQuestionChange}
          updateQuestion={props.updateQuestion}
          selectedLanguage={selectedLanguage}
        />
        <Box
          width={"100%"}
          justifyContent="center"
          display="flex"
          marginTop={"26px"}
        >
          <img src={yellowStarIcon} alt="" />
          <img src={yellowStarIcon} alt="" />
          <img src={yellowStarIcon} alt="" />
          <img src={yellowStarIcon} alt="" />
          <img src={yellowStarIcon} alt="" />
        </Box>
      </div>
      <RatingOptionCard
        id={props.questionId}
        survey={props.survey}
        surveyType={props.surveyType}
        isRequired={Required}
        updateSurvey={(value) => props.updateSurvey(value)}
        required={(value) => changeRequired(value)}
        changeQuestionType={() => props.changeQuestionType()}
        addNextQuestion={() => props.addNextQuestion()}
        updateQuestion={(value) => setLogic(value)}
        type="rating"
        totalQuestions={props.totalQuestions}
        questionLogic={props.question.logic}
      />
    </div>
  );
}
