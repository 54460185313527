import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { AddAPhoto } from "@mui/icons-material";
import Webcam from "react-webcam";
import { addIcon } from "../../../Assets";

let Value = [];
export default function Testimonial({ selected }) {
  const [OpenWebcam, setOpenWebcam] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [image, setImage] = useState(null);
  const [active, setActive] = useState("");
  const [Testimonial, setTestimonial] = useState("");
  const [Name, setName] = useState("");

  let uploadImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file.size > 2000000) return alert("File size should be less than 2MB");
    reader.readAsDataURL(file);
    reader.addEventListener("load", () => {
      setImage(reader.result);
      if (Testimonial === "") Value.splice(0, 0, reader.result);
      else Value.splice(1, 0, reader.result);
      selected(Value);
    });
  };

  return (
    <div>
      <TextField
        multiline
        maxRows={4}
        size="small"
        onChange={(e) => setTestimonial(e.target.value)}
        onBlur={() => {
          Value = [Testimonial, ...Value];
          selected(Value);
        }}
        sx={{
          fontFamily: "sans-serif",
          borderBottom: "2px solid #3A63F3",
          marginTop: "24px",
          display: "flex",
          overflow: "auto",
          resize: "vertical",
        }}
        placeholder="Enter the testimonial"
        autoComplete="nope"
        className="survey-input"
      />
      <TextField
        placeholder="Enter your name"
        onChange={(e) => setName(e.target.value)}
        onBlur={() => {
          Value.splice(Value.length, 0, Name);
          selected(Value);
        }}
        sx={{
          borderBottom: "2px solid #3A63F3",
          marginTop: "24px",
          display: "flex",
          overflow: "auto",
          resize: "vertical",
        }}
        className="survey-input"
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="15vh"
      >
        <Button
          variant="outlined"
          sx={{
            fontFamily: "sans-serif",
            padding: "8px 14px",
            border: "1px solid #3A63F3",
            borderRadius: "5px",
            color: "#000",
          }}
          endIcon={<UploadIcon />}
          onClick={() => setOpenPopup(true)}
        >
          Image upload
        </Button>
      </Box>
      <div>
        <Dialog open={openPopup}>
          <DialogActions>
            <Stack direction="row" spacing={3}>
              <IconButton
                aria-label="capture"
                onClick={() => {
                  setOpenPopup(false);
                  setActive("capture");
                }}
              >
                <PhotoCameraIcon />
              </IconButton>
              <IconButton
                aria-label="choose-file"
                onClick={() => {
                  setOpenPopup(false);
                  setActive("choose");
                }}
              >
                <PhotoLibraryIcon />
              </IconButton>
            </Stack>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        {active === "capture" && (
          <Card
            title="capture"
            sx={{
              flex: "1 1 auto",
              marginTop: "32px",
              display: "flex",
              flexDirection: "column",
              background: "#FFF",
              border: "none",
              alignItems: "center",
              boxShadow: "none",
            }}
          >
            {!OpenWebcam && !image ? (
              <>
                <Box
                  width="100px"
                  height="100px"
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                  sx={{
                    backgroundColor: "#A8A8A8",
                    borderRadius: "24px",
                  }}
                  onClick={() => setOpenWebcam(true)}
                >
                  <AddAPhoto color="primary" size={24} />
                </Box>
              </>
            ) : !OpenWebcam && image ? (
              <Box
                position={"relative"}
                display="flex"
                flexDirection={"column"}
                justifyContent="space-around"
                style={{
                  width: "200px",
                  height: "200px",
                  overflowY: "auto",
                }}
              >
                <img src={image} alt="img" style={{ maxHeight: "150px" }} />
                <Button
                  className="nextPreviousButton"
                  size="small"
                  //   disabled={props.disabled}
                  onClick={() => {
                    setImage(undefined);
                    setOpenWebcam(true);
                  }}
                >
                  <span className="nextPreviousButtonText">Retake</span>
                </Button>
              </Box>
            ) : OpenWebcam ? (
              <Box
                width={"100%"}
                display="flex"
                flexDirection={"column"}
                alignItems="center"
                height="200px"
                sx={{ overflowY: "scroll" }}
              >
                <Webcam
                  mirrored
                  screenshotFormat="image/jpeg"
                  style={{ width: "200px", height: "150px" }}
                >
                  {({ getScreenshot }) => (
                    <Button
                      className="medium"
                      onClick={() => {
                        let temp = getScreenshot();
                        setImage(temp);
                        setOpenWebcam(false);
                        if (Testimonial === "") Value.splice(0, 0, temp);
                        else Value.splice(1, 0, temp);
                        selected(Value);
                      }}
                    >
                      Capture photo
                    </Button>
                  )}
                </Webcam>
              </Box>
            ) : (
              ""
            )}
          </Card>
        )}

        {active === "choose" && (
          <Card
            title="choose-file"
            sx={{
              flex: "1 1 auto",
              marginTop: "32px",
              display: "flex",
              flexDirection: "column",
              background: "#FFF",
              alignItems: "center",
              boxShadow: "none",
            }}
          >
            <Box
              style={{
                background: "#E9EDF9",
                border: "1px solid rgba(0, 0, 0, 0.15)",
                borderRadius: "24px",
                backgroundColor: "#A8A8A8",
                width: "100px",
                height: "100px",
                marginLeft: "12px",
                position: "relative",
              }}
            >
              <label
                role="button"
                style={{
                  position: "absolute",
                  left: "0",
                  top: "0",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  style={{
                    visibility: "hidden",
                    position: "absolute",
                  }}
                  onChange={(e) => uploadImage(e)}
                />
                {!image ? (
                  <img src={addIcon} alt="add" height={"13px"} width="13px" />
                ) : (
                  <img src={image} alt="bgimage" width={"100%"} height="100%" />
                )}
              </label>
            </Box>
          </Card>
        )}
      </div>
    </div>
  );
}
