import React, { Fragment, useEffect, useState } from "react";
import { MdAssignment, MdPeople, MdAccountTree } from "react-icons/md";
import "./AccountNavbar.css";
import { useLocation, useNavigate } from "react-router-dom";
import { gstdept } from "../../Assets";
import { Tabs, Tab, Box } from "@mui/material";
import kdisc from "../../Assets/kdisc.gif";

const tabStyle = {
  display: "flex",
  justifyContent: "start",
  fontSize: "16px",
  color: "rgba(0, 0, 0, 0.6)",
};

const navItems = ["/surveys", "/settings/users", "/settings/branches"];
export default function AccountNavbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState(0);
  const handleChange = (e, value) => {
    setSelected(value);
    navigate(navItems[value]);
  };

  const isSuperAdmin = localStorage.role === "Account Owner";

  useEffect(() => {
    const index = navItems.findIndex((loc) => location.pathname === loc);
    if (index !== -1) {
      setSelected(index);
    }
  }, [location]);

  return (
    <Fragment>
      <div className="accountNavbarWrapper">
        <div className="navbarLogoWrapper">
          <img src={kdisc} alt="kdisc logo" style={{ height: "50px" }} />
        </div>
        <Box>
          {isSuperAdmin ? (
            <>
              {" "}
              <Tabs
                orientation="vertical"
                value={selected}
                onChange={handleChange}
                aria-label="Tab navigation"
                sx={{
                  borderRight: 1,
                  borderColor: "divider",
                  textAlign: "start",
                }}
              >
                <Tab
                  iconPosition="start"
                  icon={<MdAssignment fontSize={"large"} />}
                  label="Surveys"
                  sx={tabStyle}
                  onClick={(e) => handleChange(e, 0)}
                />
                <Tab
                  iconPosition="start"
                  icon={<MdPeople />}
                  label="Users"
                  sx={tabStyle}
                  onClick={(e) => handleChange(e, 1)}
                />
                <Tab
                  iconPosition="start"
                  icon={<MdAccountTree />}
                  label="Branches"
                  sx={tabStyle}
                  onClick={(e) => handleChange(e, 2)}
                />
              </Tabs>
            </>
          ) : (
            <Tabs
              orientation="vertical"
              value={selected}
              onChange={handleChange}
              aria-label="Tab navigation"
              sx={{
                borderRight: 1,
                borderColor: "divider",
                textAlign: "start",
              }}
            >
              <Tab
                iconPosition="start"
                icon={<MdAssignment fontSize={"large"} />}
                label="Surveys"
                sx={tabStyle}
                onClick={(e) => handleChange(e, 0)}
              />
            </Tabs>
          )}
        </Box>
        <div className="emptyDivWrapper">
          <img src={gstdept} alt="gst dept" style={{ height: "70px" }} />
        </div>
      </div>
    </Fragment>
  );
}
