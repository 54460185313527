import { AppBar, Box, Button, Toolbar } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TitleEdit from "./TitleEdit";
import Capitalise from "../Capitalise/Capitalise";
import "./QuestionNavbar.css";

const surveyNavbarData = [
  {
    name: "Build",
    link: "build",
  },
  {
    name: "Share",
    link: "share",
  },
  {
    name: "Result",
    link: "result",
  },
];

export default function QuestionNavbar({ id, surveyTitle }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="QuestionNavbarWrapper">
      <Box style={{ display: "flex" }}>
        <Toolbar
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <TitleEdit id={id} surveyTitle={surveyTitle} />
          <Box
            display={"flex"}
            justifyContent="space-between"
            alignItems={"end"}
            height="100%"
            gap={{ sx: 1, sm: 6 }}
          >
            {surveyNavbarData.map((item) => {
              return (
                <Button
                  key={item.link}
                  sx={{
                    padding: { xs: "6px 5px", sm: "6px 8px" },
                    fontWeight: "400",
                    // color: "#EFF0F4",
                    color: "#000",
                    borderRadius: "0px",
                    height: "35px",
                    textTransform: "none",
                    borderBottom:
                      location.pathname.indexOf(`/${item.link}`) !== -1
                        ? "3px solid #2C7BE5"
                        : "none",
                  }}
                  onClick={() => navigate(item.link)}
                >
                  {item.name}
                </Button>
              );
            })}
          </Box>
        </Toolbar>
      </Box>
    </div>
  );
}
