import React from "react";
import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  Grid,
  Modal,
  TextField,
  Stack,
} from "@mui/material";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { resetPasswordReq } from "../../Api";
import HighlightOff from "@mui/icons-material/HighlightOff";

export default function ForgotPassword(props) {
  const handleSubmit = (values, isSubmitting) => {
    isSubmitting.setSubmitting(true);
    resetPasswordReq(values.Email)
      .then(() => {
        toast.success("Reset link sent successfully");
        props.close();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Could not complete your request.");
      });
  };

  let validateForm = (values) => {
    const errors = {};
    if (!values.Email) errors.Email = "Enter your registered email address";
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email))
      errors.Email = "Invalid email address";
    return errors;
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.close()}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack
        justifyContent="end"
        alignItems={"end"}
        sx={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "8px",
        }}
        gap={2}
      >
        <HighlightOff onClick={props.close} fontSize="large" />
        <Box
          display="flex"
          flexDirection={"column"}
          justifyContent="center"
          sx={{ height: "250px" }}
        >
          <Formik
            initialValues={{ Email: "" }}
            validate={validateForm}
            onSubmit={(values, isSubmitting) =>
              handleSubmit(values, isSubmitting)
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormGroup style={{ marginBottom: "15px" }}>
                  <FormLabel style={{ color: "#000", marginBottom: "9px" }}>
                    Email
                  </FormLabel>
                  <TextField
                    type="email"
                    name="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.Email}
                    size="small"
                    placeholder="Enter Your email address"
                    variant="outlined"
                  />
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.Email && touched.Email && errors.Email}
                  </div>
                </FormGroup>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "12px",
                  }}
                >
                  <Button type="submit" variant="contained">
                    Send reset link
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Stack>
    </Modal>
  );
}
