import { AddAPhoto, CloseSharp } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import QuestionPreviewModal from "../Modals/QuestionPreviewModal";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import QuestionField from "../QuestionField/QuestionField";
import { addThankyouImageIcon } from "../../Assets";

let encodedThankYouImage;
export default function ThankYouPage(props) {
  const [OpenPreviewModal, setOpenPreviewModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("first");

  const [ThankYouImage, setThankYouImage] = useState(
    props?.question?.image || ""
  );

  let uploadThankYouImage = (e) => {
    let file = e.target.files[0];
    if (file.size > 2000000) return alert("File size should be less than 2MB");
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        encodedThankYouImage = reader.result.toString();
        setThankYouImage(encodedThankYouImage);
        props.updateQuestion({ image: encodedThankYouImage });
        resolve(encodedThankYouImage);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  let deleteThankYouImage = (e) => {
    setThankYouImage("");
    props.updateQuestion({ image: "" });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <QuestionPreviewModal
        type="thank you"
        logo={props.logo}
        open={OpenPreviewModal}
        template={props?.template}
        close={() => setOpenPreviewModal(false)}
        title={
          selectedLanguage === "first"
            ? props.question.l1_title
            : props.question.l2_title
        }
        description={
          selectedLanguage === "first"
            ? props.question.l1_description
            : props.question.l2_description
        }
      />
      <div className="question-field-section">
        <Box
          display={"flex"}
          justifyContent="space-between"
          alignItems={"center"}
          minWidth="20%"
          style={{ paddingTop: "20px" }}
          position="static"
        >
          <Box
            sx={{ display: { xs: "none", sm: "block" }, width: "12%" }}
          ></Box>
          <Box>
            <Button
              variant="contained"
              size="small"
              className="save-button"
              sx={{
                fontWeight: "700",
                marginRight: "24px",
                background: "#CCC",
                color: "#000",
                borderRadius: "2.5px",
              }}
              onClick={() => props.updateQuestion({}, "save")}
            >
              Save
            </Button>
            <Button
              variant="contained"
              size="small"
              className="preview-button"
              sx={{
                fontWeight: "700",
                background: "#FFC700",
                color: "#000",
                borderRadius: "2.5px",
              }}
              onClick={() => setOpenPreviewModal(true)}
            >
              Preview
            </Button>
          </Box>
          {props.language.length === 2 ? (
            <LanguageToggle
              language={props.language}
              setSelectedLanguage={setSelectedLanguage}
            />
          ) : (
            <Box
              sx={{ display: { xs: "none", sm: "block" }, width: "12%" }}
            ></Box>
          )}
        </Box>
        <Box
          width={"100%"}
          display="flex"
          justifyContent={"center"}
          alignItems="center"
          flexDirection={"column"}
          style={{ paddingTop: "64px" }}
        >
          {ThankYouImage ? (
            <Box className="image-slot" position={"relative"}>
              <img
                src={ThankYouImage}
                height="100%"
                width={"153px"}
                style={{ borderRadius: "5px" }}
                alt="uploaded"
              />
              <label
                role="button"
                style={{ position: "absolute", padding: "2px" }}
                className="change-photo-icon"
              >
                <input
                  type="file"
                  className="form-control position-absolute"
                  accept="image/*"
                  style={{ visibility: "hidden" }}
                  onChange={(e) => uploadThankYouImage(e)}
                />
                <AddAPhoto color="var(--primary-color)" fontSize="20px" />
              </label>
              <div
                className="delete-photo-icon"
                style={{
                  position: "absolute",
                  padding: "0px",
                  cursor: "pointer",
                }}
                onClick={(e) => deleteThankYouImage(e)}
              >
                <CloseSharp color="var(--primary-color)" fontSize="20px" />
              </div>
            </Box>
          ) : (
            <Box className="image-slot">
              <label
                role="button"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={(e) => uploadThankYouImage(e)}
                />
                <img src={addThankyouImageIcon} alt="thank you" />
                <Typography
                  variant="body2"
                  fontWeight={"600"}
                  sx={{ color: "#737782", marginTop: "24px" }}
                  align="center"
                >
                  Click here to upload a thank you image
                </Typography>
              </label>
            </Box>
          )}
        </Box>
        <QuestionField
          question={props.question}
          handleQuestionChange={props.handleQuestionChange}
          updateQuestion={props.updateQuestion}
          selectedLanguage={selectedLanguage}
        />
      </div>
    </div>
  );
}
