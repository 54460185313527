import { Box, FormLabel, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  SMILEYS,
  defaultSmileyValues,
  smileyResponses,
} from "../QuestionOptionCards/SmileyOptionCard";

export default function SmileyForm(props) {
  const { smileyLabels } = props;
  const [Value, setValue] = useState(props.initialValue || "");

  useEffect(() => {}, [props]);

  useEffect(() => {
    setValue(props.initialValue);
  }, [props.initialValue]);

  let handleNext = (values, type) => {
    props.next(props.name, values, type);
    // setValue("");
    // values.Value = "";
    // props.errors.Value = ''
  };

  let responseClick = (condition) => {
    props.setyesorno(condition);
  };

  const handleClick = (smileyIndex) => {
    setValue(smileyResponses[smileyIndex]);
    props.values.Value = smileyResponses[smileyIndex];
    responseClick(smileyIndex);
    setTimeout(() => handleNext(props.values, props.type), 300);
  };

  return (
    <Box
      width="100%"
      paddingBottom="20px"
      flexDirection={"column"}
      justifyContent="center"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{ height: "100%" }}
      >
        <FormLabel
          required={props.required}
          sx={{
            display: "flex",
            marginBottom: "8px",
            color: "#3F3D56",
            fontSize: !props.hideButton
              ? {
                  md: props.label.length > 80 ? "1rem" : "1.5rem",
                  xs: props.label.length > 80 ? "0.75" : "1.25rem",
                }
              : {
                  md: props.label.length > 80 ? "0.9rem" : "1.1rem",
                  xs: props.label.length > 80 ? "0.75" : "1.1rem",
                },
            fontWeight: !props.hideButton ? "750" : "600",
            maxWidth: "100%",
          }}
          className="details-label bold"
        >
          {props.label}
        </FormLabel>
        {props.description && (
          <Typography style={{ color: "grey", fontSize: "18px" }}>
            {props.description}
          </Typography>
        )}
        <Box
          marginTop="24px"
          display={"flex"}
          gap={{ xs: 2, md: 4 }}
          maxWidth="100%"
          overflow={"scroll"}
        >
          {
            <ShowSmileys
              smileyLabels={smileyLabels}
              onClick={handleClick}
              selectedSmiley={Value}
            />
          }
        </Box>
      </motion.div>
    </Box>
  );
}
function ShowSmileys({ smileyLabels, selectedSmiley, onClick }) {
  if (!smileyLabels || smileyLabels.length === 0) {
    smileyLabels = defaultSmileyValues;
  }
  return SMILEYS.map((smiley, index) => (
    <Box display={"flex"} flexDirection="column" alignItems={"center"}>
      <img
        src={smiley}
        alt={smileyLabels[index]}
        width={"50px"}
        height="50px"
        onClick={() => onClick(index)}
        style={{
          cursor: "pointer",
          border:
            selectedSmiley === smileyResponses[index]
              ? "solid #007aff 5px"
              : "none",
          borderRadius: "100px",
        }}
      />
      <Typography style={{ color: "#000" }}>{smileyLabels[index]}</Typography>
    </Box>
  ));
}
