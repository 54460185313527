import { Box, Button, Stack, Select, TextField, MenuItem } from "@mui/material";
import React, { useState } from "react";
import OTPInput from "otp-input-react";
import OtpValidationOptionCard from "../QuestionOptionCards/OtpValidationOptionCard";
import QuestionPreviewModal from "../Modals/QuestionPreviewModal";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import QuestionField from "../QuestionField/QuestionField";
import { flagofIndia } from "../../Assets";

export default function OtpValidationQuestion(props) {
  const [OpenPreviewModal, setOpenPreviewModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("first");
  const [Required, setRequired] = useState(props.question?.required || false);

  let changeRequired = (value) => {
    setRequired(value);
    props.updateQuestion({ required: value });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: "auto",
      }}
    >
      <QuestionPreviewModal
        logo={props.logo}
        open={OpenPreviewModal}
        template={props?.template}
        close={() => setOpenPreviewModal(false)}
        title={
          selectedLanguage === "first"
            ? props.question.l1_title
            : props.question.l2_title
        }
        description={
          selectedLanguage === "first"
            ? props.question.l1_description
            : props.question.l2_description
        }
        type="otp"
      />
      <div className="question-field-section">
        <Box className="savePreviewButtonWrapper">
          <Box
            sx={{ display: { xs: "none", sm: "block" }, width: "12%" }}
          ></Box>
          <Box>
            <Button
              variant="contained"
              size="small"
              className="save-button"
              sx={{
                fontWeight: "700",
                marginRight: "24px",
                background: "#CCC",
                color: "#000",
                borderRadius: "2.5px",
              }}
              onClick={() =>
                props.updateQuestion(
                  {
                    required: Required,
                  },
                  "save"
                )
              }
            >
              Save
            </Button>
            <Button
              variant="contained"
              size="small"
              className="preview-button"
              sx={{
                fontWeight: "700",
                background: "#FFC700",
                color: "#000",
                borderRadius: "2.5px",
              }}
              onClick={() => setOpenPreviewModal(true)}
            >
              Preview
            </Button>
          </Box>
          {props.language.length === 2 ? (
            <LanguageToggle
              language={props.language}
              setSelectedLanguage={setSelectedLanguage}
            />
          ) : (
            <Box
              sx={{ display: { xs: "none", sm: "block" }, width: "12%" }}
            ></Box>
          )}
        </Box>
        <QuestionField
          question={props.question}
          handleQuestionChange={props.handleQuestionChange}
          updateQuestion={props.updateQuestion}
          selectedLanguage={selectedLanguage}
        />
        <Box
          width="100%"
          display={"flex"}
          justifyContent="center"
          marginTop={"22px"}
        >
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={1}>
              <Select
                defaultValue="+91"
                className="select-action"
                style={{
                  borderRadius: "2.5px",
                  backgroundColor: "#D9D9D9",
                  border: "1px",
                  fontSize: "15px",
                  fontWeight: "120px",
                }}
                disabled
              >
                <MenuItem value="+91">
                  <img src={flagofIndia} alt="INDIA" width={"25px"} /> &nbsp; IN
                </MenuItem>
              </Select>

              <TextField
                type="tel"
                style={{ border: "none", backgroundColor: "#D9D9D9" }}
                disabled
              />
            </Stack>
            <Box display="flex" justifyContent="center" gap={1}>
              <Button
                type="submit"
                style={{
                  background: "#FFCC00",
                  color: "#000",
                  fontWeight: "600",
                  fontSize: "1.1rem",
                  textTransform: "none",
                  marginTop: "40px",
                  marginBottom: "5px",
                }}
                disabled
              >
                Get OTP
              </Button>
            </Box>
          </Stack>
        </Box>
        <Box
          width={"100%"}
          justifyContent="center"
          display="flex"
          marginTop={"26px"}
          gap={2}
        >
          <OTPInput OTPLength={4} otpType="number" disabled={true} secure />
        </Box>
      </div>
      <OtpValidationOptionCard
        // page={1}
        id={props.questionId}
        survey={props.survey}
        updateSurvey={(value) => props.updateSurvey(value)}
        required={(value) => changeRequired(value)}
        addNextQuestion={() => props.addNextQuestion()}
        changeQuestionType={() => props.changeQuestionType()}
      />
    </div>
  );
}
