import {
  Box,
  ButtonGroup,
  FormLabel,
  ToggleButton,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";

import React, { useEffect } from "react";
import Capitalise from "../Capitalise/Capitalise";

export default function MultipleChoiceForm(props) {
  useEffect(() => {}, [props.initialValue, props.errors]);

  let handleNext = (values, type) => {
    props.next(props.name, values, type);
  };

  let responseClick = (condition) => {
    props.setyesorno(condition);
  };

  return (
    <Box
      width="100%"
      paddingBottom="20px"
      flexDirection={"column"}
      justifyContent="center"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{ height: "100%" }}
      >
        <FormLabel
          required={props.required}
          sx={{
            display: "flex",
            marginBottom: "8px",
            color: "#3F3D56",
            fontSize: !props.hideButton
              ? {
                  md: props.label.length > 80 ? "1rem" : "1.5rem",
                  xs: props.label.length > 80 ? "0.75" : "1.25rem",
                }
              : {
                  md: props.label.length > 80 ? "0.9rem" : "1.1rem",
                  xs: props.label.length > 80 ? "0.75" : "1.1rem",
                },
            fontWeight: !props.hideButton ? "750" : "600",
            maxWidth: "100%",
          }}
          className={`label bold`}
        >
          {Capitalise(props.label)}
        </FormLabel>
        {props.description && (
          <Typography style={{ color: "grey", fontSize: "18px" }}>
            {props.description}
          </Typography>
        )}
        <ButtonGroup
          style={{
            display: !props.hideButton ? "block" : "flex",
            marginTop: "24px",
            maxHeight: "350px",
            overflowY: "auto",
          }}
          className={`normal mb-2`}
        >
          {props.options.map((option, idx) => (
            <Box key={idx} marginRight={props.hideButton && "8px"}>
              <ToggleButton
                key={idx}
                name="Value"
                value={option}
                id={`radio-${idx}`}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                disabled={props.disabled}
                className="radio-label"
                checked={props?.value === option}
                onClick={
                  !props.disabled
                    ? () => {
                        props.values.Value = option;
                        responseClick(
                          props.options.findIndex((o) => o === option)
                        );
                        setTimeout(
                          () => handleNext(props.values, props.type),
                          300
                        );
                      }
                    : ""
                }
                style={{
                  cursor: !props.disabled ? "pointer" : "default",
                  height: {
                    xs: "50px",
                    md: !props.hideButton ? "60px" : "30px",
                  },
                  fontSize: !props.hideButton ? "1.1rem" : "0.9rem",
                  color: props?.values?.Value === option ? "#FFF" : "#000",
                  background:
                    props?.values?.Value === option ? "#3A63F3" : "#FFF",
                  border: "2px solid rgba(0,0,0,0.15)",
                  borderRadius: "8px",
                  marginBottom: "10px",
                  fontWeight: "700",
                }}
              >
                {option}
              </ToggleButton>
            </Box>
          ))}
        </ButtonGroup>
      </motion.div>
    </Box>
  );
}
