import React from "react";
import { ThreeDots } from "react-loader-spinner";
import "./Loader.css";
function Loader() {
  return (
    <div className="loaderWrapper">
      <ThreeDots
        height="100"
        width="100"
        radius="5"
        color="grey"
        ariaLabel="three-dots-loading"
        visible={true}
      />
    </div>
  );
}
export default Loader;
