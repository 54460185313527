import React from "react";
import "./Progressbar.css";
function Progressbar({ present, startcaption, endcaption }) {
  return (
    <div className="progressbarWrapper">
      <div style={{ width: `${present}%` }} className="progressBar"></div>
      <span className="startCaption">{startcaption}</span>
      <span className="endCaption">{endcaption}</span>
    </div>
  );
}

export default Progressbar;
