import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  FormGroup,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import { toast } from "react-toastify";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { resetPassword } from "../../../Api";

export default function ResetPassword() {
  const { token, userId } = useParams();
  const [newPassword, setNewPassword] = useState({
    password: "",
    showPassword: false,
  });
  const [confirmPassword, setConfirmPassword] = useState({
    password: "",
    showPassword: false,
  });

  let validateForm = (values) => {
    const errors = {};

    if (!values.new) errors.new = "*Enter the new password";
    else if (
      !/^(?=.*[A-Za-z])+(?=.*\d)+(?=.*[@$!%*#?&])+[A-Za-z\d@$!%*#?&]{8,}$/i.test(
        values.new
      )
    )
      errors.new =
        "*Password must contain minimum 8 characters and must include Upper case, Lower case, Numbers and Special characters";

    if (!values.confirm) errors.confirm = "*Please confirm password";
    else if (values.confirm !== values.new)
      errors.confirm = "*Password does not match";

    return errors;
  };

  const handleSubmit = (values, isSubmitting) => {
    resetPassword(userId, token, values.new)
      .then(() => {
        toast.success("Password set successfully");
      })
      .catch((err) => {
        toast.error("Could not reset password");
        console.log(err);
        isSubmitting.setSubmitting(false);
      });
  };
  const handleNewShowPassword = () => {
    setNewPassword({
      ...newPassword,
      showPassword: !newPassword.showPassword,
    });
  };
  const handleConfirmShowPassword = () => {
    setConfirmPassword({
      ...confirmPassword,
      showPassword: !confirmPassword.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Box
      className="forgotpassword-modal"
      style={{ alignContent: "center", width: "300px", height: "300px" }}
      display="flex"
      flexDirection={"column"}
      justifyContent="center"
    >
      <Typography
        align="center"
        fontSize={"500"}
        fontWeight={"700"}
        marginTop={"31px"}
      >
        Reset Password
      </Typography>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent="center"
        marginTop="16px"
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            new: "",
            confirm: "",
          }}
          validate={validateForm}
          onSubmit={(values, actions, isSubmitting) => {
            handleSubmit(values, isSubmitting);
            actions.resetForm({
              values: {
                new: "",
                confirm: "",
              },
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                <FormGroup style={{ marginBottom: "15px" }}>
                  <TextField
                    type={newPassword.showPassword ? "text" : "password"}
                    name="new"
                    label="New Password"
                    variant="outlined"
                    value={values.new}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleNewShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {newPassword.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div style={{ color: "red", fontSize: "10px" }}>
                    {errors.new && touched.new && errors.new}
                  </div>
                </FormGroup>
                <FormGroup style={{ marginBottom: "15px" }}>
                  <TextField
                    type={confirmPassword.showPassword ? "text" : "password"}
                    name="confirm"
                    label="Confirm Password"
                    variant="outlined"
                    value={values.confirm}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleConfirmShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {confirmPassword.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div style={{ color: "red", fontSize: "10px" }}>
                    {errors.confirm && touched.confirm && errors.confirm}
                  </div>
                </FormGroup>
                <Button size="small" type="submit" disabled={isSubmitting}>
                  Change Password
                </Button>
              </FormControl>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
