import { scale } from "chroma-js";

export const supportedTypes = [
  "rating",
  "smiley",
  "yes or no",
  "opinion scale",
  "multiple choice",
  "multiple select",
];
export const colorScale = scale(["green", "red"]).colors(6);

export const multipleOptionColor = [
  "#090a74",
  "#5ca51c",
  "#29f9b1",
  "#e6124b",
  "#0fce81",

  "#b5951a",
  "#0144a3",
  "#0183e8",
  "#318014",
  "#6e51ae",

  "#bae08e",
  "#f4cf82",
  "#91eac2",
  "#a21a36",
  "#2ed6fd",
  "#4f0304",
  "#91f6f8",
  "#320a06",
  "#c4f1f6",
  "#071317",
  "#fbd7fe",
  "#06560a",
  "#af92dd",
  "#3e6417",
  "#1d9df1",
  "#a75322",
  "#3ab6f3",
  "#6f310e",
  "#2dbcc2",
  "#dc6775",
  "#117449",
  "#ff92aa",
  "#063502",
  "#dbb9f5",
  "#052805",
  "#eee7f8",
  "#071c06",
  "#ede8e1",
  "#0d152c",
  "#f7a368",
  "#0157a7",
  "#755e08",
  "#35417e",
  "#a48c44",
  "#3272a9",
  "#503e08",
  "#99d2f7",
  "#40290f",
  "#d4e1c2",
  "#0c2d4a",
  "#f5cead",
  "#00272a",
  "#fbb1bb",
  "#004a22",
  "#f7cbcf",
  "#242626",
  "#b8bdcb",
  "#722c34",
  "#17939a",
  "#b57451",
  "#197ca0",
  "#739150",
  "#334b73",
  "#b4b08f",
  "#033744",
  "#b5c1bb",
  "#28412c",
  "#b87075",
  "#1a6a72",
  "#93775e",
  "#104e58",
  "#a39d9a",
  "#424044",
  "#779f83",
  "#645f5e",
  "#779aac",
  "#4b664e",
  "#878385",
];
export const COLORS = {
  "very good": "#4ECC56",
  good: "#ACD91A",
  neutral: "#FFCF2B",
  bad: "#FE7E1F",
  "very bad": "#E8354D",
  positive: "#4ECC56",
  negative: "#E8354D",
};
export const npsChartColor = ["#4ECC56", "#E8354D", "#FECE2A"];
