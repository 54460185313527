import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import OpinionScaleOptionCard from "../QuestionOptionCards/OpinionScaleOptionCard";
import QuestionPreviewModal from "../Modals/QuestionPreviewModal";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import QuestionField from "../QuestionField/QuestionField";
import { opinionScaleCircleIcon } from "../../Assets";

export default function OpinionScaleQuestion(props) {
  const [OpenPreviewModal, setOpenPreviewModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("first");
  const [Required, setRequired] = useState(props.question?.required || false);

  // const [LeftLabel, setLeftLabel] = useState(props?.question?.left_label || 'bad')
  // const [RightLabel, setRightLabel] = useState(props?.question?.right_label || 'good')
  // const [MiddleLabel, setMiddleLabel] = useState(props?.question?.middle_label || '')
  const [logic, setLogic] = useState([]);

  let changeRequired = (value) => {
    setRequired(value);
    props.updateQuestion({ required: value });
  };
  let opinionScaleArray = [
    { alt: "vey bad", number: 1, label: "1goto" },
    { alt: "vey bad", number: 2, label: "2goto" },
    { alt: "bad", number: 3, label: "3goto" },
    { alt: "bad", number: 4, label: "4goto" },
    { alt: "normal", number: 5, label: "5goto" },
    { alt: "normal", number: 6, label: "6goto" },
    { alt: "good", number: 7, label: "7goto" },
    { alt: "good", number: 8, label: "8goto" },
    { alt: "vey good", number: 9, label: "9goto" },
    { alt: "vey good", number: 10, label: "10goto" },
  ];
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: "auto",
      }}
    >
      <QuestionPreviewModal
        logo={props.logo}
        type="opinion scale"
        open={OpenPreviewModal}
        close={() => setOpenPreviewModal(false)}
        template={props?.template}
        title={
          selectedLanguage === "first"
            ? props.question.l1_title
            : props.question.l2_title
        }
        description={
          selectedLanguage === "first"
            ? props.question.l1_description
            : props.question.l2_description
        }
      />
      <div className="question-field-section">
        <Box className="savePreviewButtonWrapper">
          <Box
            sx={{ display: { xs: "none", sm: "block" }, width: "12%" }}
          ></Box>
          <Box>
            <Button
              size="small"
              variant="contained"
              className="save-button"
              onClick={() =>
                props.updateQuestion(
                  {
                    required: Required,

                    logic: logic,
                  },
                  "save"
                )
              }
              sx={{
                color: "#000",
                boxShadow: "none",
                fontWeight: "700",
                background: "#CCC",
                marginRight: "24px",
                borderRadius: "2.5px",
              }}
            >
              Save
            </Button>
            <Button
              size="small"
              variant="contained"
              className="preview-button"
              onClick={() => setOpenPreviewModal(true)}
              sx={{
                color: "#000",
                boxShadow: "none",
                fontWeight: "700",
                borderRadius: "2.5px",
                background: "#FFC700",
              }}
            >
              Preview
            </Button>
          </Box>
          {props.language.length === 2 ? (
            <LanguageToggle
              language={props.language}
              setSelectedLanguage={setSelectedLanguage}
            />
          ) : (
            <Box
              sx={{ display: { xs: "none", sm: "block" }, width: "12%" }}
            ></Box>
          )}
        </Box>
        <QuestionField
          question={props.question}
          handleQuestionChange={props.handleQuestionChange}
          updateQuestion={props.updateQuestion}
          selectedLanguage={selectedLanguage}
        />
        <Box
          width={"100%"}
          justifyContent="center"
          display="flex"
          marginTop={"26px"}
          gap={2}
        >
          {opinionScaleArray.map((opinion) => (
            <Box
              key={opinion.number}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={opinionScaleCircleIcon}
                alt={opinion.alt}
                height="54px"
              />
              <Typography
                style={{
                  position: "absolute",
                  fontWeight: "600",
                  top: "16px",
                  left: "22px",
                }}
              >
                {opinion.number}
              </Typography>
            </Box>
          ))}
        </Box>
      </div>
      <OpinionScaleOptionCard
        id={props.questionId}
        survey={props.survey}
        surveyType={props.surveyType}
        isRequired={Required}
        updateSurvey={(value) => props.updateSurvey(value)}
        required={(value) => changeRequired(value)}
        changeQuestionType={() => props.changeQuestionType()}
        addNextQuestion={() => props.addNextQuestion()}
        updateQuestion={(value) => setLogic(value)}
        type="opinion scale"
        totalQuestions={props.totalQuestions}
        opinionScaleArray={opinionScaleArray}
        questionLogic={props.question.logic}
      />
    </div>
  );
}
