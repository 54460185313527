import { Box, Card, CardContent, Grid, Tooltip } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import React, { useEffect } from "react";
import { deleteIcon } from "../../../Assets";
import { toast } from "react-toastify";
import { useState } from "react";
import "./EditSurveyPage.css";

export default function DrawerSection(props) {
  const [questions, setQuestions] = [props.questions, props.setQuestions];
  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  let deleteQuestion = (e, id) => {
    e.stopPropagation();
    props.delete(id);
  };

  let onDragEnd = (e) => {
    const { destination, source, draggableId } = e;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    let newQuestions = questions;
    let [draggedQuestion] = questions.filter(
      (quest) => quest._id === draggableId
    );
    if (draggedQuestion.type === "thank you") {
      toast.error("Thank you should be at the end");
      return;
    }
    if (props.thankYouExists) {
      if (destination.index === questions.length - 1) {
        toast.error("Thankyou should be at the end");
        return;
      }
    }
    newQuestions.splice(source.index, 1);
    newQuestions.splice(destination.index, 0, draggedQuestion);
    newQuestions = newQuestions.map((question, i) => {
      question.order = i + 1;
      return question;
    });
    setQuestions(newQuestions);
    props.updateAllQuestions(newQuestions);
  };
  const isMobile = width < 600;

  return (
    <Box
      height="100%"
      flexDirection="column"
      paddingRight={{ xs: "0", ms: "15px" }}
      width={isMobile ? "100%" : "30%"}
      sx={{
        display: !props.open && isMobile ? "none" : "flex",
        position: isMobile ? "absolute" : "relative",
        flexShrink: 0,
      }}
    >
      <Card className="drawerUpperSectionWrapper">
        <Card
          onClick={() => props.clicked("add")}
          className="drawer-button-card"
        >
          <CardContent
            style={{
              color: "#FFF",
              display: "flex",
              justifyContent: "center",
              padding: 0,
            }}
          >
            Add Question
          </CardContent>
        </Card>
      </Card>
      <Card className="drawerLowerSectionWrapper">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-1">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ minHeight: "100px", minWidth: "100px" }}
              >
                {questions.map((question, i) => (
                  <Draggable
                    draggableId={question._id}
                    index={i}
                    key={question._id}
                  >
                    {(provided) => {
                      const style = {
                        cursor: "pointer",
                        marginBottom: "8px",
                        background: "#E9EDF9",
                        border: "1px solid rgba(0, 0, 0, 0.15)",
                        borderRadius: "5px",
                        boxShadow: "none",
                        height: "60px",
                        borderLeft:
                          question?._id === props.selectedQuestion?._id
                            ? "18px solid #3A63F3"
                            : "",
                        ...provided.draggableProps.style,
                      };
                      return (
                        <Card
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          className="question-card"
                          style={style}
                          onClick={() => props.edit(question)}
                        >
                          <CardContent
                            key={i}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              height: "-webkit-fill-available",
                              padding:
                                question?._id === props.selectedQuestion?._id
                                  ? "18px 10px 18px 0"
                                  : "18px 10px 18px 18px",
                            }}
                          >
                            <Box display={"flex"} width={"95%"}>
                              <div
                                style={{
                                  fontSize: "12px",
                                  width: "19px",
                                  height: "19px",
                                  color:
                                    question?._id ===
                                    props.selectedQuestion?._id
                                      ? "#FFF"
                                      : "#000",
                                  background:
                                    question?._id ===
                                    props.selectedQuestion?._id
                                      ? " #3A63F3"
                                      : "#FFD037",
                                  borderRadius: "50%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginRight: "15px",
                                }}
                              >
                                {" "}
                                {i + 1}
                              </div>
                              <Tooltip title={question?.l1_title}>
                                <div className="drawer-question-card-title">
                                  {question.l1_title}
                                </div>
                              </Tooltip>
                            </Box>
                            <Box className="question-card-icons">
                              <img
                                src={deleteIcon}
                                alt="delete"
                                onClick={(e) => deleteQuestion(e, question._id)}
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Card>
    </Box>
  );
}
