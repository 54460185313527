import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  List,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import {
  removeUser,
  getBranchesByAccount,
  getAccountUsersByRole,
  updatePermissions,
} from "../../../Api";
import { AddUserModal, Capitalise } from "../../../Shared";
import { deleteIcon } from "../../../Assets";
import { toast } from "react-toastify";
import { Edit } from "@mui/icons-material";
import { Stack } from "@mui/system";

let branches = [];
export default function UsersSettingsPage() {
  const [ShowAddUserModal, setShowAddUserModal] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [Reload, setReload] = useState(true);
  const [users, setUsers] = useState([]);

  let removeuser = (id) => {
    removeUser(id)
      .then((res) => {
        let userIndex = users.findIndex((item) => item._id === res.data._id);
        users.splice(userIndex, 1);
        setReload(!Reload);
      })
      .catch((err) => {
        toast.error("Could not delete user. please try again later");
      });
  };
  useEffect(() => {
    Promise.all([getAccountUsersByRole("Admin"), getBranchesByAccount()]).then(
      (res) => {
        setUsers(res[0].data);
        branches = res[1].data;
        setLoading(false);
      }
    );
  }, [Reload]);

  return (
    <Fragment>
      <AddUserModal
        open={ShowAddUserModal}
        close={() => setShowAddUserModal(false)}
      />
      <Box className="generalSettingsPageWrapper">
        <Card
          sx={{
            margin: "10px auto",
            width: "98%",
            height: "97%",
            border: "1px solid rgba(0, 0, 0, 0.15)",
            borderRadius: "8px",
            boxShadow: "none",
          }}
        >
          <CardContent sx={{ maxHeight: "100%" }}>
            <Box width={"100%"} display="flex" justifyContent="space-between">
              <Typography variant="h5" fontWeight={"700"} marginBottom={"12px"}>
                User Management
              </Typography>
              <Box display={"flex"}>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search"
                  style={{
                    width: "180px",
                    height: "43px",
                    marginRight: "19px",
                  }}
                />
                <Button
                  variant="contained"
                  onClick={() => setShowAddUserModal(true)}
                  disabled={localStorage.role === "Admin"}
                  style={{
                    textTransform: "none",
                    width: "137px",
                    height: "39px",
                  }}
                >
                  Add User
                </Button>
              </Box>
            </Box>
            <Typography variant="subtitle2" style={{ color: "#A8A8A8" }}>
              Invite your users/admins to your account. Add, edit and remove
              <br /> your users using User Management.
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                marginTop: "52px",
                border: "none",
                borderRadius: "0px",
                boxShadow: "none",
                maxHeight: "calc(90vh - 130px)",
                overflow: "auto",
              }}
            >
              <Table
                stickyHeader
                sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow sx={{ "td,th": { border: 0 } }}>
                    <TableCell style={{ fontWeight: "700", color: "#3F3D56" }}>
                      Name
                    </TableCell>
                    <TableCell style={{ fontWeight: "700", color: "#3F3D56" }}>
                      Email
                    </TableCell>
                    <TableCell style={{ fontWeight: "700", color: "#3F3D56" }}>
                      Status
                    </TableCell>
                    <TableCell style={{ fontWeight: "700", color: "#3F3D56" }}>
                      Role
                    </TableCell>
                    <TableCell style={{ fontWeight: "700", color: "#3F3D56" }}>
                      Branch
                    </TableCell>
                    {localStorage.role === "Account Owner" ? (
                      <TableCell
                        style={{ fontWeight: "700", color: "#3F3D56" }}
                      >
                        Permissions
                      </TableCell>
                    ) : null}
                    <TableCell
                      style={{ fontWeight: "700", color: "#3F3D56" }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "td, th": {
                          border: 0,
                          background: "rgba(73, 79, 125, 0.07)",
                        },
                      }}
                    >
                      <TableCell
                        style={{ color: "#A8A8A8" }}
                        component="th"
                        scope="row"
                      >
                        {Capitalise(user.name)}{" "}
                      </TableCell>
                      <TableCell style={{ color: "#A8A8A8" }}>
                        {user.email}{" "}
                      </TableCell>
                      <TableCell style={{ color: "#A8A8A8" }}>
                        {user.status}
                      </TableCell>
                      <TableCell style={{ color: "#A8A8A8" }}>
                        {user.role}
                      </TableCell>
                      <TableCell style={{ color: "#A8A8A8" }}>
                        {
                          branches.filter((item) => item._id === user.branch)[0]
                            ?.name
                        }
                      </TableCell>

                      {localStorage.role === "Account Owner" ? (
                        <>
                          <TableCell style={{ color: "#A8A8A8" }}>
                            <PermissionsCell
                              currentPermissions={user.permissions}
                              userId={user._id}
                              onPermissionsUpdate={(newPermissions) => {
                                const index = users.findIndex(
                                  (u) => u._id === user._id
                                );
                                let newUsers = users;
                                newUsers[index].permissions = newPermissions;
                                setUsers([...newUsers]);
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            {user.role !== "Account Owner" ? (
                              // <button onClick={(e)=>{console.log("ehllo")}}>delete</button>
                              <img
                                src={deleteIcon}
                                alt="delete"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  removeuser(user._id);
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    </Fragment>
  );
}
const ALLOWED_PERMISSIONS = ["create", "edit", "delete"];

function PermissionsCell({ currentPermissions, userId, onPermissionsUpdate }) {
  if (currentPermissions === undefined) {
    currentPermissions = [];
  }
  const [open, setOpen] = useState(false);
  const [permissions, setPermissions] = useState(currentPermissions);

  const handleChange = (e, p) => {
    if (e.target.checked) {
      if (!permissions.includes(p)) {
        setPermissions([...permissions, p]);
      }
    } else {
      if (permissions.includes(p)) {
        setPermissions(permissions.filter((perm) => perm != p));
      }
    }
  };
  const handleSubmit = () => {
    updatePermissions(userId, permissions)
      .then((res) => {
        toast.success("Permissions updated!");
        onPermissionsUpdate(permissions);
      })
      .catch((e) => {
        console.log(e);
        onPermissionsUpdate(currentPermissions);

        toast.error("Error occured. Please refresh the page");
      });
    setOpen(false);
  };
  return (
    <>
      <Box>
        {currentPermissions.join(", ")}
        <IconButton
          onClick={() => setOpen(true)}
          sx={{
            "--IconButton-size": "40px",
          }}
        >
          <Edit />
        </IconButton>
      </Box>
      <Dialog open={open}>
        <DialogTitle>Set Permissions</DialogTitle>
        <List sx={{ p: "8px" }}>
          {ALLOWED_PERMISSIONS.map((p) => (
            <Stack key={p} direction={"row"} justifyContent="space-between">
              <Typography>{p}</Typography>
              <Checkbox
                checked={permissions.includes(p)}
                onChange={(e) => handleChange(e, p)}
              />
            </Stack>
          ))}
        </List>
        <DialogActions>
          <Button
            onClick={() => {
              setPermissions(currentPermissions);
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Update</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
