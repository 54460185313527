export function getAllSubsets(array) {
  const subsets = [[]];

  for (const el of array) {
    const last = subsets.length - 1;
    for (let i = 0; i <= last; i++) {
      subsets.push([...subsets[i], el]);
    }
  }

  return subsets;
}
export function isSubsetof(subset, superset) {
  for (const e of subset) {
    if (!superset.includes(e)) {
      return false;
    }
  }
  return true;
}
export function isArrayEqual(arr1, arr2) {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }
  return (
    arr1.length === arr2.length &&
    arr1.every((element) => arr2.includes(element))
  );
}

/*
localStorage.getItem("filledSurveys") => 
[
  {
    surveyId: String,
    branch: String,
    lastFilled: Date,
  }
]
*/
export function alreadyFilled(surveyId, branch) {
  try {
    let filledSurveys = localStorage.getItem("filledSurveys");
    if (filledSurveys === null) {
      return false;
    } else {
      filledSurveys = JSON.parse(filledSurveys);
    }
    const survey = filledSurveys.find(
      (s) => s.surveyId === surveyId && branch === s.branch
    );
    if (survey !== undefined) {
      if (new Date(survey.lastFilled).getDate() === new Date().getDate()) {
        return true;
      }
    }
    return false;
  } catch (e) {
    console.log(e);
    console.log("Error occured. Clearing localStorage");
    localStorage.removeItem("filledSurveys");
    return false;
  }
}
export function markFilledTime(surveyId, branch) {
  try {
    let filledSurveys = localStorage.getItem("filledSurveys");
    if (filledSurveys === null) {
      filledSurveys = [];
    } else {
      filledSurveys = JSON.parse(filledSurveys);
    }

    let survey = filledSurveys.find(
      (s) => s.surveyId === surveyId && s.branch === branch
    );
    if (survey === undefined) {
      filledSurveys.push({ surveyId, branch, lastFilled: Date() });
    } else {
      filledSurveys = filledSurveys.map((s) => {
        if (s.surveyId === surveyId && s.branch === branch) {
          s.lastFilled = String(Date());
        }
        return s;
      });
    }
    localStorage.setItem("filledSurveys", JSON.stringify(filledSurveys));
  } catch (e) {
    console.log(e);
    console.log("Error occured. Clearing localStorage");

    localStorage.removeItem("filledSurveys");
  }
}
