import { Box } from "@mui/material";
import React, { useState } from "react";
import { fullStar, yellowStarIcon } from "../../../Assets";

let stars = ["1", "2", "3", "4", "5"];
export default function Rating({ selected }) {
  const [Value, setValue] = useState("");
  return (
    <Box
      display={"flex"}
      gap={2}
      style={{
        maxWidth: "100%",
        overflowY: "auto",
        marginTop: "26px",
      }}
    >
      {stars.map((star) => (
        <img
          src={Value >= star ? fullStar : yellowStarIcon}
          alt=""
          height={"40px"}
          onClick={() => {
            setValue(star);
            selected(star);
          }}
        />
      ))}
    </Box>
  );
}
