import { Box } from "@mui/material";
import React, { useState } from "react";
import {
  happySelectedSmiley,
  happySmiley,
  normalSelectedSmiley,
  normalSmiley,
  sadSelectedSmiley,
  sadSmiley,
  veryHappySelectedSmiley,
  veryHappySmiley,
  verySadSelectedSmiley,
  verySadSmiley,
} from "../../../Assets";

export const smileys = [
  {
    value: "very sad",
    image: verySadSmiley,
    selectedImage: verySadSelectedSmiley,
  },
  { value: "sad", image: sadSmiley, selectedImage: sadSelectedSmiley },
  { value: "normal", image: normalSmiley, selectedImage: normalSelectedSmiley },
  { value: "happy", image: happySmiley, selectedImage: happySelectedSmiley },
  {
    value: "very happy",
    image: veryHappySmiley,
    selectedImage: veryHappySelectedSmiley,
  },
];
export default function Smiley({ selected }) {
  const [Value, setValue] = useState("");
  return (
    <Box
      marginTop="24px"
      display={"flex"}
      alignItems={"start"}
      gap={{ xs: 2, md: 3 }}
    >
      {smileys.map((smiley) => (
        <img
          src={Value === smiley.value ? smiley.selectedImage : smiley.image}
          alt={smiley.value}
          onClick={() => {
            setValue(smiley.value);
            selected(smiley.value);
          }}
          className="singlePageSmiley"
          style={{
            backgroundColor: Value === smiley.value ? "#3A63F3" : "#FFF",
          }}
        />
      ))}
    </Box>
  );
}
