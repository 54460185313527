import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { updateSurvey } from "../../Api";

function TitleEdit({ id, surveyTitle }) {
  const [title, setTitle] = useState(surveyTitle);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (timeoutId != null) {
      clearTimeout(timeoutId);
    }
    const tID = setTimeout(saveLatestTitle, 1000);
    setTimeoutId(tID);
  }, [title]);

  const saveLatestTitle = () => {
    updateSurvey(id, { title: title }).catch((err) => {
      console.log(err);
      if (err.response.status === 403) {
        toast.error(err.response.data.message, {
          autoClose: 10000,
        });
      } else {
        toast.error("Error while saving title!");
      }
    });
  };

  useEffect(() => {}, [title]);
  return (
    <Box display={{ xs: "none", md: "flex" }}>
      <form>
        <input
          type="text"
          style={{
            // color: "#FFF",
            backgroundColor: "inherit",
            border: "none",
            width: "fit-content",
            outline: "none",
            fontSize: "20px",
          }}
          value={title}
          placeholder="Enter survey title"
          onChange={(e) => setTitle(e.target.value)}
        />
      </form>
    </Box>
  );
}
export default TitleEdit;
