import React, { Fragment, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { fetchSurveyById } from "../../../Api";
import { QuestionNavbar } from "../../../Shared";

let survey;
function SurveyId() {
  const id = useParams().id;
  const [Loading, setLoading] = useState(true);
  useEffect(() => {
    fetchSurveyById(id).then((res) => {
      survey = res.data;
      console.log(survey);
      setLoading(false);
    });
  }, [id]);

  return (
    !Loading && (
      <Fragment>
        <QuestionNavbar
          id={id}
          // page="build"
          surveyTitle={survey?.title}
          // open={() => setMobileOpen(!mobileOpen)}
        />
        <Outlet context={survey} />
      </Fragment>
    )
  );
}

export default SurveyId;
