const defaultStyles = {
  header: "headerText",
  headerText: {
    fontFamily: "Roboto",
    fontWeight: "600",
    fontSize: "2rem",
    textAlign: "center",
    color: "#000000",
  },
  headerLogo: {
    backgroundColor: "tomato",
    image: "",
    width: "100px",
  },
  description: {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "1.1rem",
    textAlign: "center",
    color: "#000000",
  },
  buttonText: {
    fontFamily: "Roboto",
    fontWeight: "600",
    fontSize: "1rem",
    textAlign: "center",
    color: "#000000",
  },
  button: {
    variant: "outlined",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  background: "color",
  bgcolor: {
    backgroundColor: "#f0f0f0",
  },
  bgimage: {
    filter: "blur(0px)",
    backgroundSize: "cover",
    opacity: "100%",
  },
};
export default defaultStyles;
