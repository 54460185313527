import { Box, Button, Divider, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import QuestionPreviewModal from "../Modals/QuestionPreviewModal";

export default function IntroductionPage(props) {
  const [OpenPreviewModal, setOpenPreviewModal] = useState(false);
  const [L1Question, setL1Question] = useState(props?.introduction || "");
  const [L1Description, setL1Description] = useState("");

  useEffect(() => {}, [props]);

  let changeWelcomeMessage = (e) => {
    setL1Question(e.target.value);
    props.updateSurvey({ introduction: e.target.value });
  };

  let changeDescription = (e) => {
    setL1Description(e.target.value);
    props.updateQuestion({ l1_description: e.target.value });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <QuestionPreviewModal
        type="introduction"
        logo={props.logo}
        open={OpenPreviewModal}
        template={props?.template}
        close={() => setOpenPreviewModal(false)}
        title={L1Question}
      />
      <div className="question-field-section">
        <Box
          display={"flex"}
          justifyContent="space-between"
          alignItems={"center"}
          minWidth="20%"
          style={{ paddingTop: "20px" }}
          position="static"
        >
          <Box
            sx={{ display: { xs: "none", sm: "block" }, width: "12%" }}
          ></Box>
          <Box>
            <Button
              variant="contained"
              size="small"
              className="save-button"
              sx={{
                fontWeight: "700",
                marginRight: "24px",
                background: "#CCC",
                color: "#000",
                borderRadius: "2.5px",
              }}
              onClick={() => props.show(null)}
            >
              Save
            </Button>
            <Button
              variant="contained"
              size="small"
              className="preview-button"
              sx={{
                fontWeight: "700",
                background: "#FFC700",
                color: "#000",
                borderRadius: "2.5px",
              }}
              onClick={() => setOpenPreviewModal(true)}
            >
              Preview
            </Button>
          </Box>
          <Box
            sx={{ display: { xs: "none", sm: "block" }, width: "12%" }}
          ></Box>
        </Box>
        <Box
          width={"100%"}
          display="flex"
          justifyContent={"center"}
          alignItems="center"
          flexDirection={"column"}
          style={{ paddingTop: "64px" }}
        ></Box>
        <Box>
          <TextField
            fullWidth
            value={L1Question}
            style={{ paddingTop: "24px" }}
            variant="standard"
            onChange={changeWelcomeMessage}
            placeholder={`Add your welcome message`}
          />
          <Divider
            style={{
              margin: "0",
              borderBottom: "1px dashed #000",
            }}
          />
          {/* <TextField
                        placeholder={`Add description to your welcome message`}
                        variant='standard'
                        value={L1Description}
                        fullWidth
                        onChange={changeDescription} /> */}
        </Box>
      </div>
    </div>
  );
}
