import React, { useState } from "react";
import {
  Box,
  FormGroup,
  FormLabel,
  ButtonGroup,
  ToggleButton,
} from "@mui/material";
import { motion } from "framer-motion";

export default function LanguageForm(props) {
  const [Value, setValue] = useState("");

  let handleChange = (e) => {
    setValue(e.target.value);
    props.selectLanguage(e.target.value);
  };

  return (
    <Box
      display="flex"
      height={"100%"}
      paddingTop={"24px"}
      paddingBottom={"24px"}
      flexDirection={"column"}
      justifyContent="center"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <Box>
          <FormGroup
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "16px",
              marginBottom: "108px",
            }}
          >
            <FormLabel
              style={{
                display: "flex",
                marginBottom: "8px",
                // color: '#FFF',
                fontSize: "1.5rem",
                fontWeight: "600",
              }}
              className={`label bold`}
            >
              Select language
            </FormLabel>
            <ButtonGroup
              style={{ display: "block", marginTop: "32px" }}
              className={`normal mb-2`}
            >
              <Box>
                <ToggleButton
                  id={`radio-1`}
                  value={"first"}
                  type="radio"
                  className="radio-label"
                  style={{
                    color: "#FFF",
                    background: Value === "first" ? "#49CE56" : "#136572",
                    borderRadius: "5px",
                    marginBottom: "12px",
                  }}
                  onChange={handleChange}
                >
                  {props?.availableLanguage[0]}
                </ToggleButton>
                <ToggleButton
                  id={`radio-2`}
                  value={"second"}
                  type="radio"
                  className="radio-label"
                  style={{
                    color: "#FFF",
                    background: Value === "second" ? "#49CE56" : "#136572",
                    borderRadius: "5px",
                    marginBottom: "12px",
                  }}
                  onChange={handleChange}
                >
                  {props?.availableLanguage[1]}
                </ToggleButton>
              </Box>
            </ButtonGroup>
          </FormGroup>
        </Box>
      </motion.div>
    </Box>
  );
}
