import { Avatar, Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  closeIcon,
  filledDislikeIcon,
  filledLikeIcon,
  sidebarOpenIcon,
} from "../../../../Assets";
import { isArrayEqual } from "../../../../helpers/helpers";
import { Capitalise, Rating } from "../../../../Shared";
import { smileys } from "../../../TakeSurvey/SinglePageView/Smiley";

const ratingArray = ["5", "4", "3", "2", "1"];
const opinionScaleArray = ["10", "9", "8", "7", "6", "5", "4", "3", "2", "1"];
export default function ResponseSidebar({
  availableFilters,
  filteredResponse,
  setfilteredResponse,
  responses,
  choices,
  selectedFilters,
  setSelectedFilters,
}) {
  const isFilterEnabled = (type, answer) => {
    if (type === "multiple select") {
      return (
        selectedFilters.find(
          (f) => f.type === type && f.answer.includes(answer)
        ) !== undefined
      );
    }
    return (
      selectedFilters.find((f) => f.type === type && f.answer === answer) !==
      undefined
    );
  };
  const handleFilterMSResponse = (type, answer, questionId) => {
    const existingFilter = selectedFilters.find(
      (f) => f.questionId === questionId
    );
    if (existingFilter) {
      const newFilters = selectedFilters.filter(
        (f) => f.questionId !== questionId
      );
      if (existingFilter.answer.includes(answer)) {
        existingFilter.answer = existingFilter.answer.filter(
          (a) => a !== answer
        );
        if (existingFilter.answer.length === 0) {
          setSelectedFilters([...newFilters]);
          return;
        }
      } else {
        existingFilter.answer.push(answer);
      }
      setSelectedFilters([...newFilters, existingFilter]);
    } else {
      setSelectedFilters([
        ...selectedFilters,
        { type, answer: [answer], questionId },
      ]);
    }
  };
  let filterResponse = (type, answer, questionId) => {
    if (type === "multiple select") {
      if (questionId === undefined) {
        toast.error("Error occured please report to dev");
        console.log("questionId not provided for multiple select filter");
        return;
      }
      handleFilterMSResponse(type, answer, questionId);
      return;
    }
    if (selectedFilters.find((f) => f.type === type && f.answer === answer)) {
      setSelectedFilters(selectedFilters.filter((f) => f.type !== type));
    } else {
      let newFilters = selectedFilters;
      if (selectedFilters.find((f) => f.type === type)) {
        newFilters = selectedFilters.filter((f) => f.type !== type);
      }
      setSelectedFilters([...newFilters, { type, answer }]);
    }
  };

  let getNoOfResponses = (type, answer) => {
    let count = 0;
    for (let response of filteredResponse) {
      response.data?.forEach((item) => {
        if (item.type === type) {
          if (type === "multiple select") {
            if (item.answer[0].includes(answer)) {
              count = count + 1;
            }
          } else if (item.answer[0] === answer) {
            count = count + 1;
          }
        }
      });
    }

    return count;
  };

  let closeSidebar = () => {
    document.getElementById("resultresponsepagewrapper").style.width = "98%";
    document.getElementById("sidebar").style.display = "none";
    document.getElementById("sidebar-icon").style.display = "inline-block";
  };
  let openSidebar = () => {
    document.getElementById("resultresponsepagewrapper").style.width = "85%";
    document.getElementById("sidebar").style.display = "block";
    document.getElementById("sidebar-icon").style.display = "none";
  };

  return (
    <React.Fragment>
      <Box id="sidebar-icon">
        <img src={sidebarOpenIcon} alt="open sidebar" onClick={openSidebar} />
      </Box>
      <Stack
        className="response-sidebar"
        id="sidebar"
        sx={{
          backgroundColor: "rgba(203, 217, 228, 0.4)",
          minWidth: "175px",
        }}
      >
        <Box width={"100%"} display="flex" justifyContent="end">
          <img
            src={closeIcon}
            height="14px"
            alt="close"
            onClick={closeSidebar}
            style={{ cursor: "pointer" }}
          />
        </Box>
        <Box
          sx={{
            overflow: "auto",
            maxHeight: "93%",
          }}
        >
          {availableFilters.map((filter, index) => (
            <Box className="sidebar-rating" key={index}>
              {filter.type === "rating" && (
                <React.Fragment>
                  {/* <Box display={'flex'} justifyContent='space-between' alignItems={'center'}> */}
                  <Typography
                    variant="subtitle1"
                    style={{ marginBottom: "6px" }}
                  >
                    Rating
                  </Typography>
                  {/* <img src={downArrowIcon} height='6px' /> */}
                  {/* </Box> */}
                  {ratingArray.map((rating, i) => (
                    <Box
                      key={i}
                      display="flex"
                      onClick={() => filterResponse("rating", rating)}
                      style={{
                        backgroundColor: isFilterEnabled("rating", rating)
                          ? " rgba(225, 225, 225, 0.8)"
                          : "",
                      }}
                    >
                      {" "}
                      <Rating height={"16px"} number={Number(rating)} />
                      &nbsp;({getNoOfResponses("rating", rating)})
                    </Box>
                  ))}
                </React.Fragment>
              )}
              {filter.type === "smiley" && (
                <React.Fragment>
                  <Typography
                    variant="subtitle1"
                    style={{ marginBottom: "6px" }}
                  >
                    Smiley
                  </Typography>
                  <Stack direction={"row"} justifyContent={"space-evenly"}>
                    {smileys.map((smiley) => (
                      <Stack
                        onClick={() => {
                          filterResponse("smiley", smiley.value);
                        }}
                        sx={{
                          backgroundColor: isFilterEnabled(
                            "smiley",
                            smiley.value
                          )
                            ? " rgba(225, 225, 225, 0.8)"
                            : "",
                          p: "3px",
                          borderRadius: "5px",
                        }}
                        key={smiley.value}
                      >
                        <Avatar
                          src={smiley.image}
                          alt={smiley.value}
                          sx={{
                            width: "22px",
                            height: "22px",
                          }}
                        />
                        <Typography
                          textAlign={"center"}
                          fontSize="12px"
                          mt="5px"
                        >
                          ({getNoOfResponses("smiley", smiley.value)})
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                </React.Fragment>
              )}
              {filter.type === "like or dislike" && (
                <React.Fragment>
                  <Typography
                    variant="subtitle1"
                    style={{ marginBottom: "6px" }}
                  >
                    Like or Dislike
                  </Typography>
                  <Grid
                    container
                    spacing={3}
                    style={{ margin: "0", padding: "0", width: "100%" }}
                  >
                    {["like", "dislike"].map((answer, i) => (
                      <Grid
                        key={`ans-${i}`}
                        item
                        xs={6}
                        display="flex"
                        onClick={() =>
                          filterResponse("like or dislike", answer)
                        }
                        style={{
                          padding: "7px 4px",
                          justifyContent: "center",
                          borderRadius: "8px",
                          fontSize: "12px",
                          backgroundColor: isFilterEnabled(
                            "like or dislike",
                            answer
                          )
                            ? " rgba(225, 225, 225, 0.8)"
                            : "",
                        }}
                      >
                        {" "}
                        {answer === "like" ? (
                          <img src={filledLikeIcon} height="16px" alt="like" />
                        ) : (
                          <img
                            src={filledDislikeIcon}
                            height="16px"
                            alt="dislike"
                          />
                        )}
                        &nbsp;({getNoOfResponses("like or dislike", answer)})
                      </Grid>
                    ))}
                  </Grid>
                </React.Fragment>
              )}
              {filter.type === "yes or no" && (
                <React.Fragment>
                  <Typography
                    variant="subtitle1"
                    style={{ marginBottom: "6px" }}
                  >
                    Yes or No
                  </Typography>
                  <Grid
                    container
                    spacing={3}
                    style={{ margin: "0", padding: "0", width: "100%" }}
                  >
                    {["yes", "no"].map((answer, i) => (
                      <Grid
                        key={`ans-${i}`}
                        item
                        xs={6}
                        display="flex"
                        onClick={() => filterResponse("yes or no", answer)}
                        style={{
                          padding: "7px 4px",
                          justifyContent: "center",
                          borderRadius: "8px",
                          fontSize: "12px",
                          backgroundColor: isFilterEnabled("yes or no", answer)
                            ? " rgba(225, 225, 225, 0.8)"
                            : "",
                        }}
                      >
                        {" "}
                        {Capitalise(answer)} &nbsp;(
                        {getNoOfResponses("yes or no", answer)})
                      </Grid>
                    ))}
                  </Grid>
                </React.Fragment>
              )}
              {filter.type === "opinion scale" && (
                <React.Fragment>
                  <Typography
                    variant="subtitle1"
                    style={{ marginBottom: "6px" }}
                  >
                    Opinion scale
                  </Typography>
                  <Grid
                    container
                    spacing={3}
                    style={{ margin: "0", padding: "0", width: "100%" }}
                  >
                    {opinionScaleArray.map((opinion, i) => (
                      <Grid
                        key={`ans-${i}`}
                        item
                        xs={6}
                        display="flex"
                        onClick={() => filterResponse("opinion scale", opinion)}
                        style={{
                          padding: "7px 4px",
                          justifyContent: "center",
                          borderRadius: "8px",
                          fontSize: "12px",
                          backgroundColor: isFilterEnabled(
                            "opinion scale",
                            opinion
                          )
                            ? " rgba(225, 225, 225, 0.8)"
                            : "",
                        }}
                      >
                        {" "}
                        {opinion}&nbsp;(
                        {getNoOfResponses("opinion scale", opinion)})
                      </Grid>
                    ))}
                  </Grid>
                </React.Fragment>
              )}
              {filter.type === "multiple choice" && (
                <React.Fragment>
                  <Typography
                    variant="subtitle1"
                    style={{ marginBottom: "6px" }}
                  >
                    Multiple choice
                  </Typography>
                  {filter.options.map((choice, i) => (
                    <Box
                      key={`choice-${i}`}
                      display="flex"
                      onClick={() => filterResponse("multiple choice", choice)}
                      style={{
                        backgroundColor: isFilterEnabled(
                          "multiple choice",
                          choice
                        )
                          ? " rgba(225, 225, 225, 0.8)"
                          : "",
                      }}
                    >
                      {" "}
                      {Capitalise(choice)}&nbsp;(
                      {getNoOfResponses("multiple choice", choice)})
                    </Box>
                  ))}
                </React.Fragment>
              )}
              {filter.type === "multiple select" && (
                <React.Fragment>
                  <Typography
                    variant="subtitle1"
                    style={{ marginBottom: "6px" }}
                  >
                    Multiple select
                  </Typography>
                  {filter.options.map((choice, i) => (
                    <Stack
                      p={0}
                      pl={1}
                      direction="row"
                      justifyContent={"space-between"}
                      key={`choice-${i}`}
                      onClick={() =>
                        filterResponse(
                          "multiple select",
                          choice,
                          filter.questionId
                        )
                      }
                      style={{
                        backgroundColor: isFilterEnabled(
                          "multiple select",
                          choice
                        )
                          ? " rgba(225, 225, 225, 0.8)"
                          : "",
                      }}
                    >
                      <Box mt="auto" mb="auto">
                        {" "}
                        {Capitalise(choice)}&nbsp;(
                        {getNoOfResponses("multiple select", choice)})
                      </Box>
                      <Checkbox
                        checked={isFilterEnabled("multiple select", choice)}
                        onChange={() => {}}
                      />
                    </Stack>
                  ))}
                </React.Fragment>
              )}
            </Box>
          ))}

          <Box className="sidebar-opinion"></Box>
          <Box className="sidebar-opinion"></Box>
          <Box className="sidebar-rating"></Box>
        </Box>

        <Button fullWidth onClick={() => setSelectedFilters([])}>
          Clear all filters
        </Button>
      </Stack>
    </React.Fragment>
  );
}
