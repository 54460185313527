import { MenuItem, Select, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { flagofIndia } from "../../../Assets";

let countryCode = [{ name: "IN", code: "+91", flag: flagofIndia }];

export default function Text({ type, selected }) {
  const [Value, setValue] = useState("");
  const [select, setSelect] = useState("+91");

  return (
    <Stack direction="row" spacing={0.1} width="100%">
      {type === "phone number" && (
        <Select
          value={select}
          className="select-action singlepageSurveySelect"
          onChange={(e) => setSelect(e.target.value)}
          sx={{
            display: "flex",
            border: 0,
            borderRadius: 0,
            borderBottom: "2px solid #3A63F3",
            fontSize: "15px",
            fontWeight: "120px",
            height: { md: "60px", xs: "50px" },
          }}
        >
          {countryCode.map((ccode, i) => (
            <MenuItem key={i} value={ccode.code}>
              <img src={ccode.flag} alt="flag" width={"25px"} />
              &nbsp; {ccode.name}
            </MenuItem>
          ))}
        </Select>
      )}
      <TextField
        type={type}
        fullWidth
        size="small"
        variant="standard"
        onChange={(e) => setValue(e.target.value)}
        onBlur={() =>
          type === "phone number" ? selected(select + Value) : selected(Value)
        }
        sx={{
          marginTop: "24px",
          display: "flex",
          justifyContent: "center",
          borderBottom: "2px solid #3A63F3",
        }}
        autoComplete="nope"
        className="text"
      />
    </Stack>
  );
}
