import { Box, Button, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { MdAddAPhoto } from "react-icons/md";
import Webcam from "react-webcam";

export default function PhotoCapture({ selected }) {
  const [OpenWebcam, setOpenWebcam] = useState(false);
  const [image, setImage] = useState(null);

  return !OpenWebcam && !image ? (
    <Fragment>
      <Box className="photocapture" onClick={() => setOpenWebcam(true)}>
        <MdAddAPhoto color="#3A63F3" size={50} />
        <Typography
          fontWeight={"500"}
          fontSize="1.5rem"
          style={{ color: "#979797" }}
        >
          Take a Photo
        </Typography>
      </Box>
    </Fragment>
  ) : !OpenWebcam && image ? (
    <Box
      display="flex"
      flexDirection={"column"}
      justifyContent="space-around"
      style={{ width: "100%" }}
    >
      <img
        src={image}
        alt="img"
        style={{ height: "200px", width: "200px" }}
        className="photocapture"
      />

      <Button
        className="nextPreviousButton"
        onClick={() => {
          setImage(null);
          setOpenWebcam(true);
        }}
        style={{ width: "200px" }}
      >
        <span className="nextPreviousButtonText">Retake</span>
      </Button>
    </Box>
  ) : OpenWebcam ? (
    <Box width={"100%"} display="flex" flexDirection={"column"}>
      <Webcam
        mirrored
        screenshotFormat="image/jpeg"
        style={{
          width: "300px",
          height: "300px",
        }}
      >
        {({ getScreenshot }) => (
          <Button
            className="medium"
            style={{ width: "300px" }}
            onClick={() => {
              let temp = getScreenshot();
              setImage(temp);
              setOpenWebcam(false);
              selected(temp);
            }}
          >
            Capture photo
          </Button>
        )}
      </Webcam>
    </Box>
  ) : (
    ""
  );
}
