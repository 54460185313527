import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import YesOrNoOptionCard from "../QuestionOptionCards/YesOrNoOptionCard";
import QuestionPreviewModal from "../Modals/QuestionPreviewModal";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import QuestionField from "../QuestionField/QuestionField";

export default function YesOrNoQuestion(props) {
  const [OpenPreviewModal, setOpenPreviewModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("first");
  const [Required, setRequired] = useState(props.question?.required || false);
  const [logic, setLogic] = useState([]);

  let changeRequired = (value) => {
    setRequired(value);
    props.updateQuestion({ required: value });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: "auto",
      }}
    >
      <QuestionPreviewModal
        type="yes or no"
        logo={props.logo}
        open={OpenPreviewModal}
        template={props?.template}
        close={() => setOpenPreviewModal(false)}
        title={
          selectedLanguage === "first"
            ? props.question.l1_title
            : props.question.l2_title
        }
        description={
          selectedLanguage === "first"
            ? props.question.l1_description
            : props.question.l2_description
        }
      />
      <div className="question-field-section">
        <Box className="savePreviewButtonWrapper">
          <Box
            sx={{ display: { xs: "none", sm: "block" }, width: "12%" }}
          ></Box>
          <Box>
            <Button
              variant="contained"
              size="small"
              className="save-button"
              sx={{
                fontWeight: "700",
                marginRight: "24px",
                background: "#CCC",
                color: "#000",
                borderRadius: "2.5px",
              }}
              onClick={() =>
                props.updateQuestion(
                  {
                    required: Required,
                    logic: logic,
                  },
                  "save"
                )
              }
            >
              Save
            </Button>
            <Button
              variant="contained"
              size="small"
              className="preview-button"
              sx={{
                fontWeight: "700",
                background: "#FFC700",
                color: "#000",
                borderRadius: "2.5px",
              }}
              onClick={() => setOpenPreviewModal(true)}
            >
              Preview
            </Button>
          </Box>
          {props.language.length === 2 ? (
            <LanguageToggle
              language={props.language || []}
              setSelectedLanguage={setSelectedLanguage}
            />
          ) : (
            <Box
              sx={{ display: { xs: "none", sm: "block" }, width: "12%" }}
            ></Box>
          )}
        </Box>
        <QuestionField
          question={props.question}
          handleQuestionChange={props.handleQuestionChange}
          updateQuestion={props.updateQuestion}
          selectedLanguage={selectedLanguage}
        />
        <Box
          width={"100%"}
          justifyContent="center"
          display="flex"
          marginTop={"26px"}
          gap={2}
        >
          <Box
            bgcolor="rgba(58, 99, 243, 0.05)"
            borderRadius="2.5px"
            padding="18px 29px"
          >
            <Typography fontWeight={"500"}>Yes</Typography>
          </Box>
          <Box
            bgcolor="rgba(58, 99, 243, 0.05)"
            borderRadius="2.5px"
            padding="18px 29px"
          >
            <Typography fontWeight={"500"}>No</Typography>
          </Box>
        </Box>
      </div>
      <YesOrNoOptionCard
        id={props.questionId}
        surveyType={props.surveyType}
        updateSurvey={(value) => props.updateSurvey(value)}
        updateQuestion={(value) => setLogic(value)}
        type="Yes or No"
        isRequired={Required}
        required={(value) => changeRequired(value)}
        addNextQuestion={() => props.addNextQuestion()}
        totalQuestions={props.totalQuestions}
        changeQuestionType={() => props.changeQuestionType()}
        questionLogic={props.question.logic}
      />
    </div>
  );
}
