import {
  Box,
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  deleteBranch,
  getBranchesByAccount,
  getAccountUsersByRole,
} from "../../../Api";
import { deleteIcon } from "../../../Assets";
import { Capitalise } from "../../../Shared";
import AddBranchModal from "../../../Shared/Modals/AddBranchModal";

let users = [];
export default function BranchesSettingsPage() {
  const [branches, setBranches] = useState([]);
  const [ShowAddBranchModal, setShowAddBranchModal] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    getBranches();
  }, []);

  useEffect(() => {
    getAccountUsersByRole("Admin").then((res) => {
      users = res.data;
    });
  }, []);

  let getBranches = () => {
    if (localStorage.role === "Account Owner")
      getBranchesByAccount()
        .then((res) => {
          setBranches(res.data);
        })
        .catch((e) => console.log(e));
  };

  let add = (res) => {
    setBranches([...branches, res.data]);
  };

  let deletebranch = (branch) => {
    if (
      users.filter((item) => item.branch === branch._id)[0]?.branch ===
      branch._id
    ) {
      setShowPopUp(true);
    } else {
      deleteBranch(branch._id)
        .then((res) => {
          setBranches(branches.filter((b) => b._id !== branch._id));
        })
        .catch((err) => {
          toast.error("Could not delete branch. Please try again");
        });
    }
  };

  return (
    <Fragment>
      <AddBranchModal
        open={ShowAddBranchModal}
        close={() => setShowAddBranchModal(false)}
        add={add}
        currentBranches={branches.map((b) => b.name.toLowerCase())}
      />
      <Box className="generalSettingsPageWrapper">
        <Card
          sx={{
            height: "97%",
            margin: "10px auto",
            width: "98%",
            border: "1px solid rgba(0, 0, 0, 0.15)",
            borderRadius: "8px",
            boxShadow: "none",
          }}
        >
          <CardContent style={{ paddingBottom: "0" }}>
            <Box display="flex" justifyContent={"space-between"}>
              <Typography variant="h5" fontWeight={"700"} marginBottom={"12px"}>
                {" "}
                Branches
              </Typography>
              <Button
                variant="contained"
                onClick={() => setShowAddBranchModal(true)}
                disabled={localStorage.role === "Admin"}
                style={{
                  borderRadius: "8px",
                  textTransform: "none",
                  fontWeight: "600",
                }}
              >
                Add Branch
              </Button>
            </Box>
            <div>
              <Dialog open={showPopUp}>
                <DialogTitle>
                  This branch is assigned to a user. <br />
                  Please delete user first.
                </DialogTitle>
                <DialogActions>
                  <Button onClick={() => setShowPopUp(false)}>OK</Button>
                </DialogActions>
              </Dialog>
            </div>
            <TableContainer
              sx={{
                border: "none",
                borderRadius: "0px",
                boxShadow: "none",
                display: "flex",
                marginTop: "24px",
                maxHeight: "75vh",
                justifyContent: "center",
              }}
            >
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ minWidth: 650 }}
              >
                <TableHead>
                  <TableRow sx={{ "td,th": { border: 0 } }}>
                    <TableCell style={{ fontWeight: "700", color: "#3F3D56" }}>
                      Name
                    </TableCell>
                    <TableCell style={{ fontWeight: "700", color: "#3F3D56" }}>
                      Code
                    </TableCell>
                    <TableCell
                      style={{ fontWeight: "700", color: "#3F3D56" }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {branches.length !== 0 ? (
                    branches?.map((branch, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          "td, th": {
                            border: 0,
                            background: "rgba(73, 79, 125, 0.07)",
                          },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ color: "#A8A8A8" }}
                        >
                          {Capitalise(branch?.name)}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ color: "#A8A8A8" }}
                        >
                          {Capitalise(branch?.code)}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: "#A8A8A8" }}
                          onClick={() => deletebranch(branch)}
                        >
                          <img
                            src={deleteIcon}
                            alt="delete"
                            style={{ cursor: "pointer" }}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow
                      sx={{
                        "td, th": {
                          border: 0,
                          background: "rgba(73, 79, 125, 0.07)",
                        },
                      }}
                    >
                      <TableCell colSpan={5} style={{ padding: "30px 0" }}>
                        <Typography align="center" style={{ color: "#ADADAD" }}>
                          No branches added
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    </Fragment>
  );
}
