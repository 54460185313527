import { Box, Button } from "@mui/material";
import leftarrow from "../../../Assets/leftarrow.png";
import rightarrow from "../../../Assets/rightarrow.png";
import "./NextPrevious.css";

function NextPrevious({ disabled, prev, totalQuestions, count, required }) {
  return (
    <Box
      display="flex"
      justifyContent="end"
      gap={3}
      sx={{
        position: "absolute",
        bottom: { lg: "30px", xs: "50px" },
        right: "0",
      }}
    >
      {count !== 1 && (
        <Button
          className="nextPreviousButton"
          disabled={disabled}
          onClick={prev}
        >
          <img src={leftarrow} alt="" className="nextPreviousButtonImage" />
          <span className="nextPreviousButtonText">Prev</span>
        </Button>
      )}
      <Button type="submit" className="nextPreviousButton" disabled={disabled}>
        <span className="nextPreviousButtonText">
          {count === totalQuestions ? "Submit" : "Next"}
        </span>
        <img src={rightarrow} alt="" className="nextPreviousButtonImage" />
      </Button>
    </Box>
  );
}

export default NextPrevious;
