import React from "react";
import { LineWave } from "react-loader-spinner";
import "./LoadingScreen.css";

function LoadingScreen() {
  return (
    <div className="loadingScreenWrapper">
      <LineWave
        height="250"
        width="100"
        ariaLabel="line-wave"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        firstLineColor="grey"
        middleLineColor="#04"
        lastLineColor="grey"
      />
    </div>
  );
}

export default LoadingScreen;
