import { Box, Button } from "@mui/material";
import { AddAPhoto } from "@mui/icons-material";
import React, { useState } from "react";
import PhotoCaptureOptionCard from "../QuestionOptionCards/PhotoCaptureOptionCard";
import QuestionPreviewModal from "../Modals/QuestionPreviewModal";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import QuestionField from "../QuestionField/QuestionField";

export default function PhotoCaptureQuestion(props) {
  const [OpenPreviewModal, setOpenPreviewModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("first");
  const [Required, setRequired] = useState(props.question?.required || false);

  let changeRequired = (value) => {
    setRequired(value);
    props.updateQuestion({ required: value });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: "auto",
      }}
    >
      <QuestionPreviewModal
        logo={props.logo}
        type="photo capture"
        open={OpenPreviewModal}
        close={() => setOpenPreviewModal(false)}
        template={props?.template}
        title={
          selectedLanguage === "first"
            ? props.question.l1_title
            : props.question.l2_title
        }
        description={
          selectedLanguage === "first"
            ? props.question.l1_description
            : props.question.l2_description
        }
      />
      <div className="question-field-section">
        <Box className="savePreviewButtonWrapper">
          <Box
            sx={{ display: { xs: "none", sm: "block" }, width: "12%" }}
          ></Box>
          <Box>
            <Button
              size="small"
              variant="contained"
              className="save-button"
              onClick={() =>
                props.updateQuestion(
                  {
                    required: Required,
                  },
                  "save"
                )
              }
              sx={{
                color: "#000",
                boxShadow: "none",
                fontWeight: "700",
                background: "#CCC",
                marginRight: "24px",
                borderRadius: "2.5px",
              }}
            >
              Save
            </Button>
            <Button
              size="small"
              variant="contained"
              className="preview-button"
              onClick={() => setOpenPreviewModal(true)}
              sx={{
                color: "#000",
                boxShadow: "none",
                fontWeight: "700",
                borderRadius: "2.5px",
                background: "#FFC700",
              }}
            >
              Preview
            </Button>
          </Box>
          {props.language.length === 2 ? (
            <LanguageToggle
              language={props.language}
              setSelectedLanguage={setSelectedLanguage}
            />
          ) : (
            <Box
              sx={{ display: { xs: "none", sm: "block" }, width: "12%" }}
            ></Box>
          )}
        </Box>
        <QuestionField
          question={props.question}
          handleQuestionChange={props.handleQuestionChange}
          updateQuestion={props.updateQuestion}
          selectedLanguage={selectedLanguage}
        />
      </div>
      <Box
        width="100%"
        display={"flex"}
        justifyContent="center"
        marginTop={"22px"}
        marginBottom={"22px"}
      >
        <Box
          width={{ xs: "60%", lg: "120px" }}
          height={{ xs: "100px", lg: "120px" }}
          display="flex"
          justifyContent={"center"}
          alignItems="center"
          sx={{ backgroundColor: "#A8A8A8", borderRadius: "24px" }}
        >
          <AddAPhoto color="primary" size={24} />
        </Box>
      </Box>
      {/* </div> */}
      <PhotoCaptureOptionCard
        isRequired={Required}
        id={props.questionId}
        required={(value) => changeRequired(value)}
        changeQuestionType={() => props.changeQuestionType()}
        addNextQuestion={() => props.addNextQuestion()}
      />
    </div>
  );
}
