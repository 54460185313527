import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Slider,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  changeQuestionType,
  happySmiley,
  normalSmiley,
  sadSmiley,
  veryHappySmiley,
  verySadSmiley,
} from "../../Assets";
import Logiccard from "./Logiccard";
export const SMILEYS = [
  verySadSmiley,
  sadSmiley,
  normalSmiley,
  happySmiley,
  veryHappySmiley,
];
export const smileyResponses = [
  "very sad",
  "sad",
  "normal",
  "happy",
  "very happy",
];
export const defaultSmileyValues = [
  "Very Sad",
  "Sad",
  "Normal",
  "Happy",
  "Very Happy",
];

let selectedQuestions = [];
export default function SmileyOptionCard(props) {
  const [ShowGoogleReview, setShowGoogleReview] = useState(
    props?.survey?.enable_google_review || false
  );
  const [ReviewLink, setReviewLink] = useState(
    props.survey?.google_review_link || ""
  );
  const [MinReviewRange, setMinReviewRange] = useState(
    props.survey?.min_review_range || 4
  );

  const marks = [
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];
  const [showOptions, setShowOptions] = useState(true);
  const [showLogic, setShowLogic] = useState(false);
  const handleSelectChange = (value, type, length) => {
    for (let i = 0; i < length; i++) {
      if (i === type) {
        selectedQuestions[type] = value;
      } else if (!selectedQuestions[i]) {
        selectedQuestions[i] = "empty";
      }
    }
    props.updateQuestion(selectedQuestions);
  };

  return (
    <Card className="optionCardWrapper">
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
        }}
      >
        <Box display={{ xs: "block", sm: "flex" }} gap={3}>
          <img
            src={changeQuestionType}
            alt="change type"
            style={{ cursor: "pointer" }}
            onClick={() => props.changeQuestionType()}
          />
          <Typography
            onClick={(e) => {
              setShowOptions(true);
              setShowLogic(false);
            }}
          >
            {" "}
            Options
          </Typography>
          <Typography
            onClick={(e) => {
              setShowLogic(true);
              setShowOptions(false);
            }}
          >
            Logic{" "}
          </Typography>
        </Box>
        <Box display={"flex"}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => props.required(e.target.checked)}
                checked={props.isRequired}
              />
            }
            label="Required"
          />
          <Button
            variant="contained"
            className="addNextQuestionButton"
            onClick={() => props.addNextQuestion()}
          >
            Add Next Question
          </Button>
        </Box>
      </CardActions>
      <CardContent
        style={{
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          gap: "20px",
          overflowY: "auto",
        }}
      >
        {showOptions && (
          <>
            <SmileyLabelEditor
              smileyLabels={props.smileyLabels}
              setSmileyLabels={props.setSmileyLabels}
            />
            <Box gap={2} display={"flex"} marginTop="30px">
              <FormGroup>
                <FormLabel>Google review</FormLabel>
                <FormControlLabel
                  control={
                    <Switch
                      checked={ShowGoogleReview}
                      disabled={localStorage.planId === "0"}
                      onChange={(e) => {
                        setShowGoogleReview(e.target.checked);
                        props.updateSurvey({
                          enable_google_review: e.target.checked,
                        });
                      }}
                    />
                  }
                />
              </FormGroup>
              <FormGroup
                style={{ display: ShowGoogleReview ? "flex" : "none" }}
              >
                <FormLabel>Google review range</FormLabel>
                <Slider
                  max={5}
                  min={3}
                  size="small"
                  marks={marks}
                  track="inverted"
                  // getAriaValueText={ valuetext}
                  defaultValue={MinReviewRange}
                  aria-labelledby="track-inverted-slider"
                />
              </FormGroup>
            </Box>
            <Box
              width="100%"
              display={{ xs: "block", sm: "flex" }}
              marginTop="12px"
              justifyContent={"space-between"}
            >
              <div>
                <TextField
                  size="small"
                  label="Paste your google review link"
                  value={ReviewLink}
                  onChange={(e) => {
                    setReviewLink(e.target.value);
                    props.updateSurvey({
                      enable_google_review: ShowGoogleReview,
                      min_review_range: MinReviewRange,
                      google_review_link: e.target.value,
                    });
                  }}
                  style={{
                    width: "250px",
                    display: ShowGoogleReview ? "flex" : "none",
                  }}
                />
              </div>
            </Box>
          </>
        )}
        {showLogic && props.surveyType !== "single page" && (
          <Logiccard
            handleSelectChange={handleSelectChange}
            totalQuestions={props.totalQuestions}
            type={props.type}
            questionLogic={props.questionLogic}
          />
        )}
        <Typography className="optionCardQuestionId">
          Question ID:&nbsp;{props.id}
        </Typography>
      </CardContent>
    </Card>
  );
}

function SmileyLabelEditor({ smileyLabels, setSmileyLabels }) {
  return (
    <Stack direction="row" spacing={2}>
      {SMILEYS.map((smiley, index) => (
        <Stack justifyContent={"center"} alignItems={"center"} spacing={1}>
          <img width={"30px"} height={"30px"} src={smiley} alt="smiley" />
          <TextField
            size="small"
            value={
              smileyLabels
                ? smileyLabels[index]
                  ? smileyLabels[index]
                  : defaultSmileyValues[index]
                : defaultSmileyValues[index]
            }
            onChange={(e) => {
              let temp = [...smileyLabels];
              temp[index] = e.target.value;
              setSmileyLabels(temp);
            }}
            style={{
              maxWidth: "157px",
              borderRadius: "2.5px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          />
        </Stack>
      ))}
    </Stack>
  );
}
