import { Box, TableContainer, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import Custombutton from "../../../Shared/customButton/Custombutton";
import Requesttable from "./Requesttable/Requesttable";
import {
  getallAccounts,
  changeAccountStatus,
  adminChangeAccountStatus,
  adminGetAllRequests,
} from "../../../Api";
import { toast } from "react-toastify";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";

function Request({ reload, setReload }) {
  const [tableSelect, setTableSelect] = useState("pending");
  const [tableBody, setTableBody] = useState([]);
  const [loading, setLoading] = useState(true);
  const pendingHeader = [
    "Name",
    "Email",
    "Phone Number",
    "Role",
    "Location",
    "Comments",
    "Approve/Deny",
  ];
  const approvedHeader = [
    "Name",
    "Email",
    "Phone Number",
    "Role",
    "Location",
    "Comments",
    "Deny",
  ];
  const rejectedHeader = [
    "Name",
    "Email",
    "Phone Number",
    "Role",
    "Location",
    "Comments",
    "Approve",
  ];
  function changeAccStatus(id, status) {
    adminChangeAccountStatus(id, status).then((res) => {
      toast.success(res.data.data);
    });
    setReload(!reload);
  }
  useEffect(() => {
    setLoading(true);
    adminGetAllRequests().then((response) => {
      setTableBody(response.data.accounts);
      setLoading(false);
    });
  }, [reload]);
  if (loading) {
    return <LoadingScreen />;
  } else {
    return (
      <Box style={{ height: "100%", paddingTop: "20px" }}>
        <TableContainer
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "20px",
            height: "100%",
          }}
        >
          <Stack direction="row" style={{ alignSelf: "flex-end" }}>
            <Custombutton
              content="Pending"
              color={tableSelect === "pending" ? "#060a32" : "gray"}
              onCLick={() => setTableSelect("pending")}
            />
            <Custombutton
              content="Approved"
              color={tableSelect === "approved" ? "#060a32" : "gray"}
              onCLick={() => setTableSelect("approved")}
            />
            <Custombutton
              content="Rejected"
              color={tableSelect === "rejected" ? "#060a32" : "gray"}
              onCLick={() => setTableSelect("rejected")}
            />
          </Stack>
          {tableSelect === "pending" && (
            <Requesttable
              type={tableSelect}
              headerArray={pendingHeader}
              bodyArray={tableBody.filter(
                (tb) => tb.account_status === "pending"
              )}
              changeAccountStatus={changeAccStatus}
            />
          )}
          {tableSelect === "approved" && (
            <Requesttable
              type={tableSelect}
              headerArray={approvedHeader}
              bodyArray={tableBody.filter(
                (tb) => tb.account_status === "approved"
              )}
              changeAccountStatus={changeAccStatus}
            />
          )}
          {tableSelect === "rejected" && (
            <Requesttable
              type={tableSelect}
              headerArray={rejectedHeader}
              bodyArray={tableBody.filter(
                (tb) => tb.account_status === "rejected"
              )}
              changeAccountStatus={changeAccStatus}
            />
          )}
        </TableContainer>
      </Box>
    );
  }
}

export default Request;
