import React, { Fragment, useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Button, Card } from "@mui/material";
import { useLocation } from "react-router-dom";
import { TbLayoutSidebarLeftExpand } from "react-icons/tb";
import SelectOptionSection from "./SelectOptionSection";
import DrawerSection from "./DrawerSection";
import {
  updateSurvey,
  addSurveyQuestion,
  deleteQuestionById,
  fetchSurveyQuestions,
  updateSurveyQuestion,
  deleteFeedbackByQuestionId,
  updateAllQuestionBySurvey,
} from "../../../Api";
import {
  TextQuestion,
  EmailQuestion,
  RatingQuestion,
  PhoneNumberQuestion,
  PhotoCaptureQuestion,
  OtpValidationQuestion,
  MultipleChoiceQuestion,
  LikeOrDislike,
  YesOrNoQuestion,
  ThankYouPage,
  SmileyQuestion,
  OpinionScaleQuestion,
  IntroductionPage,
  TestimonialQuestion,
} from "../../../Shared";
import MultipleSelectQuestion from "../../../Shared/QuestionTypes/MultipleSelect";

let survey, questionId, quantitative_question;
const sortQ = (questions) => {
  const copy = [...questions].sort((a, b) => a.order - b.order);
  return copy;
};
const blankQ = {
  l1_title: "",
  l1_options: [],
  l1_description: "",
  l2_title: "",
  logic: [],
  l2_options: [],
  l2_description: "",
};

export default function BuildSurveyPage() {
  survey = useOutletContext();
  const id = useParams().id;
  const { state } = useLocation();
  const [questions, _setQuestions] = useState([]);
  const [thankYouExists, setThankYouExists] = useState(false);
  const [Show, setShow] = useState(null);
  const [Reload, setReload] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [Disabled, setDisabled] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(true);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [pendingEdit, setPendingEdit] = useState({
    question: selectedQuestion,
    timeoutId: null,
  });

  let addQuestion = (type, title, order, options, count) => {
    setDisabled(true);
    setShow(type);
    quantitative_question =
      type === "rating" ||
      type === "smiley" ||
      type === "like or dislike" ||
      type === "yes or no" ||
      type === "opinion scale"
        ? true
        : false;
    let left, right;
    if (type === "smiley") {
      left = "very sad";
      right = "very happy";
    }
    let newOrder;
    if (thankYouExists) {
      newOrder = questions.length;
      const [thankQ] = questions.filter((q) => q.type === "thank you");
      updateSurveyQuestion(thankQ._id, { ...thankQ, order: newOrder + 1 }).then(
        (res) => {
          updateQuestionsState(thankQ._id, res.data);
        }
      );
    } else {
      newOrder = order || questions.length + 1;
    }

    addSurveyQuestion(
      {
        type: type,
        quantitative_question: quantitative_question,
        left_label: left || null,
        right_label: right || null,
        order: newOrder,
        l1_title: title || "",
        l1_description: "",
        l2_title: "",
        l2_description: "",
        l1_options: "" || options,
      },
      id
    )
      .then((res) => {
        const newQuestions = [...questions];
        newQuestions.push(res.data);
        setQuestions(newQuestions);
        questionId = res.data._id;
        setSelectedQuestion(res.data);
        if (count) {
          updateSurvey(id, { questions: count });
        } else {
          updateSurvey(id, { questions: newQuestions.length });
        }
        setDisabled(false);
        setReload(!Reload);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          toast.error(
            "No edit permission. Contact super admin to get edit access.",
            {
              autoClose: 10000,
            }
          );
        } else {
          toast.error("Something went wrong. Please try again.");
          console.log(err);
        }
      });
  };

  useEffect(() => {
    fetchSurveyQuestions(id).then((res) => {
      setQuestions(res.data);
      setShow(null);
      setLoading(false);
    });
  }, [id, Loading]);
  useEffect(() => {
    if (state !== null) {
      let count = 0;
      Promise.all(
        state.questions.map(async (question, i) => {
          count++;
          return addQuestion(
            question.type,
            question.l1_title,
            i + 1,
            question.l1_options,
            count
          ).then((res) => _setQuestions((item) => [...item, res.data]));
        })
      ).then(() => {
        window.history.replaceState({}, document.title);
      });
    }
  }, []);

  useEffect(() => {
    const index = questions.findIndex((q) => q.type === "thank you");

    if (index === -1) {
      setThankYouExists(false);
    } else {
      setThankYouExists(true);
    }
    if (selectedQuestion !== null) {
      const updatedQuestion = questions.find(
        (q) => selectedQuestion._id === q._id
      );
      if (updatedQuestion) {
        setSelectedQuestion({ ...updatedQuestion });
      }
    }
  }, [questions]);

  useEffect(() => {
    if (!selectedQuestion) {
      setPendingEdit({ question: null, timeoutId: null });
    } else if (!pendingEdit.question) {
      setPendingEdit({ question: selectedQuestion._id, timeoutId: null });
    } else if (selectedQuestion._id !== pendingEdit.question) {
      setPendingEdit({ question: selectedQuestion._id, timeoutId: null });
    }
  }, [selectedQuestion, pendingEdit.question]);

  const setQuestions = (questions) => {
    questions = sortQ(questions);
    const thankQIndex = questions.findIndex((q) => q.type === "thank you");
    if (thankQIndex !== -1) {
      if (thankQIndex !== questions.length - 1) {
        const [thankQ] = questions.splice(thankQIndex, 1);
        const updatedQuestions = [...questions, thankQ];
        updatedQuestions.map((q, i) => (q.order = i + 1));
        _setQuestions(updatedQuestions);
        return;
      }
    }
    _setQuestions(sortQ([...questions]));
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  let clickAction = async (action) => {
    setSelectedQuestion(null);
    questionId = null;
    if (action === "add") setShow("type");
    else if (action === "introduction") setShow("introduction");
    setMobileOpen(false);
  };

  let updatesurvey = (updatedValue) => {
    updateSurvey(id, updatedValue).then((res) => {
      survey = res.data;
      setReload(!Reload);
    });
  };

  let changeQuestionType = (type) => {
    quantitative_question =
      type === "rating" ||
      type === "smiley" ||
      type === "like or dislike" ||
      type === "yes or no" ||
      type === "opinion scale"
        ? true
        : false;
    Promise.all([
      updateSurveyQuestion(selectedQuestion._id, {
        type: type,
        quantitative_question: quantitative_question,
      }),
      deleteFeedbackByQuestionId(survey._id, selectedQuestion._id),
    ]).then((res) => {
      setSelectedQuestion({ ...selectedQuestion, type: type });
      setShow(type);
    });
  };

  const updateQuestionsState = (qID, updatedQuestion) => {
    _setQuestions((questions) => {
      let editedQuestionPosition = questions.findIndex(
        (quest) => quest._id === qID
      );
      const newQuestion = {
        ...questions[editedQuestionPosition],
        ...updatedQuestion,
      };
      const updatedQuestions = [...questions];
      updatedQuestions.splice(editedQuestionPosition, 1, newQuestion);
      return updatedQuestions;
    });
  };

  let updateQuestion = async (question, action) => {
    const currentQuestionId = questionId;
    if (
      pendingEdit.timeoutId != null &&
      pendingEdit.question === currentQuestionId
    ) {
      clearTimeout(pendingEdit.timeoutId);
      setPendingEdit({ timeoutId: null });
    }
    if (action === "save") {
      setSelectedQuestion(null);
      questionId = null;
      setShow(null);
      setMobileOpen(true);
      const updatedQuestion = {
        ...selectedQuestion,
        ...question,
      };
      await updateSurveyQuestion(currentQuestionId, updatedQuestion).then(
        (res) => {
          updateQuestionsState(currentQuestionId, updatedQuestion);
        }
      );
      return;
    }

    updateQuestionsState(currentQuestionId, {
      ...selectedQuestion,
      ...question,
    });
    const updatedQuestion = {
      ...selectedQuestion,
      ...question,
    };
    const tID = setTimeout(async () => {
      await updateSurveyQuestion(currentQuestionId, updatedQuestion).then(
        (res) => {
          updateQuestionsState(currentQuestionId, updatedQuestion);
        }
      );
    }, 1500);
    setPendingEdit({ ...pendingEdit, timeoutId: tID });
  };

  let addNextQuestion = async () => {
    //await updateQuestion(question).then((res) => {});
    setSelectedQuestion(null);
    questionId = null;
    setShow("type");
  };

  let deleteQuestion = (questionId) => {
    deleteQuestionById(questionId, survey._id)
      .then((res) => {
        if (res.status === 201) {
          let deletedIndex = questions.findIndex(
            (question) => question._id === questionId
          );

          let remainingquestions = questions.filter(
            (question) => question._id !== questionId
          );

          let newOrder = deletedIndex;
          remainingquestions.map((quest, i) => {
            if (i >= deletedIndex) {
              newOrder = newOrder + 1;
              return (quest.order = newOrder);
            }
            return quest.order;
          });
          Promise.all([
            updateAllQuestionBySurvey(id, remainingquestions),
            updateSurvey(id, { questions: remainingquestions.length }),
          ]);
          setQuestions(remainingquestions);
          setSelectedQuestion("");
          questionId = "";
          setShow(null);
          setDisabled(true);
          setReload(!Reload);
        }
      })
      .catch((err) => {
        let message;
        try {
          message = err.response.data.message;
        } catch (e) {}
        toast.error(`An error occured.\n ${message}`);
      });
  };

  let editQuestion = (question) => {
    setSelectedQuestion(question);
    questionId = question._id;
    setShow(question.type);
    setMobileOpen(false);
    setReload(!Reload);
  };

  let updateAllQuestions = (questionsToUpdate) => {
    updateAllQuestionBySurvey(id, questionsToUpdate)
      .then((res) => {
        setQuestions(res.data);
      })
      .catch((err) => {
        toast.error(
          "Could not reorder the question, Please refresh the page and try again"
        );
      });
  };

  return (
    <Box
      display={"flex"}
      flex={"1 1 auto"}
      padding={{ sm: "20px 23px", xs: "0" }}
    >
      <Button
        className="QuestionDrawerButtonWrapper"
        onClick={() => setMobileOpen(true)}
        sx={{ display: { xs: mobileOpen ? "none" : "inherit", sm: "none" } }}
      >
        <TbLayoutSidebarLeftExpand />
      </Button>
      {!Loading ? (
        <Fragment>
          <DrawerSection
            open={mobileOpen}
            questions={questions}
            setQuestions={setQuestions}
            thankYouExists={thankYouExists}
            selectedQuestion={selectedQuestion}
            clicked={(action) => clickAction(action)}
            edit={editQuestion}
            delete={(questionId) => deleteQuestion(questionId)}
            updateAllQuestions={(questionsToUpdate) =>
              updateAllQuestions(questionsToUpdate)
            }
          />

          <Card
            alignItems="center"
            sx={{
              p: { xs: "0", sm: "3" },
              flexGrow: 1,
              width: "100%",
              paddingTop: "0px",
              bgcolor: "background.default",
              display: mobileOpen ? "none" : "inherit",
            }}
          >
            {Show === "introduction" ? (
              <IntroductionPage
                updateSurvey={(value) => updatesurvey(value)}
                show={() => setShow(null)}
                introduction={survey.introduction || null}
              />
            ) : Show === "type" ? (
              <SelectOptionSection
                show={
                  !selectedQuestion
                    ? (type) => addQuestion(type)
                    : (type) => changeQuestionType(type)
                }
                thankYouExists={thankYouExists}
                surveyType={survey?.type}
              />
            ) : Show === "text" ? (
              <TextQuestion
                disabled={Disabled}
                questionId={questionId}
                logo={survey?.logo || null}
                language={survey?.language}
                template={survey?.template}
                question={selectedQuestion || blankQ}
                handleQuestionChange={setSelectedQuestion}
                updateQuestion={updateQuestion}
                addNextQuestion={addNextQuestion}
                changeQuestionType={() => setShow("type")}
              />
            ) : Show === "otp" ? (
              <OtpValidationQuestion
                disabled={Disabled}
                questionId={questionId}
                logo={survey.logo || null}
                language={survey?.language}
                template={survey?.template}
                question={selectedQuestion || blankQ}
                handleQuestionChange={setSelectedQuestion}
                updateQuestion={updateQuestion}
                addNextQuestion={addNextQuestion}
                changeQuestionType={() => setShow("type")}
              />
            ) : Show === "multiple choice" ? (
              <MultipleChoiceQuestion
                surveyType={survey?.type || "multiple page"}
                disabled={Disabled}
                questionId={questionId}
                logo={survey.logo || null}
                totalQuestions={questions}
                language={survey?.language}
                template={survey?.template}
                question={selectedQuestion || blankQ}
                handleQuestionChange={setSelectedQuestion}
                updateQuestion={updateQuestion}
                addNextQuestion={addNextQuestion}
                changeQuestionType={() => setShow("type")}
              />
            ) : Show === "multiple select" ? (
              <MultipleSelectQuestion
                surveyType={survey?.type || "multiple page"}
                disabled={Disabled}
                questionId={questionId}
                logo={survey.logo || null}
                totalQuestions={questions}
                language={survey?.language}
                template={survey?.template}
                question={selectedQuestion || blankQ}
                handleQuestionChange={setSelectedQuestion}
                updateQuestion={updateQuestion}
                addNextQuestion={addNextQuestion}
                changeQuestionType={() => setShow("type")}
              />
            ) : Show === "rating" ? (
              <RatingQuestion
                surveyType={survey?.type || "multiple page"}
                survey={survey}
                disabled={Disabled}
                questionId={questionId}
                logo={survey.logo || null}
                template={survey?.template}
                language={survey?.language}
                question={selectedQuestion || blankQ}
                handleQuestionChange={setSelectedQuestion}
                totalQuestions={questions}
                updateQuestion={updateQuestion}
                addNextQuestion={addNextQuestion}
                changeQuestionType={() => setShow("type")}
                updateSurvey={(survey) => updatesurvey(survey)}
              />
            ) : Show === "opinion scale" ? (
              <OpinionScaleQuestion
                surveyType={survey?.type || "multiple page"}
                disabled={Disabled}
                questionId={questionId}
                logo={survey.logo || null}
                language={survey?.language}
                template={survey?.template}
                question={selectedQuestion || blankQ}
                handleQuestionChange={setSelectedQuestion}
                totalQuestions={questions}
                updateQuestion={updateQuestion}
                addNextQuestion={addNextQuestion}
                changeQuestionType={() => setShow("type")}
              />
            ) : Show === "photo capture" ? (
              <PhotoCaptureQuestion
                disabled={Disabled}
                questionId={questionId}
                language={survey?.language}
                template={survey?.template}
                question={selectedQuestion || blankQ}
                handleQuestionChange={setSelectedQuestion}
                updateQuestion={updateQuestion}
                addNextQuestion={addNextQuestion}
                changeQuestionType={() => setShow("type")}
              />
            ) : Show === "email" ? (
              <EmailQuestion
                disabled={Disabled}
                questionId={questionId}
                logo={survey.logo || null}
                template={survey?.template}
                language={survey?.language}
                question={selectedQuestion || blankQ}
                handleQuestionChange={setSelectedQuestion}
                updateQuestion={updateQuestion}
                addNextQuestion={addNextQuestion}
                changeQuestionType={() => setShow("type")}
              />
            ) : Show === "phone number" ? (
              <PhoneNumberQuestion
                disabled={Disabled}
                questionId={questionId}
                logo={survey.logo || null}
                template={survey?.template}
                language={survey?.language}
                question={selectedQuestion || blankQ}
                handleQuestionChange={setSelectedQuestion}
                updateQuestion={updateQuestion}
                addNextQuestion={addNextQuestion}
                changeQuestionType={() => setShow("type")}
              />
            ) : Show === "smiley" ? (
              <SmileyQuestion
                surveyType={survey?.type || "multiple page"}
                survey={survey}
                disabled={Disabled}
                questionId={questionId}
                logo={survey.logo || null}
                template={survey?.template}
                language={survey?.language}
                question={selectedQuestion || blankQ}
                handleQuestionChange={setSelectedQuestion}
                totalQuestions={questions}
                updateQuestion={updateQuestion}
                addNextQuestion={addNextQuestion}
                changeQuestionType={() => setShow("type")}
                updateSurvey={(survey) => updatesurvey(survey)}
              />
            ) : Show === "like or dislike" ? (
              <LikeOrDislike
                surveyType={survey?.type || "multiple page"}
                disabled={Disabled}
                questionId={questionId}
                logo={survey.logo || null}
                language={survey?.language}
                template={survey?.template}
                question={selectedQuestion || blankQ}
                handleQuestionChange={setSelectedQuestion}
                totalQuestions={questions}
                updateQuestion={updateQuestion}
                addNextQuestion={addNextQuestion}
                changeQuestionType={() => setShow("type")}
              />
            ) : Show === "yes or no" ? (
              <YesOrNoQuestion
                surveyType={survey?.type || "multiple page"}
                disabled={Disabled}
                questionId={questionId}
                logo={survey.logo || null}
                language={survey?.language}
                template={survey?.template}
                question={selectedQuestion || blankQ}
                handleQuestionChange={setSelectedQuestion}
                totalQuestions={questions}
                updateQuestion={updateQuestion}
                addNextQuestion={addNextQuestion}
                changeQuestionType={() => setShow("type")}
              />
            ) : Show === "testimonial" ? (
              <TestimonialQuestion
                disabled={Disabled}
                questionId={questionId}
                logo={survey.logo || null}
                language={survey?.language}
                template={survey?.template}
                question={selectedQuestion || blankQ}
                handleQuestionChange={setSelectedQuestion}
                updateQuestion={updateQuestion}
                addNextQuestion={addNextQuestion}
                changeQuestionType={() => setShow("type")}
              />
            ) : Show === "thank you" ? (
              <ThankYouPage
                questionId={questionId}
                logo={survey.logo || null}
                language={survey?.language}
                template={survey?.template}
                question={selectedQuestion || blankQ}
                handleQuestionChange={setSelectedQuestion}
                thankYouExists={thankYouExists}
                updateQuestion={updateQuestion}
              />
            ) : (
              <div></div>
            )}
          </Card>
        </Fragment>
      ) : (
        ""
      )}
    </Box>
  );
}
