import {
  Box,
  FormLabel,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Capitalise from "../Capitalise/Capitalise";
import { Stack } from "@mui/system";
import { flagofIndia } from "../../Assets";

let countryCode = [{ name: "IN", code: "+91", flag: flagofIndia }];
// prevValue;
export default function PhoneNumberForm(props) {
  const [select, setSelect] = useState("+91");

  // prevValue = props.initialValue;
  // let selectedCountryCode = countryCode.filter((ccode) =>
  //   props.initialValue?.includes(ccode.code)
  // )[0];
  // if (props.initialValue && selectedCountryCode) {
  //   prevValue = prevValue.slice(selectedCountryCode.code.length);
  // }

  useEffect(() => {}, [props]);

  const handleSelect = (e) => {
    setSelect(e.target.value);
  };
  return (
    <Box
      width="100%"
      paddingBottom="20px"
      flexDirection={"column"}
      justifyContent="center"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{ height: "100%" }}
      >
        <FormLabel
          required={props.required}
          sx={{
            display: "flex",
            marginBottom: "8px",
            color: "#3F3D56",
            fontSize: !props.hideButton
              ? {
                  md: props.label.length > 80 ? "1rem" : "1.5rem",
                  xs: props.label.length > 80 ? "0.75" : "1.25rem",
                }
              : {
                  md: props.label.length > 80 ? "0.9rem" : "1.1rem",
                  xs: props.label.length > 80 ? "0.75" : "1.1rem",
                },
            fontWeight: !props.hideButton ? "750" : "600",
            maxWidth: "100%",
          }}
          className="details-label bold"
        >
          {Capitalise(props.label)}
        </FormLabel>
        {props.description && (
          <Typography style={{ color: "grey", fontSize: "18px" }}>
            {props.description}
          </Typography>
        )}
        <Box
          width="100%"
          display={"flex"}
          justifyContent="center"
          marginTop={"22px"}
        >
          <Stack direction="row" spacing={0.1} width="100%">
            <Select
              value={select}
              className="select-action"
              disabled={props.disabled}
              onChange={handleSelect}
              sx={{
                display: "flex",
                borderRadius: "8px",
                border: "1px solid rgba(0,0,0,0.15)",
                fontSize: "15px",
                fontWeight: "120px",
                height: { md: !props.hideButton ? "60px" : "50px", xs: "50px" },
              }}
            >
              {countryCode.map((ccode, i) => (
                <MenuItem key={i} value={ccode.code}>
                  <img src={ccode.flag} alt="flag" width={"25px"} />
                  &nbsp; {ccode.name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              type={"tel"}
              size="small"
              name="Value"
              disabled={props.disabled}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values?.Value}
              style={{ width: "100%" }}
              sx={{
                height: { xs: "50px", md: !props.hideButton ? "60px" : "50px" },
                fontFamily: "sans-serif",
                border: "2px solid rgba(0,0,0,0.15)",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                marginTop: "24px",
              }}
              placeholder="Enter the phone number"
              autoComplete="nope"
              className="details-input normal"
            />
          </Stack>
        </Box>
      </motion.div>
    </Box>
  );
}
