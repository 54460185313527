import { Box, Typography, Button, Divider, selectClasses } from "@mui/material";
import leftarrow from "../../Assets/leftarrow.png";
import rightarrow from "../../Assets/rightarrow.png";
import Capitalise from "../Capitalise/Capitalise";
import {
  veryHappySmiley,
  happySmiley,
  normalSmiley,
  sadSmiley,
  verySadSmiley,
} from "../../Assets";
import {
  defaultSmileyValues,
  smileyResponses,
} from "../QuestionOptionCards/SmileyOptionCard";

function FormPreview({
  questions,
  responses,
  language,
  handlePrev,
  handleSubmit,
  imageWidth,
  title,
  qSize,
  gap,
}) {
  let questionAndAnswers = [];
  let answer = null;
  for (const q of questions) {
    answer = responses?.find((f) => f.questionId === q._id);
    if (!answer) {
      continue;
    }
    questionAndAnswers.push([
      language === "first" ? q.l1_title : q.l2_title,
      answer.answer,
      q.type,
      q.smileyLabels,
    ]);
  }
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflowY: "auto",
        marginTop: "50px",
      }}
    >
      <Typography
        sx={{
          color: "#000",
          textAlign: "center",
          fontWeight: "900",
          fontSize: {
            md: "1.4rem",
            xs: "1.2rem",
          },
        }}
        variant="h4"
      >
        {title || "Your Responses"}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: gap ? gap[0] : "20px",
        }}
      >
        {questionAndAnswers.map(
          ([q, [answer, image, userName], type, smileyLabels], index) => {
            return (
              <Box
                m={2}
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: gap ? gap[1] : "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#3F3D56",
                    fontSize: {
                      md: qSize || "1.3rem",
                      xs: qSize || "1.1rem",
                    },
                    fontWeight: "750",
                  }}
                  variant="h5"
                >
                  {Capitalise(q)}
                </Typography>
                <DisplayAnswer
                  smileyLabels={
                    smileyLabels ? smileyLabels : defaultSmileyValues
                  }
                  answer={answer}
                  userName={userName}
                  type={type}
                  image={image}
                  imageWidth={imageWidth}
                  sx={{
                    color: "gray",
                    fontSize: {
                      md: qSize || "1.2rem",
                      xs: qSize || "1rem",
                    },
                    fontWeight: "500",
                  }}
                />
                <Divider />
              </Box>
            );
          }
        )}
      </Box>
      {handlePrev && handleSubmit && (
        <Box display="flex" justifyContent="end" gap={3}>
          <Button className="nextPreviousButton" onClick={handlePrev}>
            <img src={leftarrow} alt="" className="nextPreviousButtonImage" />
            <span className="nextPreviousButtonText">Prev</span>
          </Button>

          <Button
            type="submit"
            className="nextPreviousButton"
            onClick={handleSubmit}
          >
            <span className="nextPreviousButtonText">Submit</span>
            <img src={rightarrow} alt="" className="nextPreviousButtonImage" />
          </Button>
        </Box>
      )}
    </Box>
  );
}

function DisplayAnswer({
  type,
  answer,
  image,
  sx,
  userName,
  imageWidth,
  smileyLabels,
}) {
  if (type === "photo capture") {
    return (
      <img
        src={answer}
        alt=""
        style={{
          minWidth: imageWidth || "200px",
          width: imageWidth || "20vw",
          outlineStyle: "dashed",
          outlineColor: "#3F3D56",
          padding: "5px",
          marginTop: "20px",
          borderRadius: "5px",
        }}
      />
    );
  } else if (type === "testimonial") {
    return (
      <>
        <Typography sx={sx}>{answer}</Typography>
        {image ? (
          <img
            src={image}
            style={{
              minWidth: imageWidth || "200px",
              width: imageWidth || "20vw",
              outlineStyle: "dashed",
              outlineColor: "#3F3D56",
              padding: "5px",
              marginTop: "20px",
            }}
          />
        ) : null}
        {userName ? <Typography sx={sx}>Name: {userName}</Typography> : null}
      </>
    );
  } else if (type === "multiple select") {
    return <Typography sx={sx}> {answer.join(", ")} </Typography>;
  } else if (type === "smiley") {
    if (!smileyLabels) smileyLabels = defaultSmileyValues;
    let selectedImage = undefined;
    if (answer === "very happy") selectedImage = veryHappySmiley;
    else if (answer === "happy") selectedImage = happySmiley;
    else if (answer === "normal") selectedImage = normalSmiley;
    else if (answer === "sad") selectedImage = sadSmiley;
    else if (answer === "very sad") selectedImage = verySadSmiley;
    return (
      <>
        {selectedImage ? (
          <img
            src={selectedImage}
            style={{
              width: "40px",
              height: "40px",
              padding: "5px",
              marginTop: "20px",
            }}
          />
        ) : null}
        <Typography sx={sx}>
          {smileyLabels[smileyResponses.findIndex((sRes) => sRes === answer)]}
        </Typography>
      </>
    );
  } else {
    return <Typography sx={sx}>{answer}</Typography>;
  }
}

export default FormPreview;
