import {
  Button,
  Card,
  TextField,
  Typography,
  Box,
  Grid,
  FormGroup,
  FormLabel,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { MdOutlineAddBox } from "react-icons/md";
import { PhotoshopPicker } from "react-color";
import { toast } from "react-toastify";
import html2canvas from "html2canvas";
import QRCode from "react-qr-code";
import { CLIENT_URL, getBranchesByAccount } from "../../../../Api";
import { Capitalise, SmsModal } from "../../../../Shared";
import "./ShareSurveyPage.css";
import {
  copyIcon,
  emailBlueIcon,
  facebookIcon,
  smsIcon,
  whatsappIcon,
  embedIcon,
} from "../../../../Assets";
import { AiFillDelete } from "react-icons/ai";

let survey,
  iframecode,
  branches = [];
let whatsapEncoded;
export default function ShareSurveyPage({ Title, setTitle }) {
  survey = useOutletContext();
  const id = useParams().id;
  const printRef = useRef();

  const [Description, setDescription] = useState(
    "Enter text to be displayed here in the description box given at left"
  );
  const [OpenColorPicker, setOpenColorPicker] = useState(false);
  const [SelectedBranch, setSelectedBranch] = useState("");
  const [ShowSmsModal, setShowSmsModal] = useState(false);
  const [BgColor, setBgColor] = useState("#060A32");
  const [BgImage, setBgImage] = useState(null);
  const [Loading, setLoading] = useState(true);
  const [Logo, setLogo] = useState(null);

  useEffect(() => {
    if (localStorage.role === "Admin") {
      generateUrl(localStorage.branch + "/" + id);
      setLoading(false);
    } else {
      getBranchesByAccount().then((res) => {
        branches = res.data;
        if (branches?.length === 0) generateUrl("owner/" + id);
        else generateUrl();
        setLoading(false);
      });
    }
    var myMessage = `Take minute to complete ${survey?.title} at ${survey.url}`;
    var messageToWhatsApp = encodeURIComponent(myMessage);
    whatsapEncoded = "whatsapp://send?text=" + messageToWhatsApp;
  }, [id, Loading]);

  const generateUrl = (url) => {
    if (url) survey.url = `${CLIENT_URL}/#/feedback/start/${url}`;
    else survey.url = "";
    iframecode = `<iframe src="${survey.url}" style="border:0px #ffffff none;" name="${survey.url}-iframe" scrolling="yes" frameborder="1" marginheight="0px" marginwidth="0px" height="600px" width="400px" allowfullscreen></iframe>`;
  };

  let copyUrl = async (e, url) => {
    if (window.isSecureContext && navigator.clipboard) {
      await navigator.clipboard
        .writeText(url)
        .then((res) => {
          if (e === "copy") {
            toast.success("Url copied successfully");
          } else {
            toast.success("Embed code copied successfully");
          }
        })
        .catch((err) => toast.error("Url not copied"));
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = url;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
        if (e === "copy") {
          toast.success("Url copied successfully");
        } else {
          toast.success("Embed code copied successfully");
        }
      } catch (err) {
        toast.error("Url not copied");
      }
      document.bodyNaNpxoveChild(textArea);
    }
  };

  let uploadBgImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file.size > 2000000) return alert("File size should be less than 2MB");
    reader.readAsDataURL(file);
    reader.addEventListener(
      "load",
      () => {
        setBgImage(reader.result);
      },
      false
    );
  };

  let uploadLogo = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.addEventListener("load", () => {
      setLogo(reader.result);
    });
  };

  const downloadQrCode = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element, { backgroundColor: null });

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.bodyNaNpxoveChild(link);
    } else {
      window.open(data);
    }
  };

  const selectBranch = (e) => {
    survey.url = `${CLIENT_URL}/#/feedback/start/${e.target.value}/${id}`;
    iframecode = `<iframe src="${survey.url}" style="border:0px #ffffff none;" name="${survey.url}-iframe" scrolling="yes" frameborder="1" marginheight="0px" marginwidth="0px" height="600px" width="400px" allowfullscreen></iframe>`;
    setSelectedBranch(e.target.value);
  };

  return (
    <Fragment>
      <SmsModal
        open={ShowSmsModal}
        close={() => setShowSmsModal(false)}
        url={`${CLIENT_URL}/#/feedback/start/${localStorage.location}/${id}`}
      />

      <Box
        display="flex"
        flexDirection="column"
        flex={"1 1 auto"}
        className="shareSurveyWrapper"
      >
        {!Loading &&
          (localStorage.role === "Admin" ||
          branches.length === 0 ||
          SelectedBranch ? (
            <Fragment>
              <Card className="shareSurveyUpperCardWrapper">
                <div className="linkWrapper">
                  <h3 style={{ fontWeight: "600", fontSize: "1.25rem" }}>
                    Survey Link
                  </h3>
                  <p style={{ marginTop: "16px" }}>
                    Share this link with anyone you want and start getting
                    responses to your survey.
                  </p>
                  <Box
                    width={{ xs: "100%" }}
                    display={"flex"}
                    justifyContent="flex-start"
                    marginTop="12px"
                  >
                    <TextField
                      className="urlTextField"
                      value={survey?.url}
                      disabled
                    />
                    <Button
                      variant="contained"
                      style={{
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                        height: "37px",
                        width: "25%",
                      }}
                      onClick={(e) => copyUrl("copy", survey?.url)}
                    >
                      Copy Url
                      <img
                        src={copyIcon}
                        alt=""
                        style={{ paddingLeft: ".75rem" }}
                      />
                    </Button>
                  </Box>
                </div>
                <Box className="shareWrapper">
                  <h4>How would you like to survey people?</h4>
                  <div
                    style={{ display: "flex", gap: "12px", marginTop: "16px" }}
                  >
                    <Card className="shareCard">
                      <a
                        href={`mailto:?subject=Survey&body=Take minute to complete ${survey?.title}\n${survey?.url}`}
                      >
                        <img
                          src={emailBlueIcon}
                          alt="email"
                          className="share-icons"
                        />
                      </a>
                    </Card>
                    <Card className="shareCard">
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${survey?.url}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={facebookIcon}
                          alt="facebook"
                          className="share-icons"
                        />
                      </a>
                    </Card>
                    <Card
                      className="shareCard"
                      onClick={() => setShowSmsModal(true)}
                    >
                      <img
                        style={{ cursor: "pointer" }}
                        src={smsIcon}
                        alt="sms"
                        className="share-icons"
                      />
                    </Card>
                    <Card className="shareCard">
                      <a
                        href={whatsapEncoded}
                        data-action="share/whatsapp/share"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={whatsappIcon}
                          alt="whatsapp"
                          className="share-icons"
                        />
                      </a>
                    </Card>
                  </div>
                </Box>
              </Card>

              <Grid
                container
                spacing={2}
                className="shareSurveyLowerCardWrapper"
              >
                <Grid item xs={12} lg={8} className="codeGeneratorWrapper">
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <UploadLogo
                      uploadLogo={uploadLogo}
                      Logo={Logo}
                      setLogo={setLogo}
                    />
                    <UploadBgColor
                      OpenColorPicker={OpenColorPicker}
                      setOpenColorPicker={setOpenColorPicker}
                      BgColor={BgColor}
                      setBgColor={setBgColor}
                      setBgImage={setBgImage}
                    />
                    <UploadBgImage
                      BgImage={BgImage}
                      uploadBgImage={uploadBgImage}
                      setBgImage={setBgImage}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-around",
                      minHeight: "200px",
                    }}
                  >
                    <Box width={"60%"}>
                      <FormGroup style={{ display: "-webkit-box" }}>
                        <Box width={"20%"}>
                          <FormLabel>Title</FormLabel>
                        </Box>
                        <TextField
                          size="small"
                          style={{ width: "80%" }}
                          value={Title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ display: "-webkit-box", marginTop: "16px" }}
                      >
                        <Box width={"20%"}>
                          <FormLabel>Description</FormLabel>
                        </Box>
                        <TextField
                          size="small"
                          style={{ width: "80%" }}
                          value={Description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </FormGroup>
                    </Box>

                    <Button
                      variant="contained"
                      style={{ height: "35px" }}
                      onClick={downloadQrCode}
                    >
                      Download QR Code
                    </Button>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  height="100%"
                  sx={{
                    padding: "10px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    ref={printRef}
                    borderRadius="20px"
                    display="flex"
                    margin="auto"
                    height={"90%"}
                    style={{
                      backgroundColor: BgColor,
                      backgroundImage: `url(${BgImage})`,
                    }}
                    padding="6%"
                    justifyContent={"end"}
                    sx={{
                      width: "350px",
                    }}
                  >
                    <Card className="QrCodeCard">
                      <Typography
                        align="center"
                        variant="h6"
                        fontWeight={"600"}
                        sx={{ color: "#FFFFFF" }}
                        style={{ wordBreak: "break-word" }}
                      >
                        {Title}
                      </Typography>
                      <Box
                        style={{
                          minHeight: "150px",
                          backgroundColor: "black",
                          border: "5px solid black",
                        }}
                      >
                        <QRCode
                          title="qrcode"
                          value={survey?.url}
                          fgColor="#FFF"
                          bgColor={"#000"}
                          size={150}
                        />
                      </Box>

                      <Typography
                        fontSize={"12px"}
                        align="center"
                        fontWeight={"500"}
                        sx={{ color: "#FFF" }}
                        style={{ wordBreak: "break-word" }}
                      >
                        {Description}
                      </Typography>
                      <Typography>
                        {Logo && (
                          <img
                            src={Logo}
                            alt="logo"
                            width="auto"
                            height="45rem"
                          />
                        )}
                      </Typography>
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            </Fragment>
          ) : (
            <Box
              sx={{
                flexGrow: 1,
                height: "100vh",
                bgcolor: "background.default",
                paddingLeft: "4rem",
                paddingRight: "1.5rem",
                paddingTop: "8.125rem",
              }}
              alignItems="center"
              flexDirection={"column"}
              display="flex"
            >
              {/* <Typography variant="subtitle1" style={{ color: "#CDCDCD" }}>
									Only Admin can see this page
								</Typography> */}
              <FormControl sx={{ width: "40%" }}>
                <InputLabel
                  style={{ color: "#ADADAD", fontSize: "1.3rem" }}
                  id="select-label"
                >
                  Select a branch to generate url and qr code
                </InputLabel>
                <Select
                  className="styled-select"
                  labelId="-select-label"
                  id="simple-select"
                  variant="standard"
                  // value={values.Branch}
                  defaultValue=""
                  name="Branch"
                  onChange={selectBranch}
                  // onBlur={handleBlur}
                >
                  {branches?.map((branch, i) => (
                    <MenuItem
                      value={branch._id}
                      key={i}
                      // disabled={isAssigned(branch)}
                    >
                      {Capitalise(branch.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ))}
      </Box>
    </Fragment>
  );
}

function UploadLogo({ uploadLogo, Logo, setLogo }) {
  return (
    <div className="customiseQRCodeWrapper">
      <div>
        <label
          role="button"
          style={{
            width: "100%",
            height: "100%",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input
            type="file"
            accept="image/*"
            className="form-control"
            style={{
              visibility: "hidden",
              position: "absolute",
            }}
            onChange={(e) => uploadLogo(e)}
          />
          {!Logo ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <MdOutlineAddBox size={"25px"} color="#3A63F3" />
              <p style={{ fontSize: "0.7rem" }}>Upload Logo</p>
            </div>
          ) : (
            <img src={Logo} alt="bgimage" width={"100%"} height="100%" />
          )}
        </label>
      </div>
      {Logo && (
        <div>
          <AiFillDelete color="red" size={20} onClick={() => setLogo(null)} />
        </div>
      )}
    </div>
  );
}

function UploadBgColor({
  OpenColorPicker,
  setOpenColorPicker,
  setBgColor,
  BgColor,
  setBgImage,
}) {
  return !OpenColorPicker ? (
    <div
      className="customiseQRCodeWrapper shareSurveyColorPickerContainer"
      style={{ background: BgColor }}
      onClick={() => {
        setOpenColorPicker(true);
        setBgImage(null);
      }}
    >
      <MdOutlineAddBox size={"25px"} color="#3A63F3" />
      <p style={{ color: "#FFFFFF", fontSize: "0.7rem" }}>Background Color</p>
    </div>
  ) : (
    <PhotoshopPicker
      onAccept={() => setOpenColorPicker(false)}
      onCancel={() => {
        setBgColor("#535461");
        setOpenColorPicker(false);
      }}
      color={BgColor}
      onChange={(color) => setBgColor(color.hex)}
      style={{
        position: "absolute",
        top: "0",
        left: "0",
      }}
    />
  );
}

function UploadBgImage({ BgImage, uploadBgImage, setBgImage }) {
  return (
    <div className="customiseQRCodeWrapper">
      <div>
        <label
          role="button"
          style={{
            width: "100%",
            height: "100%",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input
            type="file"
            accept="image/*"
            className="form-control"
            style={{
              visibility: "hidden",
              position: "absolute",
            }}
            onChange={(e) => uploadBgImage(e)}
          />
          {!BgImage ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <MdOutlineAddBox size={"25px"} color="#3A63F3" />
              <p style={{ fontSize: "0.7rem" }}>Background Image</p>
            </div>
          ) : (
            <img src={BgImage} alt="bgimage" width={"100%"} height="100%" />
          )}
        </label>
      </div>
      {BgImage && (
        <div>
          <AiFillDelete
            color="red"
            size={20}
            onClick={() => setBgImage(null)}
          />
        </div>
      )}
    </div>
  );
}
