import {
  Box,
  Button,
  Checkbox,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getContactsByAccount, getContactsByUser } from "../../Api";
import Capitalise from "../Capitalise/Capitalise";

let contacts = [],
  selected = [];
export default function SmsModal(props) {
  const [Reload, setReload] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [AllSelected, setAllSelected] = useState(false);
  const [TextMessage, setTextMessage] = useState(
    `Take a minute to complete the survey \n ${props?.url}`
  );

  useEffect(() => {
    if (localStorage.role === "Account Owner")
      getContactsByAccount().then((res) => {
        contacts = res.data;
        setLoading(false);
      });
    else
      getContactsByUser().then((res) => {
        contacts = res.data;
        setLoading(false);
      });
  }, []);

  let onSelectAllClick = (event) => {
    if (event.target.checked) {
      setAllSelected(true);
      contacts.map((contact) => {
        return selected.push(contact?.name);
      });
    } else {
      selected = [];
      setAllSelected(false);
    }
  };

  let selectSingleContact = (e, name) => {
    if (e.target.checked) {
      selected.push(name);
      if (contacts.length === selected.length) setAllSelected(true);
    } else {
      selected = selected.filter((item) => item !== name);
      setAllSelected(false);
    }
    setReload(!Reload);
  };

  let sendTextMessage = () => {
    if (selected.length === 0) toast.error("select atleast one contact");
  };

  let closeModal = () => {
    setAllSelected(false);
    selected = [];
    props.close();
  };

  return (
    <Modal
      open={props.open}
      onClose={closeModal}
      aria-describedby="sms-modal-description"
    >
      <Grid
        container
        spacing={4}
        className="basic-modal"
        id="sms-modal-description"
        sx={{ width: { xs: "81%", lg: "90%" } }}
      >
        <Grid item xs={6}>
          <Typography variant="h6" fontWeight={"700"}>
            Text Message
          </Typography>
          <TextField
            multiline
            rows={5}
            fullWidth
            style={{ marginTop: "45px" }}
            defaultValue={TextMessage}
          />
          <Box display="flex" justifyContent="end" paddingTop={"12px"}>
            <Button variant="contained" onClick={sendTextMessage}>
              Send
            </Button>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <TableContainer
            sx={{
              justifyContent: "center",
              border: 0,
              display: "flex",
              marginTop: "66px",
            }}
          >
            <Table
              sx={{ width: { xs: "100%" } }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow sx={{ border: "0" }}>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      // indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={AllSelected}
                      onChange={onSelectAllClick}
                      inputProps={{
                        "aria-label": "select all contacts",
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "1.125rem",
                      fontWeight: "600",
                      color: "#3F3D56",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "1.125rem",
                      fontWeight: "600",
                      color: "#3F3D56",
                    }}
                    align="center"
                  >
                    Mobile
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "1.125rem",
                      fontWeight: "600",
                      color: "#3F3D56",
                    }}
                    align="center"
                  >
                    Email
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!Loading ? (
                  contacts.length !== 0 ? (
                    contacts?.map((contact, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Checkbox
                            color="primary"
                            checked={
                              AllSelected ||
                              selected.indexOf(contact?.name) !== -1
                            }
                            inputProps={{
                              "aria-labelledby": i,
                            }}
                            onChange={(e) =>
                              selectSingleContact(e, contact?.name)
                            }
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            color: " #A8A8A8",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                        >
                          {" "}
                          {Capitalise(contact?.name)}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: " #A8A8A8",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                        >
                          {contact?.phone}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: " #A8A8A8",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                        >
                          {contact?.email}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} style={{ padding: "30px 0" }}>
                        <Typography align="center" style={{ color: "#ADADAD" }}>
                          No contacts added
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} style={{ padding: "30px 0" }}>
                      <Typography align="center" style={{ color: "#ADADAD" }}>
                        No contacts added
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Modal>
  );
}
