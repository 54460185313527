import { Box, Divider, TextField } from "@mui/material";
import React from "react";

export default function QuestionField({
  question,
  handleQuestionChange,
  updateQuestion,
  selectedLanguage,
}) {
  if (question === null) {
    question = {
      l1_title: "",
      l1_description: "",
      l2_title: "",
      l2_description: "",
    };
  }

  const handleChange = (e) => {
    handleQuestionChange({
      ...question,
      ...{ [e.target.name]: e.target.value },
    });
    updateQuestion({
      ...question,
      ...{ [e.target.name]: e.target.value },
    });
  };

  return (
    <Box>
      <TextField
        fullWidth
        value={
          selectedLanguage === "first" ? question.l1_title : question.l2_title
        }
        style={{ paddingTop: "24px" }}
        variant="standard"
        onChange={handleChange}
        placeholder={`Add your question`}
        // TODO: change to just title
        name={selectedLanguage === "first" ? "l1_title" : "l2_title"}
      />
      <Divider
        style={{
          margin: "0",
          borderBottom: "1px dashed #000",
        }}
      />
      <TextField
        placeholder={`Add description to your question`}
        variant="standard"
        style={{ paddingTop: "10px" }}
        value={
          selectedLanguage === "first"
            ? question.l1_description
            : question.l2_description
        }
        fullWidth
        onChange={handleChange}
        name={
          selectedLanguage === "first" ? "l1_description" : "l2_description"
        }
      />
    </Box>
  );
}
