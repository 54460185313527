import { useParams } from "react-router-dom";
import { getChartData, makeResponsePublic } from "../../../Api";
import { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import {
  BarGraph,
  CustomTooltip,
} from "../../CreateSurvey/EditSurveyPage/Responses/ResultOverviewPage";
import {
  colorScale,
  multipleOptionColor,
} from "../../CreateSurvey/EditSurveyPage/Responses/colors";
import { Container, Grid, Stack, Typography } from "@mui/material";
import { defaultSmileyValues } from "../../../Shared/QuestionOptionCards/SmileyOptionCard";
import { Capitalise } from "../../../Shared";
import { ArrowRight } from "@mui/icons-material";

const scale5 = ["very good", "good", "neutral", "bad", "very bad"];
const COLORS = {
  "very good": "#4ECC56",
  good: "#ACD91A",
  neutral: "#FFCF2B",
  bad: "#FE7E1F",
  "very bad": "#E8354D",
  positive: "#4ECC56",
  negative: "#E8354D",
};
export default function BarGraphEmbed() {
  let { questionId, branchId, chartType } = useParams();
  branchId = branchId || "all";
  const SelectedBranch = branchId;
  const enabledCharts = [chartType];
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    getChartData(questionId, branchId)
      .then((res) => {
        setChartData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [questionId]);
  if (!chartData.data) return <></>;
  let barData = [];
  for (const [key, value] of Object.entries(chartData.data)) {
    barData.push({ name: key, ...value });
  }
  if (chartData.length === 0) {
    return <></>;
  }
  function getLabel(index) {
    if (chartData.smileyLabels) {
      return chartData.smileyLabels[index];
    } else {
      return scale5[index];
    }
  }
  return (
    <Container
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack>
        <Stack direction={"row"} width={"100%"}>
          <ArrowRight />
          <Typography>{Capitalise(chartData.title)}</Typography>
        </Stack>
        <BarGraph
          SelectedBranch={SelectedBranch}
          enabledCharts={enabledCharts}
          barChart={chartData}
        />
      </Stack>
    </Container>
  );
}
