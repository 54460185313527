import {
  Box,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";

import React, { useEffect, useState } from "react";
import Capitalise from "../Capitalise/Capitalise";

export default function MultipleSelectForm(props) {
  //indices of checked items
  const { multipleSelectRes, setMultipleSelectRes } = props;

  const handleChange = (checked, value) => {
    if (checked && !multipleSelectRes.includes(value)) {
      setMultipleSelectRes([...multipleSelectRes, value]);
    } else if (!checked && multipleSelectRes.includes(value)) {
      setMultipleSelectRes(multipleSelectRes.filter((v) => v !== value));
    }
  };

  return (
    <Box
      width="100%"
      paddingBottom="20px"
      flexDirection={"column"}
      justifyContent="center"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{ height: "100%" }}
      >
        <FormLabel
          required={props.required}
          sx={{
            display: "flex",
            marginBottom: "8px",
            color: "#3F3D56",
            fontSize: !props.hideButton
              ? {
                  md: props.label.length > 80 ? "1rem" : "1.5rem",
                  xs: props.label.length > 80 ? "0.75" : "1.25rem",
                }
              : {
                  md: props.label.length > 80 ? "0.9rem" : "1.1rem",
                  xs: props.label.length > 80 ? "0.75" : "1.1rem",
                },
            fontWeight: !props.hideButton ? "750" : "600",
            maxWidth: "100%",
          }}
          className={`label bold`}
        >
          {Capitalise(props.label)}
        </FormLabel>
        {props.description && (
          <Typography style={{ color: "grey", fontSize: "18px" }}>
            {props.description}
          </Typography>
        )}
        <Grid container>
          <FormGroup>
            {props.options.map((opt, indx) => (
              <FormControlLabel
                sx={{ color: "#000" }}
                key={opt}
                control={
                  <Checkbox
                    checked={multipleSelectRes.includes(opt)}
                    onChange={(e) => handleChange(e.target.checked, opt)}
                  />
                }
                label={opt}
              />
            ))}
          </FormGroup>
        </Grid>
      </motion.div>
    </Box>
  );
}
