import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
  TableContainer,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  TextField,
  Grid,
  InputAdornment,
  Menu,
  Avatar,
  Checkbox,
  Popover,
} from "@mui/material";
import { deleteIcon } from "../../../Assets";

import { useNavigate } from "react-router-dom";
import { Capitalise, CreateSurveyModal } from "../../../Shared";
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import {
  MdKeyboardArrowDown,
  MdMoreHoriz,
  MdSearch,
  MdOutlineAddBox,
  MdCreate,
  MdOutlineEqualizer,
} from "react-icons/md";
import {
  deleteManySurveysById,
  deleteSurveyById,
  fetchSurveys,
  updateSurvey,
  getAllMergedSurveys,
  addMergedSurveys,
  CLIENT_URL,
  duplicateSurvey,
  deleteMergedSurvey,
} from "../../../Api";
import "./SurveysPage.css";
import QRCode from "react-qr-code";
import moment from "moment";
import MergeSurveyModal from "../../../Shared/Modals/MergeSurveyModal";

let select = [];

export default function HomePage() {
  const [surveys, setSurveys] = useState([]);
  const [mergedSurveys, setMergedSurveys] = useState([]);
  const navigate = useNavigate();
  const [ShowCreateModal, setShowCreateModal] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [EndDate, setEndDate] = useState(null);
  const [StartDate, setStartDate] = useState(null);
  const [surveyid, setSurveyid] = useState("");
  const [surveySearch, setSurveySearch] = useState("");
  const [filterSurveys, setFilterSurveys] = useState([]);
  const [tabTableSwapper, setTabTableSwapper] = useState(false);
  const [openMergeModal, setOpenMergeModal] = useState(false);
  const [selectSurvey, setSelectSurvey] = useState("0");
  const [showMenu, setShowMenu] = useState({
    condition: null,
    survey: null,
  });
  const [showDelete, setShowDelete] = useState(null);
  useEffect(() => {
    setFilterSurveys(surveys);
  }, [surveys]);

  const openMenu = Boolean(showMenu.condition);
  const handleClick = (event, survey) => {
    setShowMenu({
      condition: event.currentTarget,
      survey: survey,
    });
  };
  const handleClose = () => {
    setShowMenu({
      condition: null,
      survey: null,
    });
  };
  const handleDeleteMerged = (id) => {
    deleteMergedSurvey(id)
      .then(() => {
        toast.success("Deleted successfully");
        setMergedSurveys(mergedSurveys.filter((s) => s._id !== id));
      })
      .catch(() => toast.error("Some error occured. Please refresh the page."));
  };
  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#EDFAEE",
      border: 0,
    },
  }));

  if (!localStorage.userId) navigate("/");
  useEffect(() => {
    fetchSurveys()
      .then((res) => {
        setSurveys(res.data);
      })
      .catch((e) =>
        toast.error("could not load surveys. please refresh the page")
      );

    getAllMergedSurveys(localStorage.userId, null).then((res) => {
      setMergedSurveys(res.data.data);
    });
  }, []);

  let changeSurveyStatus = (surveyId, status) => {
    updateSurvey(surveyId, { status: status }).then((res) => {
      setSurveys(
        surveys.map((s) => {
          if (s._id === surveyId) return res.data;
          else return s;
        })
      );
    });
  };

  const handleDuplicate = async (id) => {
    await duplicateSurvey(id)
      .then((res) => {
        setSurveys([...surveys, res.data]);
        toast.success("Survey duplicated!");
      })
      .catch((err) => toast.error("Could not duplicate survey"));
    handleClose();
  };

  let scheduleSurvey = (surveyId, date, key) => {
    if (key === "start_date") {
      let status =
        new Date().toLocaleDateString() < new Date(date).toLocaleDateString()
          ? "paused"
          : "active";
      updateSurvey(surveyId, { start_date: date, status: status }).then(
        (res) => {
          setStartDate(date);
          setSurveys(
            surveys.map((s) => {
              if (s._id === surveyId) return res.data;
              else return s;
            })
          );
        }
      );
    } else if (key === "end_date") {
      updateSurvey(surveyId, { end_date: date }).then((res) => {
        setEndDate(date);
        setSurveys(
          surveys.map((s) => {
            if (s._id === surveyId) return res.data;
            else return s;
          })
        );
      });
    }
  };

  let deleteSurvey = () => {
    deleteSurveyById(surveyid)
      .then((res) => {
        setSurveys(surveys.filter((survey) => survey._id !== surveyid));
        toast.success("survey deleted successfully");
      })
      .catch((error) => {
        let message = "";
        try {
          message = error.response.data.message;
        } catch (e) {}
        toast.error(`Could not delete survey.\n${message}`);
      });
    setOpen(false);
  };

  const handleChange = (event, survey) => {
    if (event.target.checked) {
      select.push(survey._id);
      if (surveys.length <= select.length) setSelectAll(true);
    } else {
      select = select.filter((item) => item !== survey._id);
      setSelectAll(false);
    }
  };

  const handleSelectAll = (event) => {
    select = [];
    if (event.target.checked) {
      setSelectAll(true);
      surveys.map((survey, i) => {
        return select.push(survey._id);
      });
    } else {
      select = [];
      setSelectAll(false);
    }
  };

  const handleDelete = () => {
    deleteManySurveysById(select)
      .then((res) => {
        setSurveys(surveys.filter((survey) => !select.includes(survey._id)));
        toast.success("surveys deleted successfully");
        select = [];
        setSelectAll(false);
      })
      .catch((err) => {
        toast.error("Could not delete surveys. Please try again");
      });
    setOpenPopup(false);
  };
  useEffect(() => {
    if (surveySearch === "") {
      setFilterSurveys(surveys);
    } else {
      setFilterSurveys(
        surveys.filter((sur) =>
          sur.title.toLowerCase().includes(surveySearch.toLowerCase())
        )
      );
    }
  }, [surveySearch]);

  const SelectedSurvey = (e) => {
    setSelectSurvey(e.target.value);
  };
  const handleOneResponsePerDay = (value, surveyId) => {
    updateSurvey(surveyId, { oneResponsePerDay: value })
      .then((r) => {
        toast.success("Updated Successfully");
        setSurveys(
          surveys.map((s) => {
            if (s._id === surveyId) {
              s.oneResponsePerDay = value;
            }
            return s;
          })
        );
        handleClose();
      })
      .catch((e) => {
        console.log(e);
        toast.error("Could not complete your request");
      });
  };
  const accountEl = useRef(null);
  const [openAccount, setOpenAccount] = useState(false);
  const handleLogout = (e) => {
    e.stopPropagation();
    e.preventDefault();
    localStorage.clear();
    navigate("/");
  };

  return (
    <Box className="surveyspageWrapper">
      <div
        className="accounttypespecifier"
        ref={accountEl}
        onClick={() => setOpenAccount(true)}
      >
        <span>
          {localStorage.getItem("role") === "Admin" ? "Admin" : "Super Admin"}
          -Account
        </span>
      </div>
      <Popover
        open={openAccount}
        anchorEl={accountEl.current}
        onClose={() => setOpenAccount(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Button
          variant="outlined"
          sx={{ color: "tomato" }}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Popover>
      <CreateSurveyModal
        open={ShowCreateModal}
        close={() => setShowCreateModal(false)}
      />
      <MergeSurveyModal
        open={openMergeModal}
        close={(mergedSurvey) => {
          if (mergedSurvey) {
            setMergedSurveys([...mergedSurveys, mergedSurvey]);
          }
          setOpenMergeModal(false);
        }}
        surveys={surveys}
      />
      <Box className="surveysPageUpperCard">
        <Box>
          <Typography className="surveysPageUpperCardTitle">
            All Surveys
            {/* <span
                style={{
                  fontSize: "13px",
                  fontWeight: "400",
                  color: "#3A63F3",
                  cursor: "pointer",
                }}
              >
                Create new folder +
              </span> */}
          </Typography>
          {/* <Box display={"flex"} gap={"16px "}>
            <Select
              sx={{
                borderRadius: "5px",
                height: { md: "34px", xs: "30px" },
                width: "200px",
              }}
              defaultValue="0"
              value={selectSurvey}
              onChange={SelectedSurvey}
              IconComponent={MdKeyboardArrowDown}
            >
              <MenuItem value="0" onClick={() => setTabTableSwapper(false)}>
                Single Surveys
              </MenuItem>
              <MenuItem
                value="folders"
                onClick={() => setTabTableSwapper(true)}
              >
                Grouped Surveys
              </MenuItem>
            </Select>
            <Button
              variant="contained"
              onClick={() => setOpenMergeModal(true)}
              size="small"
              style={{
                width: "200px",
                borderRadius: "5px",
                fontSize: "16px",
              }}
            >
              Merge Your Surveys
            </Button>
          </Box> */}
        </Box>
        <Box>
          <TextField
            placeholder="Search surveys"
            className="surveySearchBar"
            value={surveySearch}
            onChange={(e) => setSurveySearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MdSearch
                    style={{
                      color: "#3A63F3",
                      width: "18.33px",
                      height: "18.31px",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {/* <Stack direction="row" spacing={1}>
              {select.length !== 0 && ( */}

        {/* )}
              {select.length !== 0 && (
                <Button
                  id="deleteButton"
                  type="button"
                  variant="contained"
                  onClick={() => setOpenPopup(true)}
                  style={{
                    borderRadius: "5px",
                    textTransform: "none",
                    fontWeight: "700",
                    fontSize: "16px",
                  }}
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              )}
            </Stack> */}
      </Box>
      <div>
        {/* <Dialog open={openMergePopup}>
          <DialogTitle>
            Are you sure you want to merge these surveys
          </DialogTitle>
          <TextField
            value={mergeName}
            onChange={(e) => setMergeName(e.target.value)}
            placeholder="Enter folder name"
          />
          <DialogActions>
            <Button onClick={addMerge}>Yes</Button>
            <Button onClick={() => setOpenMergePopup(false)}>No</Button>
          </DialogActions>
        </Dialog> */}
      </div>
      <div>
        <Dialog open={openPopup}>
          <DialogTitle>Are you sure you want to delete this item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleDelete}>Yes</Button>
            <Button onClick={() => setOpenPopup(false)}>No</Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog open={open}>
          <DialogTitle>Are you sure you want to delete this item?</DialogTitle>
          <DialogActions>
            <Button onClick={deleteSurvey}>Yes</Button>
            <Button onClick={() => setOpen(false)}>No</Button>
          </DialogActions>
        </Dialog>
      </div>
      {!tabTableSwapper ? (
        <Grid
          container
          spacing={2}
          sx={{
            background: "inherit",
            margin: "0px",
            width: "100%",
          }}
        >
          <Grid item lg={4} xs={12} sm={6} className="surveyGridItem">
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => setShowCreateModal(true)}
              className="createSurveyGridItemWrapper surveyGridItemWrapper"
            >
              <MdOutlineAddBox color="#3A63F3" size={"37px"} />
              <Typography fontSize="1.25rem">Create new survey</Typography>
            </Box>
          </Grid>
          {filterSurveys.length !== 0 ? (
            filterSurveys
              ?.map((survey, i) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={4}
                  className="surveyGridItem"
                  key={survey._id}
                >
                  <Box className="surveyGridItemWrapper">
                    <Box className="SurveyGridItemTitleSection">
                      <Typography
                        sx={{
                          width: "300px",
                          fontSize: "1.3125rem",
                        }}
                      >
                        {Capitalise(survey?.title)}
                      </Typography>
                      <Box display="flex" gap="10px">
                        <MdCreate
                          color="#757575"
                          size="19px"
                          cursor={"pointer"}
                          onClick={() =>
                            navigate(`/surveys/${survey._id}/build`)
                          }
                        />
                        <MdMoreHoriz
                          color="#757575"
                          size={"25px"}
                          cursor="pointer"
                          id="basic-button"
                          aria-controls={openMenu ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openMenu ? "true" : undefined}
                          onClick={(e) => handleClick(e, survey)}
                        />
                        <Menu
                          id="basic-menu"
                          anchorEl={showMenu.condition}
                          open={openMenu}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem
                            value="Edit"
                            onClick={() =>
                              navigate(
                                `/surveys/${showMenu?.survey?._id}/build`
                              )
                            }
                          >
                            Edit Survey
                          </MenuItem>
                          <MenuItem
                            value="Share"
                            onClick={() =>
                              navigate(
                                `/surveys/${showMenu?.survey?._id}/share`
                              )
                            }
                          >
                            Share Survey
                          </MenuItem>
                          <MenuItem
                            value="Duplicate"
                            onClick={() =>
                              handleDuplicate(showMenu?.survey?._id)
                            }
                          >
                            Duplicate Survey
                          </MenuItem>
                          <MenuItem
                            value="View"
                            onClick={() =>
                              navigate(
                                `/surveys/${showMenu?.survey?._id}/result`
                              )
                            }
                          >
                            View Result
                          </MenuItem>
                          {showMenu?.survey?.status === "active" ? (
                            <MenuItem
                              value="pause"
                              onClick={() => {
                                setSurveyid(showMenu?.survey?._id);
                                changeSurveyStatus(
                                  showMenu?.survey?._id,
                                  "paused"
                                );
                                setShowMenu({
                                  condition: null,
                                  survey: null,
                                });
                              }}
                            >
                              Pause Survey
                            </MenuItem>
                          ) : (
                            <MenuItem
                              value="resume"
                              onClick={() => {
                                setSurveyid(showMenu.survey._id);
                                changeSurveyStatus(
                                  showMenu.survey._id,
                                  "active"
                                );
                                setShowMenu({
                                  condition: null,
                                  survey: null,
                                });
                              }}
                            >
                              Resume Survey
                            </MenuItem>
                          )}
                          <MenuItem
                            value="delete"
                            onClick={() => {
                              setSurveyid(showMenu.survey._id);
                              setOpen(true);
                              setShowMenu({
                                condition: null,
                                survey: null,
                              });
                            }}
                          >
                            Delete survey
                          </MenuItem>
                          <MenuItem>
                            One response per day
                            <Checkbox
                              checked={
                                showMenu.survey?.oneResponsePerDay === true
                              }
                              onChange={(e) => {
                                handleOneResponsePerDay(
                                  e.target.checked,
                                  showMenu.survey._id
                                );
                              }}
                            />
                          </MenuItem>
                          <Divider />
                          <MenuItem value="schedule">Schedule survey</MenuItem>

                          <Grid container paddingLeft={"16px"} width="270px">
                            <Grid item xs={6}>
                              From
                              <DatePicker
                                selected={
                                  showMenu.survey?.start_date
                                    ? new Date(showMenu.survey?.start_date)
                                    : null
                                }
                                minDate={new Date()}
                                maxDate={
                                  showMenu.survey?.end_date
                                    ? new Date(showMenu.survey?.end_date)
                                    : null
                                }
                                onChange={(date) =>
                                  scheduleSurvey(
                                    showMenu.survey._id,
                                    date,
                                    "start_date"
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              To
                              <DatePicker
                                selected={
                                  showMenu.survey?.end_date
                                    ? new Date(showMenu.survey?.end_date)
                                    : null
                                }
                                minDate={
                                  showMenu.survey?.start_date
                                    ? new Date(showMenu.survey?.start_date)
                                    : new Date()
                                }
                                onChange={(date) =>
                                  scheduleSurvey(
                                    showMenu.survey._id,
                                    date,
                                    "end_date"
                                  )
                                }
                              />
                            </Grid>
                          </Grid>
                        </Menu>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      alignItems={"center"}
                      style={{
                        gap: "10px",
                        marginTop: "28px",
                      }}
                    >
                      <Avatar
                        sx={{
                          background: "#060A32",
                          fontSize: "18px",
                          fontWeight: "500",
                          height: "27px",
                          width: "27px",
                        }}
                      >
                        {localStorage?.userName?.charAt(0)}
                      </Avatar>
                      <Typography
                        sx={{
                          width: "300px",
                          height: "20px",
                          fontSize: "13px",
                          color: "#757575",
                        }}
                      >
                        Created {moment(survey?.createdAt).fromNow(true)} ago
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent={"space-between"}
                      style={{
                        marginTop: "70px",
                      }}
                    >
                      <Typography
                        sx={{
                          justifyContent: "center",
                          width: "70px",
                          height: "100%",
                          background: "rgba(29, 156, 95, 0.19)",
                          fontSize: "15px",
                          fontWeight: "500",
                          marginLeft: "6px",
                          borderRadius: "4px",
                          color: "rgba(0, 155, 81, 1)",
                        }}
                      >
                        &nbsp; {Capitalise(survey?.status)}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color: "#060a32",
                        }}
                      >
                        Total responses : {survey.responses}
                      </Typography>
                      <Box display={"flex"} gap="3px">
                        <MdOutlineEqualizer color="#3A63F3" />
                        <Typography
                          sx={{
                            fontWeight: "500",
                            cursor: "pointer",
                            fontSize: "12px",
                            color: "#3A63F3",
                            textDecoration: "underline",
                          }}
                          onClick={() =>
                            navigate(`/surveys/${survey._id}/result`)
                          }
                        >
                          Responses
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))
              .reverse()
          ) : (
            <Box sx={{ margin: "75px 0px 0px 50px" }}>
              <Typography align="center" style={{ color: "#ADADAD" }}>
                No surveys available. create your first survey to get start
              </Typography>
            </Box>
          )}
        </Grid>
      ) : (
        <TableContainer className="surveyspageTableContainer">
          <Table
            sx={{ width: "100%", height: "fit-content" }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableBody>
              {mergedSurveys.length !== 0 ? (
                mergedSurveys
                  ?.map((survey, i) => (
                    <StyledTableRow
                      key={survey._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(`/surveys/${survey._id}/merged`)}
                      onMouseEnter={() => setShowDelete(survey._id)}
                      onMouseLeave={() => setShowDelete(null)}
                    >
                      <TableCell
                        style={{ borderBottom: "none" }}
                        component="th"
                        scope="row"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "1rem",
                          fontWeight: "500",
                        }}
                      >
                        <Typography
                          sx={{
                            marginLeft: "18px",
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                        >
                          {Capitalise(survey?.name)}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontSize: "1rem",
                          fontWeight: "500",
                          borderBottom: "none",
                        }}
                      >
                        {survey?.merged_survey_ids.length}
                        {survey?.merged_survey_ids.map((surveys) => (
                          <Typography>{surveys}</Typography>
                        ))}
                      </TableCell>
                      <TableCell>
                        <QRCode
                          title="qrcode"
                          value={`${CLIENT_URL}/#/surveys/${survey._id}/merged`}
                          bgColor={"transparent"}
                          size={150}
                        />
                      </TableCell>
                      <TableCell>
                        <Button variant="contained">Download</Button>
                      </TableCell>
                      <TableCell>
                        {showDelete === survey._id ? (
                          <img
                            src={deleteIcon}
                            alt="delete"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleDeleteMerged(survey._id);
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        )}
                      </TableCell>
                    </StyledTableRow>
                  ))
                  .reverse()
              ) : (
                <Typography align="center" style={{ color: "#ADADAD" }}>
                  No surveys available. create your first survey to get start
                </Typography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
